import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import { WalletImplementationEnum } from '../../../../constant';
import { getNumberOnlyTwoDigitsAfter } from '../../../../services/util/formatNumber';
import LocalStorage from '../../../../services/local-storage';

const SPLIT_CHAR = '__';

export const generateBetSettingKey = ({
  gameCode, currencyCode,
}) => `${gameCode}${SPLIT_CHAR}${currencyCode}`;

export const getCodeFromBetSettingKey = value => [value.split(SPLIT_CHAR)[0], value.split(SPLIT_CHAR)[1]];

export const transformNewGroupQuickSetupPayload = (values, isNewBrand) => {
  const {
    group: groupValue, brands: brandsValue,
  } = values;

  const transformBetSettings = betSettings => keys(betSettings).map(betStKey => {
    const {
      game, currency, betSize, betLevel, betSizeDefault, betLevelDefault, totalBetLimit,
    } = betSettings[betStKey];

    return {
      game,
      currency,
      betSizes: betSize.map(bsItem => ({
        value: bsItem.value,
        default: bsItem.value === betSizeDefault,
      })),
      betLevels: betLevel.map(bsItem => ({
        value: bsItem.value,
        default: bsItem.value === betLevelDefault,
      })),
      totalBetLimit,
    };
  });

  const getFieldsByWalletImplementation = brand => {
    if (brand.implementation === WalletImplementationEnum.SEAMLESS) {
      return {
        payOutUrl: brand.payOutUrl,
        placeBetUrl: brand.placeBetUrl,
        betStatusUrl: brand.betStatusUrl,
        cancelBetUrl: brand.cancelBetUrl,
        createPlayerUrl: brand.createPlayerUrl,
        editBalanceUrl: brand.editBalanceUrl,
        payoutConfig: {
          betType: brand.payoutConfig.betType,
          isLastFreeSpins: brand.payoutConfig.isLastFreeSpins,
        },
        placebetConfig: {
          betType: brand.placebetConfig.betType,
          isLastFreeSpins: brand.placebetConfig.isLastFreeSpins,
        },
        cancelbetConfig: {
          playerToken: brand.cancelbetConfig.playerToken,
        },
      };
    }

    return {};
  };

  const payload = {
    group: isNewBrand
      ? {
        id: groupValue.id,
      }
      : {
        name: groupValue.name,
        managerEmail: groupValue.managerEmail,
        desc: groupValue.desc,
        enabled: groupValue.enabled,
        currencies: groupValue.currencies,
      },
    brands: brandsValue.map(brandItem => ({
      name: brandItem.name,
      desc: brandItem.desc,
      enabled: brandItem.enabled,
      currencies: brandItem.currencies,
      domain: brandItem.domain,
      languages: brandItem.languages,
      defaultLanguage: brandItem.defaultLanguage,
      implementation: brandItem.implementation,
      authorizeUrl: brandItem.authorizeUrl,
      getBalanceUrl: brandItem.getBalanceUrl,
      games: brandItem.games,
      betSettings: keys(brandItem.betSettings).length > 0 ? transformBetSettings(brandItem.betSettings) : undefined,
      whitelist: isObject(brandItem.whitelist)
        ? {
          name: brandItem.whitelist.name,
          note: brandItem.whitelist.note,
          ips: brandItem.whitelist.ips,
        }
        : undefined,
      ...getFieldsByWalletImplementation(brandItem),
    })),
  };

  return payload;
};

export const generateTotalBetMap = ({
  betSize, betLevel, baseBet,
}) => {
  if (!Array.isArray(betSize) || !Array.isArray(betLevel) || !Array.isArray(baseBet)) {
    return {};
  }

  const newTotalBetMap = {};
  const totalBetSet = new Set();

  betSize.forEach(bsItem => {
    betLevel.forEach(blItem => {
      baseBet.forEach(bbItem => {
        const totalBetItem = +(bsItem.value * blItem.value * bbItem.value).toFixed(2);
        totalBetSet.add(totalBetItem);

        const key = getNumberOnlyTwoDigitsAfter(totalBetItem);
        newTotalBetMap[key] = {
          betSize: bsItem.value,
          betLevel: blItem.value,
          baseBet: bbItem.value,
        };
      });
    });
  });

  return newTotalBetMap;
};

export const calculateTotalBet = ({
  betSize, betLevel, baseBet,
}) => +(betSize * betLevel * baseBet).toFixed(2);

export const transformErrorResponseToObject = errorResponse => errorResponse.reduce((errorObj, item) => {
  if (isEmpty(item)) {
    return errorObj;
  }

  const errorKey = keys(item)[0];
  return {
    ...errorObj,
    [errorKey]: item[errorKey],
  };
}, {});

export const cloneNameWithCounterRegex = /\(clone ?(\d*?\.?\d*)\)/g;

export const getNextCloneName = (name, listOtherName) => {
  const nextName = `${name} clone`;
  const nextNameExist = listOtherName.some(otherName => otherName === nextName);
  return nextNameExist ? getNextCloneName(nextName, listOtherName) : nextName;
};

export const getBrandIndexSource = index => `brands[${index}]`;

export const removeFormDataToStorage = storageKey => {
  LocalStorage.removeItem(storageKey);
};

export const saveFormDataToStorage = (data, storageKey) => {
  LocalStorage.setItem(storageKey, JSON.stringify(data));
};

export const getFormDataFromStorage = storageKey => {
  let result = {};
  try {
    result = JSON.parse(LocalStorage.getItem(storageKey));
  } catch (err) {
    console.debug('[ERROR] parse form data from storage\n', err.message);
  }
  return result;
};

export const sortListItem = list => orderBy(list, ['value'], 'asc');
export const compareListItem = (first = [], second = []) => {
  if (typeof first.length !== 'number' || first.length !== second.length) {
    return false;
  }
  return first.every((fItem, fIdx) => fItem.value === second[fIdx].value);
};
