import { Box, fade, IconButton, makeStyles, Paper, Popover, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
import React from 'react';
import { useSelector } from 'react-redux';
import HeaderMessages from '../HeaderMessages';
import HeaderTransactions from '../HeaderTransactions';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.error.main,
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 450,
    },
  },
  customBadgeTransaction: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  customBadgeInbox: {
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
  },
  tab: {
    textTransform: 'capitalize',
  },
  tabRoot: {
    flexGrow: 1,
  },
  customPositionBadge: {
    top: '50%',
    right: theme.spacing(-4),
  },
}));

const HeaderNotifications = () => {
  const classes = useStyles();
  const translate = useTranslate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon')}
        >
          <Badge
            classes={{
              badge: classes.counterRoot,
            }}
            badgeContent="99+"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              classes={{
                root: classes.tabRoot,
                wrapper: classes.tab,
              }}
              label={(
                <Badge
                  classes={{
                    badge: classes.customBadgeTransaction,
                    anchorOriginTopRightRectangle: classes.customPositionBadge,
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  badgeContent="99+"
                >
                  <Typography>{translate('resources.transaction.name')}</Typography>
                </Badge>
              )}
            />
            <Tab
              classes={{
                root: classes.tabRoot,
                wrapper: classes.tab,
              }}
              label={(
                <Badge
                  color="primary"
                  badgeContent="99+"
                  classes={{
                    anchorOriginTopRightRectangle: classes.customPositionBadge,
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Typography>{translate('resources.inbox.name')}</Typography>
                </Badge>
              )}
            />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
          >
            <HeaderTransactions onClosePopOver={onClosePopOver} />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
          >
            <HeaderMessages />
          </TabPanel>
        </Paper>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
