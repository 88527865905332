import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, required, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Edit } from '../../../base/components/ra/views';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import BrandInput from '../components/brand-input';
import { registerViewType, EnumViewType } from '../../../base/hooks/useViewType';

const requiredValidator = required();

const ReverseWalletIntegrationEdit = ({
  resource, ...otherProps
}) => {
  const translate = useTranslate();
  const { pathname } = useLocation();

  useEffect(() => {
    // Hotfix: To self register view type because the current system doesn't cover this case
    registerViewType(pathname, EnumViewType.EDIT);
  }, []);

  const handleDataTransformation = data => {
    const excludedFields = ['groupId', 'brandId'];
    return Object.keys(data).reduce((currentData, currentKey) => {
      if (excludedFields.includes(currentKey)) return currentData;
      return {
        ...currentData,
        [currentKey]: data[currentKey],
      };
    }, {});
  };

  return (
    <Edit
      hasEdit
      resource={resource}
      confirmDialogInfo={{
        content: 'resources.reverse-integration.reverseWalletIntegration.confirmToEditContent',
      }}
      translatedResourceName={translate('resources.reverse-integration.reverseWalletIntegration.name')}
      transform={handleDataTransformation}
      {...otherProps}
    >
      <SimpleForm redirect="show">
        <GroupSelector
          disabled
          validate={requiredValidator}
          fullWidth
          inputProps={{
            helperText: true,
          }}
        />
        <BrandInput
          disabled
          fullWidth
          validate={requiredValidator}
        />
        <UrlInput
          source="authorizeUrl"
          label="common.fields.authorizeUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="placeBetUrl"
          label="common.fields.placeBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="payOutUrl"
          label="common.fields.payOutUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="getBalanceUrl"
          label="common.fields.getBalanceUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="cancelBetUrl"
          label="common.fields.cancelBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
      </SimpleForm>
    </Edit>
  );
};

ReverseWalletIntegrationEdit.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ReverseWalletIntegrationEdit;
