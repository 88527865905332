import { Box, Button, fade, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from '../../../../../../../base/hooks';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import CmtList from '../../../../../../@coremat/CmtList';
import MessageItemComponent from './MessageItem';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  scrollbarRoot: {
    height: 300,
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

const HeaderMessages = () => {
  const classes = useStyles();
  const user = useAuthUser();
  const translate = useTranslate();
  const data = useSelector(({ notification }) => notification?.inbox?.data);

  return (
    <CmtCard className={classes.cardRoot}>
      <Box
        display="flex"
        justifyContent="space-between"
        p={4}
      >
        <Box />
        <Button
          style={{
            textTransform: 'capitalize',
          }}
          variant="outlined"
          color="primary"
          size="small"
        >
          {translate('ra.action.readAll')}
        </Button>
      </Box>
      <CmtCardContent>
        {data?.length > 0 ? (
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <CmtList
              data={data}
              renderRow={item => (
                <MessageItem
                  key={item.id}
                  item={item}
                  user={user}
                />
              )}
            />
          </PerfectScrollbar>
        ) : (
          <Box
            p={4}
            pt={0}
            textAlign="center"
          >
            <Typography variant="body2">{translate('ra.text.noMessageFound')}</Typography>
          </Box>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

const MessageItem = ({
  item, user,
}) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState();

  return (
    <MessageItemComponent
      item={item}
      subTitleNormal={expand}
    />
  );
};

export default HeaderMessages;
