import { Typography, Box, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useAuthUser } from '../../hooks';

function getTimeWithLocalTZ() {
  // const [time, setTime] = useState(moment().format('MM/DD/YYYY HH:mm'));
  const [time, setTime] = useState(moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss (Z)'));

  function tick() {
    setTime(moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss (UTC Z)'));
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  return `${time}`;
}

function HeaderInfo() {
  const user = useAuthUser();
  const muiTheme = useTheme();
  const contrastTextColor = muiTheme.palette.primary.contrastText;

  return (
    <Box
      display="flex"
      minWidth="250px"
      flexDirection="column"
    >
      <Typography
        variant="caption"
        component="div"
        style={{
          color: contrastTextColor,
        }}
      >
        {getTimeWithLocalTZ()}
      </Typography>
      <Typography
        variant="caption"
        component="div"
        style={{
          color: contrastTextColor,
        }}
      >
        {/* {user?.email} */}
        {user?.id}
      </Typography>
    </Box>
  );
}

export default HeaderInfo;
