import React, { useEffect, useMemo } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { FormDataConsumer, required } from 'react-admin';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import CustomSelectConfig, { customFieldsConfig } from '../wallet-integration/components/customSelectConfig';
import { useQueryParams } from '../../base/hooks';
import { checkCheatModeEnv, isPreStagingEnv } from '../../services/util';
import CurrenciesConfigWrapper, { CURRENCIES_FIELD_KEY } from './components/currencies-config-wrapper';
import PlayerLimitWrapper, { PLAYER_LIMIT_FIELD_KEY } from './components/player-limit';
import { AutocompleteInput } from '../../base/components/ra/inputs';
import ReferenceInput from '../../base/components/ra/inputs/reference.input';
import GetPlayerTokenUrlInput from './components/get-player-token-url-input';

const GROUP_FIELD_KEY = 'group';
export const CUSTOM_FIELDS = [CURRENCIES_FIELD_KEY, PLAYER_LIMIT_FIELD_KEY, GROUP_FIELD_KEY, ...customFieldsConfig];

const CustomFieldPlaceBetPayoutConfig = props => (
  <FormDataConsumer>
    {({ formData }) => customFieldsConfig.map(field => (
      <CustomSelectConfig
        key={`brand-create-${field}`}
        source={field}
        implementation={formData.implementation}
        {...props}
      />
    ))}
  </FormDataConsumer>
);

const HandlePreFillGroup = () => {
  const { groupId } = useQueryParams();
  const form = useForm();
  const { values: formValues } = useFormState();

  const cleanUrlToCreateNewBrand = () => window.history.pushState(null, null, '/#/brand/create');

  useEffect(() => {
    if (groupId) {
      form.change('group.id', groupId);
    }
  }, []);

  useEffect(() => {
    if (formValues?.group?.id && formValues?.group?.id !== groupId) {
      cleanUrlToCreateNewBrand();
    }
  }, [formValues?.group?.id]);

  return null;
};

const BrandCreate = props => {
  const isInPreStaging = isPreStagingEnv();
  const isCheatModeEnv = checkCheatModeEnv();
  const { groupId } = useQueryParams();

  const excludeFields = useMemo(() => {
    const cheatModeExcludeFields = isCheatModeEnv ? [] : ['createPlayerUrl', 'editBalanceUrl', 'playerLimit'];
    const preStgExcludeFields = ['getPlayerTokenUrl'];
    return [...cheatModeExcludeFields, ...preStgExcludeFields];
  }, [isInPreStaging, isCheatModeEnv]);

  return (
    <WealthCreateGuesser
      {...props}
      customFields={CUSTOM_FIELDS}
      excludeFields={excludeFields}
      fieldOrder={[
        'name',
        'desc',
        'domain',
        'languages',
        'defaultLanguage',
        'group',
        'implementation',
        'authorizeUrl',
        'getPlayerTokenUrl',
        'getBalanceUrl',
        'payOutUrl',
        'placeBetUrl',
        'betStatusUrl',
        'cancelBetUrl',
        'createPlayerUrl',
        'editBalanceUrl',
        'homeButtonEnabled',
        'enabled',
        'currencies',
      ]}
    >
      <GetPlayerTokenUrlInput source="getPlayerTokenUrl" />
      {isCheatModeEnv && <PlayerLimitWrapper />}
      <CurrenciesConfigWrapper
        initialGroupId={groupId}
        {...props}
      />
      <CustomFieldPlaceBetPayoutConfig {...props} />
      <HandlePreFillGroup {...props} />
      <ReferenceInput
        validate={required()}
        reference="group"
        source={GROUP_FIELD_KEY}
        disabled={Boolean(groupId)}
        format={data => data?.id}
        filterToQuery={value => ({
          'name||$cont': value || '',
          enabled: true,
        })}
        parse={id => ({
          id,
        })}
      >
        <AutocompleteInput
          variant="outlined"
          optionText="name"
        />
      </ReferenceInput>
    </WealthCreateGuesser>
  );
};

export default BrandCreate;
