import { Box, darken, fade, IconButton } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import { RefreshSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotify, useRefresh, useTranslate, useDataProvider, useLoading } from 'react-admin';
import HeaderInfo from '../../../../../../../base/components/layout/headerInfo';
import SidebarToggleHandler from '../../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import LanguageSwitcher from '../../LanguageSwitcher';
import Logo from '../../Logo';
import UserDropDown from '../../UserDropDown';
import { checkCheatModeEnv, getBackofficeEnv, RoleBuiltIn } from '../../../../../../../services/util';
import { useAuthUser } from '../../../../../../../base/hooks';
import { useReloadContext } from '../../../../../../../base/context/reload/reload.context';
import LocalStorage, { userStorageKey } from '../../../../../../../services/local-storage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 10,
    padding: 0,
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: fade(theme.palette.common.dark, 0.15),
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: fade(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
  transactionWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  refreshIcon: {
    color: theme.palette.primary.contrastText,
  },
  viewButton: {
    color: 'white',
    '&:hover': {
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const GoToLobbyPage = () => {
  const user = useAuthUser();
  const translate = useTranslate();
  const env = getBackofficeEnv();
  const classes = useStyles();

  const lobbyUrl = useMemo(
    () => {
      const url = new URL(env.LOBBY_PAGE_URL);
      Object.entries({
        t: user?.id,
        oc: user?.brands?.[0]?.code,
      }).forEach(([key, value]) => url.searchParams.append(key, value));
      return url.href;
    },
    [env.LOBBY_PAGE_URL, user?.id, user?.brands?.[0]?.code],
  );

  return (
    <Box>
      <Box className={clsx('Cmt-i18n-switch')}>
        <Tooltip title={translate('ra.text.lobbyPage')} className={classes.viewButton}>
          <IconButton
            onClick={(_e) => {
              window.open(lobbyUrl, '_blank');
            }}
          >
            <SportsEsportsIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const HeaderTop = () => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const { setValuesContext } = useReloadContext();

  const pageLoading = useLoading();

  const handleRefresh = useCallback(() => {
    refresh();
    notify(translate('ra.notification.refreshed', 'success'));

    return () => {
      if (!pageLoading) {
        setValuesContext({});
      }
    };
  }, [notify, refresh, translate, setValuesContext, pageLoading]);

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo
        mr={{
          xs: 2,
          sm: 4,
          lg: 6,
          xl: 8,
        }}
      />
      <Box display="flex" alignItems="center" ml={{ base: "'unset'", sm: 'auto' }}>
        <Hidden xsDown>
          <HeaderInfo />
          <IconButton aria-label="Refresh" aria-haspopup="true" onClick={handleRefresh} color="default">
            <RefreshSharp className={classes.refreshIcon} />
          </IconButton>
        </Hidden>
        <Box
          display={{
            xs: 'none',
            sm: 'block',
          }}
        >
          <Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>
            <LanguageSwitcher />
          </Box>
        </Box>

        <GoToLobbyPage />
        <UserDropDown />
      </Box>
    </Toolbar>
  );
};

export default HeaderTop;
