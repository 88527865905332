/* eslint-disable no-unused-vars, no-underscore-dangle */
import _ from 'lodash';

import { AppBrandEnum, ROLE_TYPE } from '.';

// const MBO_LOGO_GREEN_COLUMN = '/images/mbo-logos/test-studio-logo-fullcolor.png';
// const MBO_LOGO = '/images/mbo-logos/test-studio-logo.png';

const COMPANY_HEADER_LOGO = '/images/nagagames-logos/logo-light-transparent.svg';
const DEFAULT_IMAGE = 'images/nagagames-logos/logo-dark-white.ico';
const COMPANY_LOGO = '/images/nagagames-logos/logo-dark-square-transparent.svg';
const COMPANY_NAME = 'Revenge Games';

const _appBrandConfigMapping = {
  [AppBrandEnum.NAGA]: {
    topHeaderLogo: COMPANY_HEADER_LOGO,
    loadingImage: COMPANY_LOGO,
    companyName: COMPANY_NAME,
    loginPageLogo: COMPANY_LOGO, // Check at route `/login`
    newUserChangePasswordPageLogo: COMPANY_LOGO, // Check at route `/require-change-password`
    resetPasswordPageLogo: COMPANY_LOGO, // Check at route `/reset-password/:resetToken`
    faviconDefault: DEFAULT_IMAGE,
    getTabTitle({
      userGroupCode, roleType,
    }) {
      if (roleType === ROLE_TYPE.group) {
        return `Group ${userGroupCode || ''}`;
      }
      // ROLE_TYPE.system
      return `${COMPANY_NAME} | Back office`;
    },
  },

  // the legacy src code is already in very bad state, thus this is just quick fix to redeem the theme requested by the mgmt
  [AppBrandEnum.MBO]: {
    topHeaderLogo: COMPANY_HEADER_LOGO,
    loadingImage: COMPANY_LOGO,
    companyName: COMPANY_NAME,
    loginPageLogo: COMPANY_LOGO, // Check at route `/login`
    newUserChangePasswordPageLogo: COMPANY_LOGO, // Check at route `/require-change-password`
    resetPasswordPageLogo: COMPANY_LOGO, // Check at route `/reset-password/:resetToken`
    faviconDefault: DEFAULT_IMAGE,
    getTabTitle({
      userGroupCode, roleType,
    }) {
      if (roleType === ROLE_TYPE.group) {
        return `${COMPANY_NAME} | Group ${userGroupCode || ''}`;
      }
      // ROLE_TYPE.system
      return `${COMPANY_NAME} | Streamer Marketing Back Office`;
    },
  },
};

export const appBrandConfigMapping = new Proxy(_appBrandConfigMapping, {
  get(target, prop, _receiver) {
    const targetKeys = _.keys(target);
    if (!targetKeys.includes(prop)) {
      return target[AppBrandEnum.NAGA];
    }
    return target[prop];
  },
});
