import React, { useEffect, useRef, useState } from 'react';
import { SimpleForm, Toolbar, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormState, useForm } from 'react-final-form';
import keys from 'lodash/keys';
import { Box, Card, Fade } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import Button from '../../ra/buttons/Button';

const CustomizedToolbar = ({
  confirmDialogInfo, onSubmit, submitButtonProps,
}) => {
  const {
    alwaysEnableSaveButton, disabled: disabledSaveButton,
  } = submitButtonProps;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const refModified = useRef({});
  const [errorMessage, setErrorMessage] = useState('');

  const {
    modified, values: formValues,
  } = useFormState();
  const form = useForm();

  useEffect(() => {
    if (isEmpty(refModified.current)) {
      refModified.current = modified;
    }
  }, [modified]);

  useEffect(() => {
    if (!errorMessage) {
      return;
    }

    // Hide submit help text
    const allFieldsAreValid = keys(modified).every(d => form?.getFieldState(d)?.valid);
    if (allFieldsAreValid) {
      setErrorMessage('');
    }
  }, [modified, formValues]);

  const isModified = keys(modified).some(key => modified[key] === true) || keys(refModified.current).length !== keys(modified).length;
  const isDisabled = !disabledSaveButton && !isModified && !alwaysEnableSaveButton;

  const onSaveClick = () => {
    // Check validation form
    const someFieldInValid = keys(modified).some(d => !form?.getFieldState(d)?.valid);
    if (someFieldInValid) {
      form.submit(); // To trigger validation
      setErrorMessage(translate('ra.message.someFieldInValid'));
    } else if (confirmDialogInfo) {
      // Show confirm dialog when has the confirmDialogInfo prop
      dispatch(
        showConfirmDialog({
          isOpen: true,
          ...confirmDialogInfo,
          onConfirm: () => onSubmit(formValues),
        }),
      );
    } else {
      onSubmit(formValues);
    }
  };

  return (
    <Toolbar>
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
        pr={1}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Fade in={!!errorMessage}>
            <Box
              color="error.main"
              marginRight="16px"
            >
              {errorMessage}
            </Box>
          </Fade>
          <Button
            label={translate('ra.action.save')}
            disabled={isDisabled}
            alwaysEnableSaveButton={alwaysEnableSaveButton}
            onClick={onSaveClick}
            {...submitButtonProps}
          />
        </Box>
      </Box>
    </Toolbar>
  );
};

CustomizedToolbar.propTypes = {
  confirmDialogInfo: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
};

CustomizedToolbar.defaultProps = {
  confirmDialogInfo: undefined,
  submitButtonProps: {},
};

const CommonSubmitFormWrapper = ({
  children, onSubmit, confirmDialogInfo, submitButtonProps, ...otherProps
}) => (
  <Card>
    <SimpleForm
      toolbar={(
        <CustomizedToolbar
          onSubmit={onSubmit}
          confirmDialogInfo={confirmDialogInfo}
          submitButtonProps={submitButtonProps}
        />
      )}
      {...otherProps}
    >
      {children}
    </SimpleForm>
  </Card>
);

export default CommonSubmitFormWrapper;

CommonSubmitFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  confirmDialogInfo: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
};

CommonSubmitFormWrapper.defaultProps = {
  confirmDialogInfo: undefined,
  submitButtonProps: {},
};
