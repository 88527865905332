import PartnerList from './partner.list';
import PartnerCreate from './partner.create';
import PartnerEdit from './partner.edit';
import PartnerShow from './partner.show';

export default {
  list: PartnerList,
  edit: PartnerEdit,
  create: PartnerCreate,
  show: PartnerShow,
};
