import React from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  required,
  useTranslate,
} from 'react-admin';
import { makeStyles, Radio, Box, FormControlLabel } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import SimpleFormIterator from '../../../base/components/ra/simpleFormIterator';

const useStyles = makeStyles({
  section: {
    marginTop: 16,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '85% 15%',
    alignItems: 'center',
    gap: 16,
  },
  radio: {
    '&:hover, &[class*="checked"]:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const PlayUrlInput = props => {
  const styles = useStyles();
  const translate = useTranslate();

  return (
    (
      <FormDataConsumer>
        {({ formData }) => {
          const currentPlayUrl = formData.playUrl;

          const hasBlankUrl = currentPlayUrl.findIndex(playUrl => !playUrl?.url) >= 0;
          const hasMultipleUrls = currentPlayUrl.length > 1;

          return (
            <ArrayInput
              label={translate('resources.game.fields.playUrl')}
              validate={required()}
              {...props}
            >
              <SimpleFormIterator
                className={styles.section}
                disableReordering
                disableRemove={record => currentPlayUrl?.length <= 1 || record?.isDefault}
                disableAdd={hasBlankUrl && hasMultipleUrls}
                inline
              >
                <FormDataConsumer>
                  {({ getSource }) => {
                    const regex = /playUrl\[(\d+)\]\./;
                    const match = getSource('').match(regex);
                    const index = parseInt(match[1], 10);

                    return (
                      <Box className={styles.grid}>
                        <UrlInput
                          source={getSource('url')}
                          label={false}
                          helperText={false}
                          fullWidth
                          validate={required()}
                        />
                        <DefaultInput
                          source="isDefault"
                          index={index}
                        />
                      </Box>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          );
        }}
      </FormDataConsumer>
    )
  );
};

const DefaultInput = ({
  source, index,
}) => {
  const translate = useTranslate();
  const styles = useStyles();
  const form = useForm();
  const { values: { playUrl } } = useFormState();

  const handleChange = () => {
    const newPlayUrl = playUrl.map((item, itemIdx) => ({
      ...item,
      isDefault: itemIdx === index ? !item?.isDefault : false,
    }));

    form.change('playUrl', newPlayUrl);
  };

  return (
    <FormControlLabel
      control={(
        <Radio
          checked={Boolean(playUrl[index]?.[source])}
          onChange={handleChange}
          disableRipple
          className={styles.radio}
          color="primary"
        />
      )}
      label={translate('ra.text.default')}
    />
  );
};

DefaultInput.propTypes = {
  source: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default PlayUrlInput;
