/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useTranslate } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import BetDateRangeInput from '../bet/components/date-range.input';
import { FILTER_PREFIX_ON_VALUE } from '../../constant';
import InfinitePagination from '../../base/components/ra/list/InfinitePagination';

const useStyles = makeStyles({
  filterContainerWrapper: {
    "& *[data-field='created']": {
      order: 1,
    },
    "& *[data-field='wagerId']": {
      order: 2,
    },
    "& *[data-field='playerId']": {
      order: 3,
    },
    "& *[data-field='nativeId']": {
      order: 4,
    },
    "& *[data-field='group']": {
      order: 5,
    },
    "& *[data-field='brand']": {
      order: 6,
    },
    "& *[data-field='currencyType']": {
      order: 7,
    },
    "& *[data-field='currency']": {
      order: 8,
    },
    "& *[data-field='gameId']": {
      order: 9,
    },
    "& *[data-field='game']": {
      order: 10,
    },
    "& *[data-field='betType']": {
      order: 11,
    },
    "& *[data-field='betStatus']": {
      order: 12,
    },
    "& *[data-field='largeWin']": {
      order: 13,
    },
  },
});

function WagerList(props) {
  const styles = useStyles();
  const today = moment().endOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const translate = useTranslate();

  return (
    <WealthListGuesser
      {...props}
      defaultSort={{
        field: 'created',
        order: 'ASC',
      }}
      optionsSearch={{
        excludeSearchFields: ['created'],
        extraSearchFields: [
          <BetDateRangeInput
            source="created"
            label={translate('resources.bet.fields.created')}
            {...props}
          />,
        ],
      }}
      filterProps={{
        containerClassName: styles.filterContainerWrapper,
      }}
      filterDefaultValues={{
        created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday.toISOString()},${today.toISOString()}`,
      }}
      pagination={<InfinitePagination />}
      disabledAutoRefetch
    />
  );
}

export default WagerList;
