/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  FunctionField,
  Loading,
  ReferenceField,
  TextField,
  useListContext,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Card, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import StringFilter from '../../../base/components/customized/common-input/StringInput';
import TimeRangeFilter from '../../../base/components/customized/common-input/TimeRangeInput';
import CreateButton from '../../../base/components/customized/common-list-action-buttons/CreateButton';
import FilterButton from '../../../base/components/customized/common-list-action-buttons/FilterButton';
import CustomizedFilterWrapper from '../../../base/components/customized/CustomizedFilterWrapper';
import CustomizedList from '../../../base/components/customized/CustomizedList';
import CustomizedListActionWrapper from '../../../base/components/customized/CustomizedListActionWrapper';
import { DateField, IdField, NameField } from '../../../base/components/ra/fields';
import RowActionField from '../../../base/components/ra/fields/row-action-field/row-action.field';
import { CUSTOM_ROUTES, REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';
import { CUSTOM_RESOURCES } from '../../../constant/customResources';
import useConfiguration from '../hooks/useConfiguration';
import Button from '../../../base/components/ra/buttons/Button';
import BrandSelector from '../../../base/components/customized/common-input/BrandSelector';
import { transformIdValue } from '../../../services/util/stringUtil';

export const getAvailableBrandsEndpoint = groupId => `api/vertbet/partner/brand-available/${groupId}`;

const PartnerList = props => {
  const operatorId = props.options?.operatorId;
  const translate = useTranslate();
  const {
    data: configInfo, isLoading,
  } = useConfiguration();

  if (isLoading) return <Loading />;

  const groupId = configInfo?.groupId;

  return groupId ? (
    <CustomizedList
      {...props}
      actions={<PartnerListAction groupId={groupId} />}
      filters={<PartnerFilterWrapper groupId={groupId} />}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      emptyProps={{
        emptyMessage: translate('resources.reverse-integration.partner.empty'),
      }}
      basePath={`/${props.resource}`}
    >
      <IdField hasShow />
      <DateField
        source="createdAt"
        label="common.fields.created"
      />
      <DateField
        source="updatedAt"
        label="common.fields.updated"
      />
      <NameField label="common.fields.name" />
      <TextField
        source="partnerId"
        label="resources.reverse-integration.partner.fields.partnerId"
      />

      <ReferenceField
        source="groupId"
        reference="group"
        link="show"
        label="resources.group.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        reference="brand"
        link="show"
        label="resources.brand.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="walletIntegrationId"
        reference={CUSTOM_RESOURCES.VERTBET_WALLET_INTEGRATION.name}
        link="show"
        label="resources.reverse-integration.partner.fields.reverseWalletIntegrationId"
      >
        <FunctionField render={record => transformIdValue(record.id)} />
      </ReferenceField>
      <RowAction label={translate('ra.field.action')} />
    </CustomizedList>
  ) : (
    <NotYetSetupGroupConfigurationWrapper operatorId={operatorId} />
  );
};

PartnerList.propTypes = {
  options: PropTypes.shape({
    operatorId: PropTypes.string.isRequired,
  }).isRequired,
  resource: PropTypes.string.isRequired,
};

const RowAction = props => {
  const translate = useTranslate();
  const { record } = useRecordContext();
  return (
    <RowActionField
      {...props}
      hasDelete
      deleteButtonProps={{
        confirmTitle: translate('resources.reverse-integration.partner.confirmToDeleteTitle', {
          id: record?.id,
        }),
        translatedResourceName: translate('resources.reverse-integration.partner.name'),
      }}
      sortable={false}
    />
  );
};

const NotYetSetupGroupConfigurationWrapper = ({ operatorId }) => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <Box
      component={Card}
      p={6}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Typography gutterBottom>{translate('resources.reverse-integration.partner.notConfigYet')}</Typography>
      <Button
        label="resources.reverse-integration.partner.goToConfig"
        onClick={() => history.push(CUSTOM_ROUTES.REVERSE_INTEGRATION_GROUP_CONFIG(operatorId))}
      />
    </Box>
  );
};

NotYetSetupGroupConfigurationWrapper.propTypes = {
  operatorId: PropTypes.string.isRequired,
};

const PartnerListAction = ({ groupId }) => {
  const translate = useTranslate();
  const { total: totalPartner } = useListContext();
  const [hasNoAvailableBrands, setHasNoAvailableBrands] = useState(false);

  useEffect(() => {
    axios
      .get(getAvailableBrandsEndpoint(groupId))
      .then(({ data }) => {
        setHasNoAvailableBrands(data.data.length === 0);
      })
      .catch(() => {
        setHasNoAvailableBrands(false);
      });
  }, [groupId, totalPartner]);

  return (
    <CustomizedListActionWrapper>
      <FilterButton />
      <CreateButton
        tooltipProps={
          hasNoAvailableBrands
            ? {
              title: translate('resources.reverse-integration.partner.hasNoAvailableBrands'),
            }
            : {}
        }
        disabled={hasNoAvailableBrands}
        basePath={CUSTOM_ROUTES.REVERSE_INTEGRATION_PARTNER(REVERSE_INTEGRATION_OPERATORS.VERTBET.id)}
      />
    </CustomizedListActionWrapper>
  );
};

PartnerListAction.propTypes = {
  groupId: PropTypes.string.isRequired,
};

const PartnerFilterWrapper = ({ groupId }) => (
  <CustomizedFilterWrapper>
    <TimeRangeFilter
      resource="createdAt"
      source="createdAt"
      label="common.fields.created"
    />
    <TimeRangeFilter
      resource="updatedAt"
      source="updatedAt"
      label="common.fields.updated"
    />
    <StringFilter
      source="name||$cont"
      label="common.fields.name"
    />
    <StringFilter
      source="partnerId||$cont"
      label="resources.reverse-integration.partner.fields.partnerId"
    />
    <BrandSelector
      filter={{
        'group.id': groupId,
      }}
    />
  </CustomizedFilterWrapper>
);

PartnerFilterWrapper.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default PartnerList;
