/* eslint-disable */
import React, { useEffect } from 'react';
import { required } from 'ra-core';
import { AutocompleteInput } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';

import { TextInput, SelectInput, ReferenceInput } from '../../../../base/components/ra/inputs';
import { useViewType } from '../../../../base/hooks';
import {
  InboxHub,
  InboxModule,
  InboxType,
} from '../../inbox-rule.const';

const useStyles = makeStyles(theme => ({
  hiddenInput: {
    display: 'none',
  },
}), {
  name: 'InboxRule_GeneralStep',
});

const GeneralStep = props => {
  const { formData } = props;

  const { isEditView, isCreateView } = useViewType();
  const classes = useStyles();
  const form = useForm();

  useEffect(() => {
    if (!isCreateView) {
      return;
    }

    const { hub, module } = formData;

    // hub just one option => auto select
    if (!hub) {
      form.change('hub', InboxHub.game.value);
    }

    // module just one option => auto select
    if (!module) {
      form.change('module', InboxModule.INBOX.value);
    }
  }, [isCreateView]);

  return (
    <Box className="InboxRule_general-step" margin="0 10px">
      <TextInput
        source="dynamicGroupStep"
        className={classes.hiddenInput}
      />
      <TextInput
        label="resources.inbox-rule.fields.name"
        source="name"
        fullWidth
        disabled={isEditView}
        validate={required()}
      />
      <TextInput
        label="resources.inbox-rule.fields.desc"
        source="desc"
        multiline
        rows={5}
        fullWidth
      />
      <TextInput
        label="resources.inbox-rule.fields.message"
        source="message.data"
        multiline
        rows={5}
        fullWidth
        validate={required()}
      />
      <Field name="hub">
        {() => (
          <SelectInput
            label="resources.inbox-rule.fields.hub"
            className={classes.hiddenInput}
            source="hub"
            choices={[
              {
                id: InboxHub.game.value,
                name: InboxHub.game.displayName,
              },
            ]}
            validate={required()}
          />
        )}
      </Field>
      <Field name="module">
        {() => (
          <SelectInput
            label="resources.inbox-rule.fields.module"
            className={classes.hiddenInput}
            source="module"
            choices={[
              {
                id: InboxModule.INBOX.value,
                name: InboxModule.INBOX.displayName,
              },
            ]}
            validate={required()}
          />
        )}
      </Field>
      <Field name="type">
        {() => (
          <SelectInput
            label="resources.inbox-rule.fields.type"
            source="type"
            choices={[
              {
                id: InboxType.GENERAL.value,
                name: InboxType.GENERAL.displayName,
              },
              {
                id: InboxType.GAME_BASED.value,
                name: InboxType.GAME_BASED.displayName,
              },
            ]}
            validate={required()}
          />
        )}
      </Field>
      {formData.type === InboxType.GAME_BASED.value && (
        <ReferenceInput
          label="resources.inbox-rule.fields.game"
          source="game.id"
          reference="game"
          fullWidth
          perPage={100}
          validate={required()}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
    </Box>
  );
};

export default GeneralStep;
