/* eslint-disable react/forbid-prop-types, react/require-default-props */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import { useTranslate, useNotify } from 'react-admin';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import axios from 'axios';
import Confirm from '../../../base/components/ra/layout/Confirm';
import { sanitizeButtonRestProps } from '../../../base/components/ra/buttons/Button';

const useStyles = makeStyles(() => ({
  viewButton: {
    color: '#0080FF',
    '&:hover': {
      backgroundColor: fade('#0080FF', 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const defaultIcon = <RestorePageIcon />;

function ResetPasswordButton(props) {
  const {
    record, icon = defaultIcon, className, ...rest
  } = props;
  const [isConfirm, setConfirm] = useState(false);
  const style = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  if (!record || record.id == null) {
    return null;
  }

  const handleConfirm = async () => {
    const { id } = record;
    try {
      await axios.post(`api/auth/password/reset/${id}`);
      notify(translate('ra.notification.resetPasswordSuccess'), 'success');
    } catch {
      // Do nothing!
      // This error is handled in the interceptor
    }
    setConfirm(false);
  };

  return (
    <>
      <Tooltip title={translate('ra.action.resetPassword')}>
        <IconButton
          onClick={e => {
            setConfirm(true);
            e.stopPropagation();
          }}
          className={classnames(className, style.viewButton)}
          {...sanitizeButtonRestProps(rest)}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Confirm
        isOpen={isConfirm}
        title={translate('ra.action.resetPassword')}
        content={record?.username}
        onConfirm={handleConfirm}
        onClose={() => setConfirm(false)}
      />
    </>
  );
}

ResetPasswordButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.any,
  extraActions: PropTypes.any,
};

const PureResetPasswordButton = memo(ResetPasswordButton, (props, nextProps) => (props.record && nextProps.record
  ? props.record.id === nextProps.record.id
  : props.record === nextProps.record));

export default PureResetPasswordButton;
