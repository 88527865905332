/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import PropTypes from 'prop-types';
import { isEmpty, keys, flatten } from 'lodash';
import {
  Card,
  Box,
  Avatar,
  Link,
  makeStyles,
  useTheme,
  fade,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import moment from 'moment';

import { getUserAvatar } from '../../../services/util/stringUtil';
import { SmallButton } from '../../components/ra/buttons';
import useTranslateSchema from '../../hooks/useTranslateSchema';
import Empty from '../../components/empty';
import AuditLogSkeleton from './skeleton';

const METHOD = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const ACTION = {
  ADDED: 'added',
  UPDATED: 'updated',
  DELETED: 'deleted',
};

const useStyle = makeStyles(theme => ({
  timeLine: {
    '&:before': {
      display: 'none',
    },
  },
  historyContent: {
    marginBottom: '16px',
    '&:last-of-type': {
      marginBottom: '0px',
    },
  },
}));

const getMethodText = (action, translateSchema) => {
  switch (action) {
    case METHOD.CREATE:
      return translateSchema({
        name: 'ra.text.actionWithThisRecord',
        params: {
          smart_name: 'wa.common.created'
        }
      });
    case METHOD.UPDATE:
      return translateSchema({
        name: 'ra.text.actionWithThisRecord',
        params: {
          smart_name: 'wa.common.changed'
        }
      });
    case METHOD.DELETE:
      return translateSchema({
        name: 'ra.text.actionWithThisRecord',
        params: {
          smart_name: 'wa.common.deleted'
        }
      });
    default:
      return translateSchema({
        name: 'wa.common.unknown'
      });
  }
};

const getActionText = (action, translate) => {
  switch (action) {
    case ACTION.ADDED:
      return translate('wa.common.added');
    case ACTION.UPDATED:
      return translate('wa.common.changed');
    case ACTION.DELETED:
      return translate('wa.common.deleted');
    default:
      return translate('wa.common.unknown');
  }
};

const ChangingLabel = ({ label }) => <Box marginRight="10px">{!isNaN(Number(label)) ? `[${Number(label) + 1}]` : label}:</Box>;

const IndentWrapper = ({ children }) => (
  <Box display="flex" >
    <Box component="span" width="20px" />
    <Box>{children}</Box>
  </Box>
);

const HandleActionData = ({ actionData }) => {
  const checkConditionBreak = (data) => {
    return keys(data).length === 1 && ['from', 'to'].includes(keys(data)[0]);
  };
  
  const loggingHandler = (data, key) => {
    if (
      keys(data).length === 2 &&
      keys(data).every(k => ['from', 'to'].includes(k))
    ) {
      return (
        <IndentWrapper key={key}>
          <HistoryContentItem
            label={key}
            fromData={data.from}
            toData={data.to}
          />
        </IndentWrapper>
      );
    }

    // Handle for method: ADD or DELETE
    if (checkConditionBreak(data)) {
      return keys(data).map(dKey => (
        <Box>{JSON.stringify(data[dKey])}</Box>
      ));
    }

    const result = [];
    keys(data).forEach(dKey => {
      const loggingComponent = loggingHandler(data[dKey], dKey);

      if (checkConditionBreak(data[dKey])) {
        result.push(
          <IndentWrapper key={`${key}_${dKey}`}>
            <Box display="flex">
              <ChangingLabel label={dKey} />
              {loggingComponent}
            </Box>
          </IndentWrapper>
        );
        return;
      }
      
      if (Array.isArray(loggingComponent)) {
        result.push((
          <IndentWrapper key={`${key}_${dKey}`}>
            <ChangingLabel label={dKey} />
            <Box>{loggingComponent}</Box>
          </IndentWrapper>
        ));
        return;
      }

      result.push(loggingComponent);
    });

    return flatten(result);

  }; // End loggingHandler(...)

  const loggingHandleResult = loggingHandler(actionData);
  return loggingHandleResult;
};

const HistoryContent = ({
  avatar,
  username,
  method,
  data,
  createdDate,
  recordId,
}) => {
  const classes = useStyle();
  const muiTheme = useTheme();
  const translate = useTranslate();
  const translateSchema = useTranslateSchema();

  const getBgColorByAction = (action) => {
    switch (action) {
      case ACTION.ADDED:
        return muiTheme.palette.success.dark;
      case ACTION.UPDATED:
        return muiTheme.palette.primary.light;
      case ACTION.DELETED:
        return muiTheme.palette.error.main;
      default:
        return undefined;
    }
  };

  const needToShowDetail = method === METHOD.UPDATE;

  return (
    <Box
      className={classes.historyContent}
      style={{
        display: 'flex',
        gap: '16px',
      }}
    >
      {avatar}
      <Box
        style={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <Box display="inline">
          <Box component="strong">{username}</Box>
          {' '}
          <Box component="span">{getMethodText(method, translateSchema)?.toLowerCase()}</Box>
          {' '}
          <Box
            component="em"
            style={{
              color: fade(muiTheme.palette.primary.subText, 0.62),
              marginLeft: '8px',
            }}
          >
            <Link
              style={{
                color: 'currentColor',
                textDecoration: 'underline',
              }}
              href={`/#/audit-log/${recordId}/show`}
            >
              {moment(createdDate).fromNow()}
            </Link>
          </Box>
        </Box>

        {needToShowDetail && keys(data).map(actionKey => {
          const actionData = data[actionKey];

          if (isEmpty(data[actionKey])) {
            return null;
          }

          return (
            <Box key={actionKey}>
              <IndentWrapper>
                <Box
                  display="flex"
                  style={{
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  {translate('ra.field.action')}
                  <Box
                    style={{
                      padding: '2px 11px',
                      background: getBgColorByAction(actionKey),
                      borderRadius: '3px',
                      color: '#FFFFFF',
                      textTransform: 'uppercase',
                      fontWeight: 700,
                      fontSize: '12px',
                    }}
                  >
                    {getActionText(actionKey, translate)}
                  </Box>
                </Box>
                <HandleActionData action={actionKey} actionData={actionData} />
              </IndentWrapper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const HistoryContentItem = ({
  label, fromData, toData,
}) => {
  return (
    <Box
      display="flex"
      style={{
        alignItems: 'center',
      }}
    >
      <ChangingLabel label={label} />
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Box component="span">{fromData.toString()}</Box>
        <ArrowRightAltIcon
          style={{
            fontSize: '18px',
          }}
        />
        <Box component="span">{toData.toString()}</Box>
      </Box>
    </Box>
    
  );
};

export default function AuditLogSection({ resourceName, recordId }) {
  const classes = useStyle();
  const muiTheme = useTheme();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [auditLogData, setAuditLogData] = useState({
    loading: true,
    data: [],
    total: 0,
  });
  const currentPage = useRef();

  const fetchAuditLogByResource = async (resourceName, recordId, page = 1) => {
    try {
      const { data: newAuditLogData, total } = await dataProvider.getList('audit-log', {
        pagination: {
          page,
          perPage: 5,
        },
        filter: {
          'recordId||$eq||': recordId,
          'resource.name||$eq||': resourceName,
        },
        sort: {
          field: 'created',
          order: 'DESC',
        }
      });

      setAuditLogData({
        loading: false,
        data: [...auditLogData.data, ...newAuditLogData],
        total,
      });

      currentPage.current = page;
    } catch (error) {
      console.debug('Error: at fetching audit-log\n', error);
    }
  };
  
  useEffect(() =>  {
    fetchAuditLogByResource(resourceName, recordId);
  }, [resourceName, recordId]);

  return (
    <Card
      style={{
        marginTop: '-2px',
        padding: '32px 50px',
        borderTop: '1px dashed #cccccc',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box
        style={{
          fontWeight: 700,
          fontSize: '24px',
          marginBottom: '15px',
        }}
      >
        {translate('ra.text.changeLogs')}
      </Box>
      <Box>
        {auditLogData.data.length > 0 && auditLogData.data.map(item => {
          let changedData = null;
          try {
            changedData = JSON.parse(item.description);
          } catch (error) {
            console.debug("Error: at parse json data to changed data\n", error);
          }

          if (!changedData) {
            return null;
          }

          return (
            <>
              <HistoryContent
                avatar={(
                  <Avatar
                    style={{
                      fontSize: '18px',
                      backgroundColor: orange[500],
                      width: '30px',
                      height: '30px',
                    }}
                  >
                    {getUserAvatar(item.user?.username)}
                  </Avatar>
                )}
                username={item.user?.username}
                data={changedData}
                method={item.action}
                createdDate={item.created}
                recordId={item.id}
              />
            </>
          );
        })}

        {!auditLogData.loading && auditLogData.data.length === 0 && <Empty text={translate('ra.text.noDetails')} />}

        {auditLogData.loading && <AuditLogSkeleton />}

        {!auditLogData.loading && auditLogData.data?.length !== auditLogData.total && (
          <Box marginTop="16px" display="flex" justifyContent="center">
            <SmallButton
              label="ra.action.showMore"
              onClick={() => {
                setAuditLogData({
                  ...auditLogData,
                  loading: true,
                });
                fetchAuditLogByResource(resourceName, recordId, currentPage.current + 1);
              }}
            />
            </Box>
        )}
      </Box>
    </Card>
  );
}

AuditLogSection.propTypes = {};

AuditLogSection.defaultProps = {};
