import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '& .flag': {
      fontSize: 30,
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
  },
}));

const LanguageItem = ({
  language, onClick,
}) => {
  const {
    name, icon,
  } = language;
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, 'pointer')}
      onClick={() => onClick(language)}
    >
      <i className={`flag flag-24 flag-${icon}`} />
      <Box ml={3}>{name}</Box>
    </Box>
  );
};

LanguageItem.defaultProps = {
  language: {},
  onClick: () => {},
};

LanguageItem.propTypes = {
  language: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default LanguageItem;
