/* eslint-disable consistent-return */
import React, { cloneElement, createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useForm } from 'react-final-form';

import { fetchDefaultBetSetting, getBetSettingInfo } from '../../../../services/util/bet-setting.utils';
import { getLimitRanges } from '../../../../routes/bet-setting/components/bet-setting-create-form';

const BetSettingFormContext = createContext({
  defaultSettingValue: null,
  setDefaultSettingValue: noop,

  currentSettingDefaultValue: {},
  setCurrentSettingDefaultValue: noop,

  currentSettingValue: {},
  setCurrentSettingValue: noop,

  betSettingDefaultLoaded: false,
  setBetSettingDefaultLoaded: noop,

  getBetSettingDefault: noop,
});

export const useBetSettingFormContext = () => useContext(BetSettingFormContext);

const generateBetSettingDefaultCacheKey = (gameId, currencyId) => `${gameId}__${currencyId}`;
const betSettingDefaultCache = {};

export const BetSettingFormProvider = ({
  children, sourceNamePrefix, game, currency, ...restProps
}) => {
  const dataProvider = useDataProvider();
  const form = useForm();
  const baseBet = game?.baseBet;

  const [betSettingDefaultFromCache] = useState(() => {
    if (game?.id && currency?.id) {
      const cacheKey = generateBetSettingDefaultCacheKey(game.id, currency.id);
      return betSettingDefaultCache[cacheKey];
    }
  });

  const [defaultSettingValue, setDefaultSettingValue] = useState(() => {
    if (betSettingDefaultFromCache) {
      return betSettingDefaultFromCache;
    }
    return null;
  });

  const [currentSettingValue, setCurrentSettingValue] = useState();
  const [currentSettingDefaultValue, setCurrentSettingDefaultValue] = useState();
  const [betSettingDefaultLoaded, setBetSettingDefaultLoaded] = useState(() => {
    if (betSettingDefaultFromCache) {
      return true;
    }
    return undefined;
  });
  const [defaultSettingApplyRef, setDefaultSettingApplyRef] = useState({});

  const getBetSettingDefault = async (gameId, currencyId) => {
    setBetSettingDefaultLoaded(false);

    try {
      // Data of default bet setting
      const data = await fetchDefaultBetSetting(dataProvider, {
        gameId,
        currencyId,
      });

      // Cache Bet Setting Default
      const cacheKey = generateBetSettingDefaultCacheKey(gameId, currencyId);
      betSettingDefaultCache[cacheKey] = data;

      if (!isEmpty(data)) {
        setDefaultSettingValue(data);
      } else {
        setDefaultSettingValue({});
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting Default', err?.message);
    } finally {
      setBetSettingDefaultLoaded(true);
    }
  };

  const resetBetSettingDefault = () => {
    const [listSettingObj, listSettingDefaultObj] = getBetSettingInfo(defaultSettingValue);

    // Reset list of betSize & betLevel to default
    if (!isEmpty(listSettingObj)) {
      setCurrentSettingValue(listSettingObj);
    }

    // Reset `default value` of betSize & betLevel to default
    if (!isEmpty(listSettingDefaultObj)) {
      setCurrentSettingDefaultValue(listSettingDefaultObj);
    }

    // Reset totalBetLimit
    const [totalBetMin, totalBetMax] = getLimitRanges(listSettingObj.betSize, listSettingObj.betLevel, baseBet);

    form.change(`${sourceNamePrefix}totalBetMin`, totalBetMin ?? defaultSettingValue?.totalBetLimit?.[0]);
    form.change(`${sourceNamePrefix}totalBetMax`, totalBetMax ?? defaultSettingValue?.totalBetLimit?.[1]);
  };

  return (
    <BetSettingFormContext.Provider
      value={{
        defaultSettingApplyRef,
        setDefaultSettingApplyRef,

        defaultSettingValue,
        setDefaultSettingValue,

        currentSettingDefaultValue,
        setCurrentSettingDefaultValue,

        currentSettingValue,
        setCurrentSettingValue,

        betSettingDefaultLoaded,
        setBetSettingDefaultLoaded,

        getBetSettingDefault,
        resetBetSettingDefault,

        baseBet,
      }}
    >
      {cloneElement(children, {
        ...restProps,
      })}
    </BetSettingFormContext.Provider>
  );
};

BetSettingFormProvider.defaultProps = {
  children: null,
  sourceNamePrefix: '',
  game: {},
  currency: {},
};

BetSettingFormProvider.propTypes = {
  children: PropTypes.node,
  sourceNamePrefix: PropTypes.string,
  game: PropTypes.object,
  currency: PropTypes.object,
};
