import React from 'react';
import useNavigation from '../../../../../../base/hooks/useNavigation';
import { PageBreadcrumbs, PageHeader as DefaultPageHeader } from '../../../PageComponents';

const PageHeader = () => {
  const { breadcrumbs, pageTitleInfo } = useNavigation();

  // Filter out the "Home" breadcrumb
  const filteredBreadcrumbs = breadcrumbs.filter((breadcrumb) => breadcrumb.label !== 'Home');

  return (
    <DefaultPageHeader
      pageTitleInfo={pageTitleInfo}
      breadcrumbComponent={filteredBreadcrumbs.length > 0 && <PageBreadcrumbs items={filteredBreadcrumbs} />}
    />
  );
};

export default PageHeader;
