/* eslint-disable */
import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { PREPAID_ISSUER } from '../../../constant';

const useStyles = makeStyles(() => ({
  infoButton: {
    padding: '0 0 0 8px',
    marginTop: -4,
    '&:hover': {
      background: 'transparent',
    },
  },
  label: {
    pointerEvents: 'auto',
    cursor: 'text',
  },
}));

const IssuerSelector = ({ disabled, ...otherProps }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <SelectInput
      variant="outlined"
      source="issuer"
      label={
        <Box display="flex">
          <Box component="span">{translate('resources.prepaid.fields.issuer')}</Box>
          {Boolean(disabled) && (
            <Tooltip placement="top-end" title={translate('resources.prepaid.nonPermissionToUpdateIssuerMessage')}>
              <IconButton className={classes.infoButton}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
      InputLabelProps={{
        className: classes.label,
      }}
      choices={Object.values(PREPAID_ISSUER).map((issuer) => ({
        id: issuer,
        name: issuer,
      }))}
      defaultValue={PREPAID_ISSUER.brand}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default IssuerSelector;
