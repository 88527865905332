import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useGetList, useLocale, useRefresh, useSetLocale, useTranslate } from 'react-admin';
import moment from 'moment';
import LanguageItem from './LanguageItem';
import { useLanguageContext } from '../../../../context/language';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import './asset/sprite-flags-24x24.css';
import useStyles from './index.style';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import CmtList from '../../../../../theme/@coremat/CmtList';

let firstLoad = true;
const WaLanguage = {};

const LanguageSwitcherMobile = props => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  const locale = useLocale();
  const setLocale = useSetLocale();
  const translate = useTranslate();
  const { setLanguage } = useLanguageContext();
  const refresh = useRefresh();

  const { setLocalStorage } = useLocalStorage('waLocale');

  const toggleLanguageSwitcher = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const switchLanguage = language => {
    setLocale(language.locale);
    setLocalStorage(language.locale);
    handleClose();

    // Avoid unnecessary issue
    window.location.reload();
  };

  const {
    data, error,
  } = useGetList('language', {
    page: 1,
    perPage: 100,
  });

  if (!error && data) {
    Object.keys(data).forEach(langId => {
      // WaLanguage[data[langId].locale] = data[langId];

      // Filter out the "cn" locale data
      if (data[langId].locale !== 'cn') {
        WaLanguage[data[langId].locale] = data[langId];
      }
    });
  }

  useEffect(() => {
    const text = translate('ra.boolean.true');
    if (text === '' && firstLoad && locale) {
      firstLoad = false;
      setLocale(locale);
    }
  });

  useEffect(() => {
    if (WaLanguage?.[locale]?.enabled) {
      setLanguage(WaLanguage?.[locale]);
      moment.locale(locale);
    } else {
      const langEnabled = Object.values(data || {}).find(lang => lang.enabled);
      if (langEnabled) {
        switchLanguage(langEnabled);
        setLanguage(langEnabled);
        moment.locale(langEnabled.locale);
      }
    }
  }, [WaLanguage?.[locale]]);

  // Refresh to change languages for report modules
  useEffect(() => {
    refresh();
  }, [locale]);

  const enableLanguages = Object.values(WaLanguage).filter(lang => lang.enabled);
  const displayLanguages = enableLanguages.filter(lang => lang.locale !== locale);

  const currentLanguage = enableLanguages.find(lang => lang.locale === locale);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <IconButton
        size="small"
        onClick={toggleLanguageSwitcher}
        data-tut="reactour__localization"
        style={{
          padding: '12px 24px',
          justifyContent: 'flex-start',
          border: '1px solid #DDDDDD',
          borderRadius: '4px',
        }}
      >
        <i className={`flag flag-24 flag-${WaLanguage?.[locale]?.icon || 'us'}`} />
        <Box marginLeft="12px">{currentLanguage?.name}</Box>
      </IconButton>

      <Box>
        <Box display={open ? 'block' : 'none'}>
          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={displayLanguages}
              renderRow={(item, index) => (
                <LanguageItem
                  key={index}
                  language={item}
                  onClick={switchLanguage}
                />
              )}
            />
          </PerfectScrollbar>
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageSwitcherMobile;
