/* eslint react/prop-types: 0 */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { useTranslate } from 'ra-core';
import * as React from 'react';
import { FormWithRedirect, useNotify } from 'react-admin';
import { Button, SaveButton } from '../ra/buttons';
import { PasswordInput } from '../ra/inputs';

function OwnerChangePasswordForm(props) {
  const {
    open, close, record: user,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();

  // @TODO move to separated validation module if needed
  function matchPassword(
    message = 'wa.exception.newPasswordAndConfirmPasswordNotMatch',
  ) {
    return function (value, allValues) {
      return value === allValues.newPassword ? undefined : translate(message);
    };
  }

  async function handleSubmit(formValues) {
    try {
      await axios.put(
        'api/auth/password/change',
        {
          currentPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
          confirmPassword: formValues.confirmPassword,
        },
      );

      close();

      const translatedPasswordField = translate('resources.auth.fields.password');
      notify('ra.notification.updated', 'info', {
        smart_name: translatedPasswordField,
      });
    } catch (error) {
      // Do nothing!
    }
  }

  return (
    <Dialog
      fullWidth
      open={!!open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {translate('ra.text.changePasswordOf', {
          smart_text: `# ${user.username}`,
        })}
      </DialogTitle>
      <FormWithRedirect
        save={handleSubmit}
        render={({
          handleSubmitWithRedirect, pristine, saving,
        }) => (
          <form>
            <DialogContent>
              <PasswordInput
                label="ra.field.currentPassword"
                source="currentPassword"
                autoComplete="new-password"
                fullWidth
              />
              <PasswordInput
                label="ra.field.newPassword"
                source="newPassword"
                autoComplete="new-password"
                fullWidth
              />
              <PasswordInput
                label="ra.field.confirmPassword"
                source="confirmPassword"
                autoComplete="new-password"
                fullWidth
                validate={matchPassword()}
              />
            </DialogContent>
            <DialogActions>
              <Button
                label={translate('ra.action.close')}
                onClick={close}
                variant="text"
                color="primary"
                size="small"
              />
              <SaveButton
                label={translate('ra.action.confirm')}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                size="small"
                color="primary"
                variant="text"
                disabled={pristine}
                icon={<></>}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default OwnerChangePasswordForm;
