/* eslint-disable react/prop-types */
import React from 'react';
import { Labeled } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import PlayUrlDetailField from './components/playUrl.field';

function GameShow(props) {
  return (
    <WealthShowGuesser
      {...props}
      excludeFields={['playUrl']}
      extraBlocks={[
        <Labeled label="resources.game.fields.playUrl">
          <PlayUrlDetailField
            source="playUrl"
            {...props}
          />
        </Labeled>,
      ]}
    />
  );
}

export default GameShow;
