import { Box, fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const bgImage = '/images/auth/auth-bg-pattern.png';

const useStyles = makeStyles(theme => ({
  authWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: props => (props.variant === 'bgColor' ? `URL(${bgImage})` : ''),
    backgroundPosition: props => (props.variant === 'bgColor' ? 'center center' : ''),
    backgroundRepeat: props => (props.variant === 'bgColor' ? 'no-repeat' : ''),
    backgroundSize: props => (props.variant === 'bgColor' ? 'cover' : ''),
    position: 'relative',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: 40,
      width: '100%',
      '&:before': {
        content: '""',
      },
    },
    '&:before': {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: props => (props.variant === 'bgColor' ? fade(theme.palette.primary.main, 0.5) : ''),
    },
  },
  authCard: {
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    maxWidth: props => {
      const maxWidth = {
        default: '850px',
        ['cheat-mode']: '765px',
      };
      return maxWidth[props.variant] || '550px';
    },
    width: '100%',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: props => (props.variant === 'default' ? '1050px' : '750px'),
    },
  },
}));

const AuthWrapper = ({
  children, variant, cardStyle
}) => {
  const classes = useStyles({
    variant,
  });
  return (
    <Box className={classes.authWrap}>
      <Box className={classes.authCard} style={cardStyle}>{children}</Box>
    </Box>
  );
};

export default AuthWrapper;
