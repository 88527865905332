/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';
import { get, isEmpty, isObject } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import queryString from 'querystring';
import { useAuthUser } from '../../../hooks';
import exporters from '../../ra/list/exporters';
import SearchPanel from './search-panel';
import DatagridContainer from './dataGridContainer';
import { FormContextProvider } from './form.context';
import ReportCustomList from './reportCustomList';
import { canI, RbacAction } from '../../../../services/provider/rbacProvider';

function ReportListGuesser(props) {
  const {
    reportInterface, resource, extraActions, defaultCurrency, optionsSearch, defaultRefFilter, ...rest
  } = props;

  const { permissions } = rest;

  const location = useLocation();
  const user = useAuthUser();
  const [defaultFiltersProps, setDefaultFilter] = useState({});
  const [isMounted, setMounted] = useState(false);

  const exporter = Object.keys(exporters).includes(resource) ? exporters[resource] : undefined;

  const reduceAllowFields = (acc, cur) => {
    const allowListProperties = Object.keys(cur.allow);
    const isAllow = allowListProperties.every(key => get(user, key) === cur.allow[key]?.default);

    if (!isAllow) {
      acc.push(cur.source);
    }

    return acc;
  };

  const getExcludingFields = () => {
    const isArray = Array.isArray(optionsSearch?.excludeSearchFields);
    const initialExcludingFields = isArray ? optionsSearch?.excludeSearchFields : [];

    const excludingFieldsFromServer = reportInterface.filter
      ?.filter(fItem => isObject(fItem.allow))
      ?.reduce(reduceAllowFields, []);

    return [...initialExcludingFields, ...excludingFieldsFromServer];
  };

  useEffect(() => {
    const defaultFilters = reportInterface.filter?.reduce((acc, cur) => {
      if (cur.default) {
        acc[cur.source] = cur.default;
      }

      return acc;
    }, {});

    let filterProps = {
      ...defaultFilters,
      ...(rest.filterDefaultValues || {}),
    };

    const { filter = '{}' } = queryString.parse(location.search.substring(1)) || {};

    try {
      const filterLocation = JSON.parse(filter);
      if (!isEmpty(filterLocation)) {
        filterProps = filterLocation;
      }
    } catch {
      // Do nothing!
    }

    setMounted(true);
    setDefaultFilter(filterProps);
  }, []);

  const newOptionsSearch = {
    ...optionsSearch,
    excludeSearchFields: getExcludingFields(),
  };

  const searchPanel = !isEmpty(reportInterface.filter) && (
    <SearchPanel
      filters={reportInterface.filter}
      reportServiceVersion={reportInterface.serviceVersion}
      resource={resource}
      optionsSearch={newOptionsSearch}
      defaultFilters={defaultFiltersProps}
      defaultRefFilter={defaultRefFilter}
      showExporter={
        canI(RbacAction.EXPORT, resource, permissions)
        && canI(RbacAction.LIST, resource, permissions)
        && canI(RbacAction.READ, resource, permissions)
      }
      filterProps={rest.filterProps}
    />
  );

  const refactoredResponse = useMemo(() => {
    const newResponse = Object.keys(reportInterface.response).reduce((currentResponse, currentFieldKey) => {
      const fieldData = reportInterface.response[currentFieldKey];

      // Quick fix: Remove sort from playerCount fields in all reports without any server side updates
      if (currentFieldKey === 'playerCount') {
        fieldData.sortable = false;
      }

      currentResponse[currentFieldKey] = fieldData;

      return currentResponse;
    }, {});
    return newResponse;
  }, [reportInterface.response]);

  return (
    isMounted && (
      <FormContextProvider initValues={defaultFiltersProps}>
        <ReportCustomList
          {...rest}
          hideExporter
          resource={resource}
          searchPanel={searchPanel}
          bulkActionButtons={false}
          exporter={exporter}
        >
          <DatagridContainer
            response={refactoredResponse}
            defaultCurrency={defaultCurrency}
            extraActions={extraActions}
            excludeSearchFields={newOptionsSearch.excludeSearchFields}
          />
        </ReportCustomList>
      </FormContextProvider>
    )
  );
}

ReportListGuesser.propTypes = {
  optionsSearch: PropTypes.object,
  reportInterface: PropTypes.object.isRequired,
  resource: PropTypes.string,
  extraActions: PropTypes.node,
  defaultCurrency: PropTypes.object,
  defaultRefFilter: PropTypes.object,
  filterProps: PropTypes.object,
};

ReportListGuesser.defaultProps = {
  resource: '',
  extraActions: undefined,
  defaultCurrency: {},
  optionsSearch: {},
  defaultRefFilter: {},
  filterProps: {},
};

export default ReportListGuesser;
