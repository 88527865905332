/* eslint-disable */
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  useDeleteWithUndoController, useResourceContext
} from "ra-core";
import * as React from "react";
import Button from "./Button";


const DeleteWithUndoButton = (props) => {
  const {
    label = "ra.action.delete",
    classes: classesOverride,
    className,
    icon = defaultIcon,
    onClick,
    record,
    basePath,
    redirect = "list",
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const classes = useStyles(props);
  const resource = useResourceContext(props);
  const { loading, handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    basePath,
    redirect,
    onClick,
    onSuccess,
    onFailure,
  });

  return (
    <Button
      onClick={handleDelete}
      disabled={loading}
      label={label}
      className={classnames(
        "ra-delete-button",
        classes.deleteButton,
        className
      )}
      key="button"
      {...rest}
    >
      {icon}
    </Button>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  {
    name: "RaDeleteWithUndoButton",
  }
);

const defaultIcon = <ActionDelete />;

DeleteWithUndoButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default DeleteWithUndoButton;
