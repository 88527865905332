import React from 'react';
import { useTranslate, useRefresh, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import DeleteButton from './delete-button/delete.button';
import { useError } from '../../../../hooks';

const useStyles = makeStyles(() => ({
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function RowActionField(props) {
  const {
    hasDelete, extraActions, role, deleteButtonProps, ...rest
  } = props;
  const {
    resource, record,
  } = rest;

  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const { notifyError } = useError();

  const onDeleteSuccess = () => {
    const translatedResourceName = deleteButtonProps?.translatedResourceName || translate(`resources.${resource}.name`);
    const onDeleteSuccessMessage = translate('ra.notification.deleted', {
      smart_name: translatedResourceName,
    });

    notify(onDeleteSuccessMessage, 'success');
    refresh();
  };

  const onDeleteFailure = error => {
    notifyError(error);
  };

  const shouldHiddenDelete = resource === 'user' && record?.role?.level >= role?.level;

  return (
    <div className={classes.actionButton}>
      {hasDelete && !shouldHiddenDelete && (
        <DeleteButton
          {...rest}
          confirmTitle={deleteButtonProps?.confirmTitle}
          confirmContent={deleteButtonProps?.confirmContent}
          onSuccess={onDeleteSuccess}
          onFailure={onDeleteFailure}
        />
      )}
      {extraActions && React.cloneElement(extraActions, rest)}
    </div>
  );
}

RowActionField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  hasDelete: PropTypes.bool,
  extraActions: PropTypes.element,
  role: PropTypes.object,
  deleteButtonProps: PropTypes.shape({
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    translatedResourceName: PropTypes.string,
  }),
};

RowActionField.defaultProps = {
  record: {},
  label: 'Action',
  hasDelete: false,
  extraActions: null,
  role: {},
  deleteButtonProps: null,
};

export default RowActionField;
