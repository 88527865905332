import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { CUSTOM_RESOURCES } from '../../../constant/customResources';

const useConfiguration = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleFetchConfig = async () => {
    try {
      const response = await axios.get(`api/${CUSTOM_RESOURCES.VERTBET_CONFIG.name}`);
      const config = response.data.data?.[0];
      setData(config);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchConfig();
  }, []);

  return useMemo(
    () => ({
      data,
      isLoading,
    }),
    [isLoading, data],
  );
};

export default useConfiguration;
