import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import ActionList from './components/action.list';
import FailedRetryFilter from './components/FailedRetryFilter';

const SeamlessLogList = props => (
  <WealthListGuesser
    {...props}
    actions={<ActionList />}
    extraFilterInputs={[<FailedRetryFilter />]}
  />
);

export default SeamlessLogList;
