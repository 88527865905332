/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField, Chip, CircularProgress, makeStyles, Box, Button } from '@material-ui/core';
import { useDataProvider, useTranslate } from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import { useForm, Field } from 'react-final-form';
import { debounce } from 'lodash';
import resourceSlug from '../../../../constant/resource-slug';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';
import clsx from 'clsx';

const PLAYERS_KEY = 'players';
const ERR_PLAYER_INVALID = 'wa.exception.playerInvalid';

const useStyles = makeStyles(
  (theme) => ({
    errorChip: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
    chipContainer: {
      maxHeight: 300,
      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: 4,
    },
    clearButton: {
      color: theme.palette.error.main,
      borderColor: "inherit",
      padding: "2px 4px",
      fontSize: 12
    },
  }),
  [],
);

const PlayerAutocomplete = ({ label, disabled, groupId, brandId, currencyId, value, error }) => {
  const classes = useStyles();
  const isActive = useRef(false);
  const currentPlayers = useRef(value);
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const form = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [invalidPlayers, setInvalidPlayers] = useState([]);

  const handleChange = useCallback((_, newValue) => {
    const newPlayerNativeIds = newValue?.map((player) => player?.nativeId || player);

    const newPlayers = [...new Set(newPlayerNativeIds)].map((nativeId) => ({ nativeId }));

    form.change(PLAYERS_KEY, newPlayers);
  }, []);

  const fetchOptions = useCallback(
    debounce(async (value) => {
      let newData = [];
      try {
        const { data } = await dataProvider.getList(resourceSlug.PLAYER, {
          pagination: {
            page: 1,
          },
          filter: {
            groupId,
            brandId,
            'wallet.currencyId': currencyId,
            nativeId: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
            [`nativeId${FILTER_PREFIX_ON_VALUE.NOT_IN.replace(/\*/g, '')}`]: currentPlayers.current
              ?.map((player) => player.nativeId)
              ?.join(','),
          },
        });
        newData = data;
      } catch (err) {
        console.error('[ERROR] Get Player List', err?.message);
      } finally {
        if (!isActive.current) return;
        setIsLoading(false);
        setOptions(newData);
      }
    }, 350),
    [groupId, brandId, currencyId],
  );

  const handleInputChange = useCallback(
    (newInputValue) => {
      fetchOptions(newInputValue);
    },
    [fetchOptions],
  );

  useEffect(() => {
    isActive.current = true;

    return () => {
      isActive.current = false;
    };
  }, []);

  useEffect(() => {
    currentPlayers.current = value;
  }, [value]);

  useEffect(() => {
    if (error?.body?.key !== ERR_PLAYER_INVALID) return;
    setInvalidPlayers(error?.body?.data || []);
  }, [error]);

  useEffect(() => {
    setInvalidPlayers([]);
    setOptions([]);
  }, [groupId, brandId, currencyId]);

  return (
    <Field name={PLAYERS_KEY}>
      {(props) => {
        return (
          <Autocomplete
            multiple
            disabled={disabled}
            variant="outlined"
            fullWidth
            value={props.input.value ?? []}
            options={options}
            onOpen={() => {
              handleInputChange('');
            }}
            loading={isLoading}
            freeSolo
            renderTags={(tagValue, getTagProps) =>
              tagValue?.map((option, index) => {
                const label = option.nativeId || option;
                return (
                  <Chip
                    {...getTagProps({
                      index,
                    })}
                    className={clsx({ [classes.errorChip]: invalidPlayers.includes(label) })}
                    label={label}
                  />
                );
              })
            }
            onChange={handleChange}
            onInputChange={(e, newInputValue) => {
              setIsLoading(true);
              setOptions([]);
              handleInputChange(newInputValue);
            }}
            getOptionLabel={(option) => option.nativeId}
            renderInput={(params) => {
              const { InputProps, ...restParams } = params;
              const { startAdornment, ...restInputProps } = InputProps;
              const hasInvalidPlayers = Boolean(
                invalidPlayers?.length > 0 &&
                  props.input.value?.find?.((player) => invalidPlayers.includes(player?.nativeId || player)),
              );

              return (
                <TextField
                  {...restParams}
                  error={hasInvalidPlayers}
                  helperText={
                    hasInvalidPlayers && (
                      <Button
                      className={classes.clearButton}
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          form.change(
                            PLAYERS_KEY,
                            props.input.value.filter((player) => !invalidPlayers.includes(player?.nativeId || player)),
                          )
                        }
                      >
                        {t('resources.prepaid.clearInvalidPlayers')}
                      </Button>
                    )
                  }
                  label={label}
                  placeholder={t('resources.prepaid.fields.players')}
                  InputProps={{
                    ...restInputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {restInputProps.endAdornment}
                      </>
                    ),
                    startAdornment: <Box className={classes.chipContainer}>{startAdornment}</Box>,
                  }}
                />
              );
            }}
          />
        );
      }}
    </Field>
  );
};

export default PlayerAutocomplete;
