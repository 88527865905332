import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AutocompleteInput } from '../../../../base/components/ra/inputs';
import { getAvailableBrandsEndpoint } from '../partner.list';

const BrandInput = ({
  groupId, source, initialValue, ...otherProps
}) => {
  const [availableBrands, setAvailableBrands] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    axios
      .get(getAvailableBrandsEndpoint(groupId))
      .then(({ data }) => {
        setAvailableBrands(data.data);
      })
      .finally(() => setIsFetching(false));
  }, [groupId]);

  useEffect(() => {
    if (!initialValue || availableBrands.find(brand => brand.brandId === initialValue?.brandId)) return;
    setAvailableBrands([...availableBrands, initialValue]);
  }, [initialValue, availableBrands]);

  return (
    <AutocompleteInput
      translateChoice={false}
      fullWidth
      source={source}
      isLoading={isFetching}
      choices={availableBrands}
      optionText="name"
      optionValue="brandId"
      resettable
      {...otherProps}
    />
  );
};

BrandInput.propTypes = {
  groupId: PropTypes.string.isRequired,
  source: PropTypes.string,
  initialValue: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

BrandInput.defaultProps = {
  source: 'brandId',
  initialValue: null,
};

export default BrandInput;
