/* eslint-disable camelcase */
import { useMemo } from 'react';
import useAccessToken from './useAccessToken';
import useSetting from './useSetting';

import { getBackofficeEnv } from '../../services/util';
import { ROLE_TYPE } from '../../constant';
import { appBrandConfigMapping } from '../../constant/appBrandMapping';

const useSite = () => {
  const accessTokenData = useAccessToken();
  const {
    site_favicon, site_name,
  } = useSetting();

  const { APP_BRAND } = getBackofficeEnv();
  const {
    faviconDefault, getTabTitle,
  } = appBrandConfigMapping[APP_BRAND];

  const userGroupCode = accessTokenData.group?.code;
  const faviconUrlFromServer = site_favicon ? `api/blob/static?blobId=${site_favicon}` : null;

  const userRoleType = useMemo(() => {
    if (accessTokenData.group) {
      return ROLE_TYPE.group;
    }
    return ROLE_TYPE.system;
  }, [accessTokenData]);

  // Config for per Role's Type (system or group)
  const USER_TYPE_OPTIONS = {
    [ROLE_TYPE.system]: {
      favicon: faviconUrlFromServer || faviconDefault,
      title: getTabTitle({
        siteNameFromServer: site_name,
        userGroupCode,
        roleType: ROLE_TYPE.system,
      }),
    },
    [ROLE_TYPE.group]: {
      favicon: faviconUrlFromServer || faviconDefault,
      title: getTabTitle({
        siteNameFromServer: site_name,
        userGroupCode,
        roleType: ROLE_TYPE.group,
      }),
    },
  };

  const getUrl = () => window.location.href;

  const getAuthor = () => window.location.origin;

  return {
    title: USER_TYPE_OPTIONS[userRoleType].title,
    favicon: {
      href: USER_TYPE_OPTIONS[userRoleType].favicon,
      default: faviconDefault,
    },
    url: getUrl(),
    author: getAuthor(),
  };
};

export default useSite;
