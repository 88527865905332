import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateRangeInput from '../../../base/components/guesser/date-range-input';

const BetDateRangeInput = props => {
  const {
    source, resource, label, ...rest
  } = props;

  const today = moment().endOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  const disabledDate = (current, dates) => {
    const startDate = dates[0] && moment(dates[0].format()).startOf('day');
    const endDate = dates[1] && moment(dates[1].format()).startOf('day');

    const currentDate = moment(current.format()).startOf('day');
    const now = moment();

    const numberOfDateLimit = 7;
    const numberOfMonthLimit = 3;

    const tooLate = startDate
      ? currentDate.diff(startDate, 'days') >= numberOfDateLimit || currentDate.isAfter(now)
      : currentDate.isAfter(now);

    const tooEarly = endDate
      ? endDate.diff(currentDate, 'days') >= numberOfDateLimit
        || currentDate.isBefore(now.subtract(numberOfMonthLimit, 'months'))
      : now.diff(currentDate, 'months') >= numberOfMonthLimit && currentDate.isBefore(now);

    return !!tooEarly || !!tooLate;
  };

  return (
    <DateRangeInput
      entity="report"
      source={source}
      resource={resource}
      label={label}
      prefix
      clearable={false}
      dateRange={[yesterday, today]}
      disabledDate={disabledDate}
      ranges={[]}
      {...rest}
    />
  );
};

BetDateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
};

BetDateRangeInput.defaultProps = {
  label: '',
};

export default BetDateRangeInput;
