import { Card } from '@material-ui/core';
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import { ShowSection } from '../../../base/components/guesser/wealth-show.guesser';
import CommonShowWrapper from '../../../base/components/customized/common-wrapper/CommonShowWrapper';
import { DateField } from '../../../base/components/ra/fields';
import Labeled from '../../../base/components/ra/labeled';
import { CUSTOM_RESOURCES } from '../../../constant/customResources';

const PartnerShow = props => {
  const translate = useTranslate();

  return (
    <CommonShowWrapper {...props}>
      <Card>
        <ShowSection title={translate('common.show.general')}>
          <TextField
            source="id"
            label="common.fields.id"
          />
          <Labeled label="common.fields.created">
            <DateField source="createdAt" />
          </Labeled>
          <Labeled label="common.fields.updated">
            <DateField source="updatedAt" />
          </Labeled>
        </ShowSection>
        <ShowSection title={translate('resources.reverse-integration.partner.partnerInformation')}>
          <TextField
            source="name"
            label="common.fields.name"
          />
          <TextField
            source="partnerId"
            label="resources.reverse-integration.partner.fields.partnerId"
          />
          <ReferenceField
            reference="group"
            source="groupId"
            label="common.fields.group"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference="brand"
            source="brandId"
            label="common.fields.brand"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference={CUSTOM_RESOURCES.VERTBET_WALLET_INTEGRATION.name}
            source="walletIntegrationId"
            label="resources.reverse-integration.reverseWalletIntegration.name"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
        </ShowSection>
      </Card>
    </CommonShowWrapper>
  );
};

export default PartnerShow;
