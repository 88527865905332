/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  FunctionField,
  SelectInput,
  SimpleFormIterator,
  useDataProvider,
  useLocale,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Tooltip, IconButton, makeStyles, Divider, FormLabel, Chip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import resourceSlug from '../../../constant/resource-slug';
import { get } from 'lodash';
import { useForm } from 'react-final-form';
import OutlinedDiv from '../../../base/components/outlinedDiv';

const useStyles = makeStyles(() => ({
  arrayContainer: {
    marginTop: 0,
    marginBottom: 0,
    "& [class*='fullWidth']": {
      width: '100%',
    },
  },
  headerWrapper: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    alignItems: 'center',
    marginBottom: 12,
    '& *': {
      fontSize: 12,
      fontWeight: 700,
      color: 'black',
    },
  },
  outlinedDiv: {
    '&:hover [class*="notchedOutline"], & [class*="focused"] [class*="notchedOutline"]': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: 1,
    },
  },
  defaultChip: {
    fontSize: 10,
    padding: 0,
    borderRadius: 4,
  },
  defaultChipLabel: {
    padding: '2px 4px !important', // To override default styles
  },
}));

const PlayUrlMapping = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const locale = useLocale();
  const form = useForm();
  const [availableGames, setAvailableGames] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!record.id) return;

    dataProvider
      .getCustomList(resourceSlug.AVAILABLE_GAME, {
        filter: {
          'brand.id': record.id,
        },
      })
      .then((response) => {
        const games = get(record, 'games', []);
        const data =
          response?.data?.data?.[0]?.games?.sort(
            (prevGame, nextGame) => games.indexOf(prevGame?.id) - games.indexOf(nextGame?.id),
          ) || [];
        const multiplePlayUrlGames = data?.filter((game) => game.playUrl?.length > 1);
        setAvailableGames(multiplePlayUrlGames);
      });
  }, [record.id]);

  useEffect(() => {
    if (!availableGames?.length) return;
    const games = get(record, 'games', []);
    const refactoredGames = availableGames.map((availableGame) => {
      const gameIdxInGames = games.findIndex((game) => game.id === availableGame.id);
      const playUrlDefaultOption = availableGame.playUrl?.findIndex((playUrl) => playUrl?.isDefault);

      if (gameIdxInGames >= 0) {
        return games[gameIdxInGames];
      } else {
        return { id: availableGame.id, playUrlOption: availableGame.playUrlOption ?? playUrlDefaultOption };
      }
    });
    form.change('games', refactoredGames);
    setIsReady(true);
  }, [availableGames]);

  if (!isReady || !availableGames?.length) return null;

  return (
    <OutlinedDiv
      label={
        <Box display="flex">
          <Box>{translate('resources.brand.fields.playUrlMapping')}</Box>
          <Tooltip placement="top-end" title={translate('resources.brand.fields.playUrlMappingTooltip')}>
            <IconButton className={classes.infoButton}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    >
      <Box className={classes.headerWrapper}>
        <Box component={FormLabel}>{translate('resources.available-game.name')}</Box>
        <Box component={FormLabel}>{translate('resources.game.fields.playUrl')}</Box>
      </Box>
      <Divider />
      <ArrayInput className={classes.arrayContainer} source="games" label={false}>
        <SimpleFormIterator
          disableAdd
          disableRemove
          disableReordering
          TransitionProps={{ enter: false, exit: false }}
          getItemLabel={() => false}
        >
          <FormDataConsumer>
            {({
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) => {
              const regex = /games\[(\d+)\]\./;
              const match = getSource('').match(regex);
              const index = parseInt(match[1]);
              const game = availableGames[index];
              const playUrlChoices = game?.playUrl?.map((item, idx) => ({
                id: idx,
                name: item?.url,
                isDefault: item?.isDefault,
              }));
              return (
                <Box display="grid" gridTemplateColumns="40% 60%" alignItems="center">
                  <FunctionField source={getSource('id')} render={() => game?.name?.[locale]} />
                  <SelectInput
                    {...rest}
                    helperText={false}
                    variant="outlined"
                    choices={playUrlChoices}
                    fullWidth
                    source={getSource('playUrlOption')}
                    label={false}
                    optionText={(record) => {
                      return (
                        <Box display="flex" alignItems="center" sx={{ gap: 8 }}>
                          <Box>{record?.name}</Box>
                          <Box>
                            {record?.isDefault && (
                              <Chip
                                className={classes.defaultChip}
                                classes={{
                                  label: classes.defaultChipLabel,
                                }}
                                label={translate('ra.text.default')}
                              />
                            )}
                          </Box>
                        </Box>
                      );
                    }}
                  />
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </OutlinedDiv>
  );
};

export default PlayUrlMapping;
