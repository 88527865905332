/* eslint-disable */
import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const { RangePicker } = DatePicker;

const RangePickerReport = (props) => {
  const {
    translate,
    disabled = false,
    allowClear = true,
    value,
    bordered,
    format,
    locale,
    onChange,
    reportServiceVersion,
    disabledDate: defaultDisabledDate,
    className,
    source,
    ...otherProps
  } = props;

  const [dates, setDates] = useState(null);
  const [hackValue, setHackValue] = useState(null);

  const getNumberOfDateLimit = () => {
    if (reportServiceVersion === 0) {
      return 31; // Limit 1 month with the reports using the 1st service version
    }
    // Set unlimited time range filter with the reports using the rest service versions
    return undefined; // Unlimited
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    } else if (defaultDisabledDate !== undefined) {
      if (typeof defaultDisabledDate === 'function') {
        return defaultDisabledDate(current, dates);
      } else {
        return !!defaultDisabledDate;
      }
    } else {
      const startDate = dates[0] && moment(dates[0].format()).startOf('day');
      const endDate = dates[1] && moment(dates[1].format()).startOf('day');

      const currentDate = moment(current.format()).startOf('day');

      const numberOfDateLimit = getNumberOfDateLimit();

      const tooLate = startDate ? currentDate.diff(startDate, 'days') >= numberOfDateLimit : false;

      const tooEarly = endDate ? endDate.diff(currentDate, 'days') >= numberOfDateLimit : false;

      return !!tooEarly || !!tooLate;
    }
  };

  const disabledTimeOld = (_time, type) => {
    if (!dates) {
      return false;
    }

    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end + 1; i += 1) {
        result.push(i);
      }
      return result;
    };

    if (type === 'start') {
      if (!dates[1]) {
        return false;
      }

      const [hours, minutes, seconds] = [dates[1].hours(), dates[1].minutes(), dates[1].seconds()];

      return {
        disabledHours: () => range(0, 23).slice(0, hours),
        disabledMinutes: () => range(0, 59).slice(0, minutes),
        disabledSeconds: () => range(0, 59).slice(0, seconds),
      };
    }

    if (!dates[0]) {
      return false;
    }

    const [hours, minutes, seconds] = [dates[0].hours(), dates[0].minutes(), dates[0].seconds()];

    return {
      disabledHours: () => range(0, 23).slice(hours + 1, 23),
      disabledMinutes: () => range(0, 59).slice(minutes + 1, 59),
      disabledSeconds: () => range(0, 59).slice(seconds + 1, 59),
    };
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setDates(null);
      setHackValue(null);
    }
  };

  useEffect(() => {
    const dateRangePickerEls = Array.from(document.getElementsByClassName(source));

    if (!dateRangePickerEls?.length) return;

    let inputEls = [];

    try {
      inputEls = Array.from(dateRangePickerEls?.[0]?.getElementsByTagName('input'));
    } catch (error) {
      console.log(error);
    }

    const startDateInput = inputEls[0];
    const endDateInput = inputEls[1];

    const handleStartInputFocus = (e) => {
      if (endDateInput?.value) {
        const newDate = dates || [null, null];
        newDate[1] = moment(endDateInput.value, format);
        setDates(newDate);
      }
    };

    const handleEndInputFocus = (e) => {
      if (startDateInput?.value) {
        const newDate = dates || [null, null];
        newDate[0] = moment(startDateInput.value, format);
        setDates(newDate);
      }
    };

    startDateInput?.addEventListener('focus', handleStartInputFocus);
    endDateInput?.addEventListener('focus', handleEndInputFocus);

    return function cleanUp() {
      startDateInput?.removeEventListener('focus', handleStartInputFocus);
      endDateInput?.removeEventListener('focus', handleEndInputFocus);
    };
  }, [dates, source]);

  return (
    <>
      <RangePicker
        style={{
          maxWidth: '380px',
        }}
        allowClear={allowClear}
        value={dates || value}
        disabled={disabled}
        bordered={bordered}
        format={format}
        locale={locale}
        disabledDate={disabledDate}
        // disabledTime={disabledTime}
        onChange={onChange}
        onCalendarChange={(val) => setDates(val)}
        onOpenChange={onOpenChange}
        ranges={{
          [translate('ra.text.today')]: [moment().startOf('day'), moment().endOf('day')],
          [translate('ra.text.yesterday')]: [
            moment().subtract(1, 'days').startOf('day'),
            moment().subtract(1, 'days').endOf('day'),
          ],
          [translate('ra.text.lastNDays', {
            smart_number: 7,
          })]: [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
          [translate('ra.text.lastMonth')]: [
            moment().subtract(1, 'month').startOf('month').startOf('day'),
            moment().subtract(1, 'month').endOf('month').endOf('day'),
          ],
          [translate('ra.text.monthTillDate')]: [moment().startOf('month').startOf('day'), moment().endOf('day')],
        }}
        {...otherProps}
        className={clsx('date-range-picker', source, className)}
        showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
      />
    </>
  );
};

RangePickerReport.propTypes = {
  resource: PropTypes.string,
  translate: PropTypes.func,
  disabled: false,
  showTime: PropTypes.oneOf([PropTypes.bool, PropTypes.object]),
  allowClear: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.any),
  bordered: PropTypes.bool,
  format: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  reportServiceVersion: PropTypes.number,
  className: PropTypes.string,
  source: PropTypes.string,
};

RangePickerReport.defaultProps = {
  translate: (f) => f,
  disabled: false,
  showTime: false,
  allowClear: false,
  value: [],
  bordered: false,
  format: null,
  locale: 'en',
  onChange: (f) => f,
  resource: '',
  reportServiceVersion: 0,
  className: '',
  source: '',
};

export default memo(RangePickerReport);
