/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslate, FormDataConsumer, required, minValue, useLocale } from 'ra-core';
import { useDataProvider } from 'react-admin';
import { Box, CircularProgress } from '@material-ui/core';
import { get, isEmpty, orderBy } from 'lodash';
import { useForm } from 'react-final-form';
import { useAuthUser, useApiProperties } from '../../../base/hooks';
import { ReferenceInput as ReferenceInputCustom, NumberInput, AutocompleteInput } from '../ra/inputs';
import BetSettingPicker from './bet-setting-picker/bet-setting-picker.input';
import { BetSettingPickerProvider } from '../bet-setting/bet-setting-picker/bet-setting-picker.context';
import { useBetSettingFormContext } from './utils/bet-setting-form.context';
import { checkSystemAdmin, checkTransatableField } from '../../../services/util';
import { validateTotalBetMax, fetchGamesBeingAvailable } from '../../../services/util/bet-setting.utils';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import useTranslateSchema from '../../../base/hooks/useTranslateSchema';
import { TWO_DECIMAL_REGEX } from '../../../services/util/validate/regularExpression';
import { getLimitRanges } from '../../../routes/bet-setting/components/bet-setting-create-form';

const BetSettingCreateForm = (props) => {
  const {
    sourceNamePrefix,
    initByCustomData,
    currency,
    game,
    totalBetMinSourceName,
    totalBetMaxSourceName,
    pickerSourceName,
  } = props;

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const user = useAuthUser();
  const form = useForm();
  const locale = useLocale();
  const formValues = form.getState().values;

  const [loading, setLoading] = useState(true);

  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGame = getPropertiesByFieldName('game');

  const translateSchema = useTranslateSchema();
  const translateSchemaRef = useTranslateSchemaRef();

  const {
    // Store betSettingDefault value reference
    defaultSettingApplyRef,
    setDefaultSettingApplyRef,
    // Default bet setting from API
    defaultSettingValue,
    // Current setting default
    currentSettingDefaultValue,
    setCurrentSettingDefaultValue,
    // Current setting
    currentSettingValue,
    setCurrentSettingValue,
    // State loading bet setting default
    betSettingDefaultLoaded,
    // Utils
    resetBetSettingDefault,
    getBetSettingDefault,
  } = useBetSettingFormContext();

  const baseBet = get(game, 'baseBet') || get(game, 'gameConfig.baseBet') || get(game, 'game.baseBet');
  const isSystemAdmin = checkSystemAdmin(user);

  const totalBetMaxMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMax',
    },
  });

  const totalBetMinMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMin',
    },
  });

  const onlyTwoDigits = (fieldName) =>
    testByRegex(translateSchemaRef, {
      regex: TWO_DECIMAL_REGEX,
      translationSchema: {
        name: 'ra.validation.onlyTwoDigitsAfter',
        params: {
          smart_name: fieldName,
        },
      },
    });

  useEffect(() => {
    if (isEmpty(defaultSettingValue)) {
      setCurrentSettingValue({});
      setCurrentSettingDefaultValue({});

      form.change(totalBetMinSourceName, undefined);
      form.change(totalBetMaxSourceName, undefined);
      return;
    }

    // Will use custom data instead of default data
    if (initByCustomData) {
      return;
    }

    const settingObj = {
      betSize: [],
      betLevel: [],
    };

    const settingDefaultObj = {
      betSize: null,
      betLevel: null,
    };

    // Update betSize list & betSize default value
    defaultSettingValue.betSizes.forEach((item) => {
      settingObj.betSize.push({
        value: item.value,
      });
      if (item.default) {
        settingDefaultObj.betSize = item.value;
      }
    });

    // Update betLevel list & betLevel default value
    defaultSettingValue.betLevels.forEach((item) => {
      settingObj.betLevel.push({
        value: item.value,
      });
      if (item.default) {
        settingDefaultObj.betLevel = item.value;
      }
    });

    setCurrentSettingDefaultValue(settingDefaultObj);
    setCurrentSettingValue(settingObj);
    setDefaultSettingApplyRef({
      listItem: settingObj,
      defaultValue: settingDefaultObj,
    });

    const [totalBetMin, totalBetMax] = getLimitRanges(settingObj.betSize, settingObj.betLevel, baseBet);

    form.change(totalBetMinSourceName, totalBetMin ?? defaultSettingValue?.totalBetLimit?.[0]);
    form.change(totalBetMaxSourceName, totalBetMax ?? defaultSettingValue?.totalBetLimit?.[1]);
  }, [defaultSettingValue, baseBet]);

  useEffect(() => {
    if (!initByCustomData || isEmpty(initByCustomData)) {
      return;
    }

    const { betSize, betLevel, betSizeDefault, betLevelDefault, totalBetLimit } = initByCustomData;

    setCurrentSettingValue({
      betSize,
      betLevel,
    });
    setCurrentSettingDefaultValue({
      betSize: betSizeDefault,
      betLevel: betLevelDefault,
    });
    form.change(totalBetMinSourceName, totalBetLimit?.[0]);
    form.change(totalBetMaxSourceName, totalBetLimit?.[1]);
  }, []);

  useEffect(() => {
    if (betSettingDefaultLoaded) {
      setLoading(false);
    }
  }, [betSettingDefaultLoaded]);

  return (
    <>
      {loading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <FormDataConsumer>
        {({ formData }) => (
          <Box>
            {!isEmpty(game) && !isEmpty(currency) && betSettingDefaultLoaded && (
              <>
                <NumberInput
                  fullWidth
                  source={totalBetMinSourceName}
                  label={translate('ra.field.totalBetMin')}
                  validate={[
                    required(),
                    minValue(0, totalBetMinMustGreatThanZero),
                    onlyTwoDigits('ra.field.totalBetMin'),
                  ]}
                />

                <NumberInput
                  fullWidth
                  source={totalBetMaxSourceName}
                  label={translate('ra.field.totalBetMax')}
                  validate={[
                    required(),
                    minValue(0, totalBetMaxMustGreatThanZero),
                    onlyTwoDigits('ra.field.totalBetMax'),
                    validateTotalBetMax(translateSchemaRef),
                  ]}
                />

                <BetSettingPickerProvider>
                  <BetSettingPicker
                    sourceNamePrefix={sourceNamePrefix}
                    source={pickerSourceName}
                    baseBetInit={baseBet}
                    listItemInit={currentSettingValue}
                    defaultValueInit={currentSettingDefaultValue}
                    onResetSetting={isEmpty(defaultSettingValue) ? null : resetBetSettingDefault}
                    defaultSettingValue={defaultSettingValue}
                  />
                </BetSettingPickerProvider>
              </>
            )}
          </Box>
        )}
      </FormDataConsumer>
    </>
  );
};

export default BetSettingCreateForm;
