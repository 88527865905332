/* eslint-disable */
import React, { useState } from 'react';
import { read } from 'xlsx';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { DeleteRounded } from '@material-ui/icons';

function formatBytes(bytes, decimals = 1) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const UploadDialog = ({ disabled }) => {
  const translate = useTranslate();
  const form = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);

  const handleResetData = () => {
    setData([]);
  };

  const handleCancel = () => {
    setIsOpen(false);
    handleResetData();
  };

  const handleDeleteFile = (fileIdx) => {
    data.splice(fileIdx, 1);
    setData([...data]);
  };

  const handleConfirmToUpload = () => {
    const { players: currentPlayers = [] } = form.getState().values;
    const currentPlayerNativeIds = currentPlayers?.map((player) => player.nativeId) || [];
    const newPlayerNativeIds = data.reduce((currentIds, newData) => {
      return [...currentIds, ...newData?.data];
    }, []);
    const playerNativeIds = [...new Set([...currentPlayerNativeIds, ...newPlayerNativeIds])]?.map((nativeId) => ({
      nativeId,
    }));
    form.change('players', playerNativeIds);
    handleCancel();
  };

  const handleUploadFiles = async (e) => {
    try {
      const file = e.target.files[0];

      /* data is an ArrayBuffer */
      const ab = await file.arrayBuffer();

      /* parse */
      const wb = read(ab);

      /* generate array of id from first worksheet */
      const ws = wb.Sheets[wb.SheetNames[0]] || {}; // get the first worksheet
      const sheetData = Object.values(ws).reduce((currentData, item) => {
        if (item?.v) {
          currentData.push(item.v);
        }

        return currentData;
      }, []); // generate array of id

      const newData = [...data, { name: file?.name, size: file?.size, data: sheetData?.map((row) => row) }];

      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        component={Button}
        height="fit-content"
        minHeight={55}
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        sx={{ "&[type='button']": { minWidth: 150, margin: 0 } }}
      >
        {translate('ra.action.upload')}
      </Box>
      <Dialog fullWidth open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{translate('resources.prepaid.uploadPlayersTitle')}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography>{translate('resources.prepaid.csvFileFormatTitle')}</Typography>
            <Box component="a" href="/template/CSV_file_format.xlsx" target="_blank" download>
              {translate('resources.prepaid.csvFileFormatMessage')}
            </Box>
          </Box>
          <Box sx={{display:"flex", gap: 8, alignItems: "center" }}>
            <Button variant="outlined" component="label" color="primary">
              {translate('ra.action.chooseFile')}
              <Box component="input" type="file" accept=".csv, .xlsx, .xls" hidden onChange={handleUploadFiles} />
            </Button>
            <Box sx={{fontSize: 12, fontStyle: "italic", color: "rgba(0, 0, 0, 0.6)"}}>{`(${translate('resources.prepaid.allowedFileTypes')})`}</Box>
          </Box>
          <Box width="70%" m="auto" display="flex" flexDirection="column" mt={3}>
            {data?.map((item, idx) => (
              <Box key={`${item?.name}_${idx}`} display="grid" gridTemplateColumns={'50% 30% 20%'} sx={{ gap: 16 }}>
                <Typography variant="body2">{item?.name}</Typography>
                <Typography variant="body2">{formatBytes(item?.size)}</Typography>
                <Box
                  component={IconButton}
                  size="small"
                  color="primary"
                  sx={{ width: 'fit-content' }}
                  onClick={() => handleDeleteFile(idx)}
                >
                  <DeleteRounded fontSize="small" />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="text" color="primary" size="small">
            {translate('ra.action.cancel')}
          </Button>
          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={handleConfirmToUpload}
            disabled={!!!data?.length}
          >
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadDialog;
