import React from 'react';
import { ArrayField, SingleFieldList, useRecordContext, useTranslate } from 'react-admin';
import { Box, Chip, Link, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  list: {
    listStyle: 'decimal inside none',
    gap: 6,
    flexDirection: 'column',
  },
  defaultChip: {
    fontSize: 10,
    padding: 0,
    borderRadius: 4,
    marginRight: 4,
  },
  defaultChipLabel: {
    padding: '2px 4px !important', // To override default styles
  },
}));

const PlayUrlDetailField = props => {
  const styles = useStyles();

  return (
    <ArrayField {...props}>
      <SingleFieldList
        component="ol"
        className={styles.list}
        linkType={false}
      >
        <CustomUrlField />
      </SingleFieldList>
    </ArrayField>
  );
};

const CustomUrlField = props => {
  const theme = useTheme();
  const record = useRecordContext();
  const translate = useTranslate();
  const styles = useStyles();

  return (
    <Box
      component="li"
      sx={{
        textAlign: 'left',
        wordBreak: 'break-word',
      }}
    >
      {record?.isDefault && (
        <Chip
          component="span"
          className={styles.defaultChip}
          classes={{
            label: styles.defaultChipLabel,
          }}
          label={translate('ra.text.default')}
        />
      )}
      <Box
        component={Link}
        color="primary.lighter"
        href={record?.url}
        sx={{
          '&[class*="Link"]:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
          },
        }}
        target="_blank"
        {...props}
      >
        {record?.url}
      </Box>
    </Box>
  );
};

export default PlayUrlDetailField;
