export default {
  BET_SETTING: 'bet-setting',
  BET_SETTING_DEFAULT: 'bet-setting-default',
  INBOX_RULE: 'inbox-rule',
  AVAILABLE_GAME: 'available-game',
  GAME: 'game',
  BRAND: 'brand',
  CURRENCY: 'currency',
  PREPAID: 'prepaid',
  PREPAID_ISSUER: 'prepaid-issuer',
  REWARD: 'reward',
  PREPAID_APPROVAL: 'prepaid-approval',
  USER: 'user',
  REPORT_INVOICING: 'report-invoicing',
  PLAYER_STATE_MANAGEMENT: 'player-state-management',
  EXPORT_HISTORY: 'export-history',
  BET: 'bet',
  PLAYER: 'player',
  REPORT_PREPAID: 'report-prepaid',
  REPORT_RTP: 'report-rtp',
  REPORT_OPERATOR_PROFIT: 'report-operator-profit',
  REPORT_SALES_AND_PROFIT: 'report-sales-and-profit',
  REPORT_GAME_PERFORMANCE: 'report-game-performance',
  REPORT_PERFORMANCE: 'report-performance',
  REPORT_PLAYERS: 'report-players',
  REPORT_WINNERS: 'report-winners',
  REPORT_DETAIL_PLAYER: 'report-detail-player',
  GAME_TYPE: 'game-type',
  WAGER: 'wager',
  WALLET: 'wallet',
};

export const REPORT_TYPES = {
  OVERALL: 'overall',
  STANDARD: 'standard',
  PREPAID: 'prepaid',
};
