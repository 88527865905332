/* eslint-disable react/prop-types */
import React, { useEffect, useState, memo, useRef } from 'react';
import { useTranslate, useDataProvider, useListContext, useLocale } from 'react-admin';
import { useMediaQuery, Button, makeStyles } from '@material-ui/core';
// import unionBy from 'lodash/unionBy';

import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useTitleContext } from '../../base/context/title';
import { ActionMenu } from './components/action.menu';
import { useAuthUser } from '../../base/hooks';
import { RoleBuiltIn } from '../../services/util';

const useStyles = makeStyles(theme => ({
  buttonFilter: {
    color: 'white',
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover, &:focus': {
      background: theme.palette.primary.main,
    },
  },
}));

const FilterGames = memo(({ games = [] }) => {
  const classes = useStyles();
  const locale = useLocale();

  const {
    setFilters, filterValues,
  } = useListContext();

  const onToggleFilterGame = gameId => {
    if (filterValues.game?.id === gameId) {
      const tmpFilter = {
        ...filterValues,
      };
      delete tmpFilter.game;
      return setFilters(tmpFilter);
    }

    return setFilters({
      ...filterValues,
      game: {
        id: gameId,
      },
    });
  };

  return (
    <div>
      {games.map(game => {
        const isActive = filterValues?.game?.id === game.id;
        const isTranslatable = typeof game.name === 'object';
        return (
          <Button
            key={game?.id}
            color="primary"
            variant="text"
            size="small"
            className={isActive ? classes.buttonFilter : ''}
            onClick={() => onToggleFilterGame(game.id)}
          >
            {isTranslatable ? game.name?.[locale] : game.name}
          </Button>
        );
      })}
    </div>
  );
});

const CheatModeApplyList = props => {
  const { setTitle } = useTitleContext();

  const [includeFields, setIncludeFields] = useState([]);
  const [excludeFields, setExcludeFields] = useState([]);
  const dataProvider = useDataProvider();

  const translate = useTranslate();

  const pageTitle = translate('resources.cheat-mode-apply.name');
  const pageNote = translate('resources.cheat-mode-apply.text.note');

  const matches = useMediaQuery(theme => theme.breakpoints.down('md'));
  const user = useAuthUser();
  const isStreamer = [RoleBuiltIn.STREAMER_ADMIN, RoleBuiltIn.STREAMER].includes(user?.role?.id);
  const [games, setGames] = useState([]);
  const refGames = useRef(false);
  const getDataAvaiableGame = async () => {
    const groupId = user?.group?.id;
    const brandIds = user?.brands?.map(brand => brand.id);

    try {
      const filterBrandIds = brandIds?.length
        ? {
          'brands.id': brandIds,
        }
        : {};

      const { data } = await dataProvider.getList('available-game', {
        pagination: {
          page: 1,
        },
        filter: {
          ...filterBrandIds,
          'group.id': groupId,
        },
      });

      if (data?.length > 0) {
        // const gamesUniqon = unionBy(  // Since each item in data is a game object so we don't need the unionBY
        //   ...data.map(availableGame => availableGame.games),
        //   'id',
        // );

        setGames(data);
      }
    } catch (err) {
      console.error('[ERROR] Get One Game', err?.message);
    }
  };

  useEffect(() => {
    if (isStreamer && user?.id && !refGames.current) {
      getDataAvaiableGame();
      refGames.current = true;
    }
  }, [isStreamer, user?.id]);

  useEffect(() => {
    if (matches) {
      setIncludeFields(['game', 'status', 'cheatModeTemplate']);
      setExcludeFields(['id']);
    } else {
      setIncludeFields([]);
      setExcludeFields([]);
    }
  }, [matches]);

  useEffect(() => {
    const element = (
      <div>
        <div
          style={{
            fontSize: '20px',
            marginTop: '5px',
            bold: 'bold',
          }}
        >
          {pageTitle}
        </div>
        <div
          style={{
            fontSize: '18px',
            marginTop: '5px',
            color: 'red',
          }}
        >
          {pageNote}
        </div>
      </div>
    );
    setTitle(element);

    return function cleanUp() {
      setTitle(null);
    };
  }, [pageTitle, pageNote]);

  return (
    <WealthListGuesser
      {...props}
      extraActions={(
        <ActionMenu
          label="ra.field.action"
          // list   // this is giving true only.
        />
      )}
      includeFields={includeFields}
      excludeFields={excludeFields}
      additionFilters={<FilterGames games={games} />}
    />
  );
};

export default CheatModeApplyList;
