import React from 'react';
import CommonSubmitFormWrapper from '../../../base/components/customized/common-wrapper/CommonSubmitFormWrapper';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import { required, useNotify, useTranslate } from 'react-admin';
import axios from 'axios';
import useConfiguration from '../hooks/useConfiguration';

const requiredValidator = required();

const VerbetConfig = ({ resource }) => {
  const { data: configInfo } = useConfiguration();
  const notify = useNotify();
  const translate = useTranslate();

  const groupId = configInfo?.groupId;
  const configurationId = configInfo?.id;

  const handleSubmitGroup = async ({ groupId: newGroupId }) => {
    try {
      if (configurationId) {
        // Call API to update
        await axios.patch(`/api/${resource}/${configurationId}`, { groupId: newGroupId });
      } else {
        // Call API to create
        await axios.post(`/api/${resource}`, { groupId: newGroupId });
      }
      notify(translate('resources.reverse-integration.groupConfig.successfulMessage'), 'success');
    } catch (error) {
      notify(translate('resources.reverse-integration.groupConfig.failedMessage'), 'error');
    }
  };

  return (
    <CommonSubmitFormWrapper
      onSubmit={handleSubmitGroup}
      confirmDialogInfo={{
        title: 'resources.reverse-integration.groupConfig.name',
        content: 'resources.reverse-integration.groupConfig.confirmDialogContent',
      }}
      submitButtonProps={{ alwayDisabledSaveButton: false }}
    >
      <GroupSelector initialValue={groupId} fullWidth validate={requiredValidator} />
    </CommonSubmitFormWrapper>
  );
};

export default VerbetConfig;
