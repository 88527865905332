/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslate, FormDataConsumer, required, minValue, useLocale } from 'ra-core';
import { useDataProvider } from 'react-admin';
import { Box } from '@material-ui/core';
import { get, isEmpty, orderBy } from 'lodash';
import { useForm } from 'react-final-form';
import { useAuthUser, useApiProperties } from '../../../base/hooks';
import { ReferenceInput as ReferenceInputCustom, AutocompleteInput } from '../../../base/components/ra/inputs';
import BetSettingPicker from '../../../base/components/rmc-picker-custom/bet-setting-picker.input';
import { BetSettingPickerProvider } from '../../../base/components/rmc-picker-custom/bet-setting-picker.context';
import { useBetSettingFormContext } from '../utils/bet-setting-form.context';
import { checkSystemAdmin, checkTransatableField } from '../../../services/util';
import { validateTotalBetMax, fetchGamesBeingAvailable } from '../../../services/util/bet-setting.utils';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import useTranslateSchema from '../../../base/hooks/useTranslateSchema';
import { TWO_DECIMAL_REGEX } from '../../../services/util/validate/regularExpression';
import NumberInput from '../../../base/components/ra/inputs/commonNumber.input';

export const getLimitRanges = (betSizes = [], betLevels = [], baseBet) => {
  const betSizeArr = betSizes?.map((item) => (typeof item === 'number' ? item : item?.value));
  const betLevelArr = betLevels?.map((item) => (typeof item === 'number' ? item : item?.value));
  const minBetSize = Math.min.apply(null, betSizeArr);
  const minBetLevel = Math.min.apply(null, betLevelArr);

  const maxBetSize = Math.max.apply(null, betSizeArr);
  const maxBetLevel = Math.max.apply(null, betLevelArr);

  const minTotalBet = minBetSize * minBetLevel * baseBet;
  const maxTotalBet = maxBetSize * maxBetLevel * baseBet;

  return [+minTotalBet?.toFixed(2), +maxTotalBet?.toFixed(2)];
};

const BetSettingRefInput = (props) => (
  <ReferenceInputCustom fullWidth validate={required()} perPage={100} variant="outlined" {...props}>
    <AutocompleteInput optionText="name" />
  </ReferenceInputCustom>
);

const BetSettingCreateForm = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const user = useAuthUser();
  const form = useForm();
  const locale = useLocale();
  const formValues = form.getState().values;

  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGroup = getPropertiesByFieldName('group');
  const propertiesBrand = getPropertiesByFieldName('brand');
  const propertiesGame = getPropertiesByFieldName('game');

  const [game, setGame] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [listGameAvailable, setListGameAvailable] = useState(null);
  const [listCurrencySupport, setListCurrencySupport] = useState([]);

  const translateSchema = useTranslateSchema();
  const translateSchemaRef = useTranslateSchemaRef();

  const {
    // Default bet setting from API
    defaultSettingValue,
    // Current setting default
    currentSettingDefaultValue,
    setCurrentSettingDefaultValue,
    // Current setting
    currentSettingValue,
    setCurrentSettingValue,
    // State loading bet setting default
    betSettingDefaultLoaded,
    // Utils
    resetBetSettingDefault,
    getBetSettingDefault,
  } = useBetSettingFormContext();

  const baseBet = get(game, 'baseBet');
  const isSystemAdmin = checkSystemAdmin(user);

  const totalBetMaxMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMax',
    },
  });

  const totalBetMinMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMin',
    },
  });

  const getListGame = async () => {
    try {
      const data = await fetchGamesBeingAvailable(
        dataProvider,
        {
          groupId: formValues.group?.id,
          brandId: formValues.brand?.id,
        },
        {
          pagination: {
            page: 1,
            perPage: propertiesGame.choiceLimit || 25,
          },
          sort: {
            field: propertiesGame.choiceSort?.field || 'name',
            order: propertiesGame.choiceSort?.order || 'ASC',
          },
        },
      );

      if (Array.isArray(data)) {
        const field = propertiesGame.choiceSort?.field;
        const order = propertiesGame.choiceSort?.order;

        const { translatable } = propertiesGame;

        const tmpData = data.map((item) => {
          if (translatable || checkTransatableField(item, 'name', 'locale')) {
            item.name = get(item, ['name', locale]);
          }

          return item;
        });

        setListGameAvailable(orderBy(tmpData, [field || 'name'], [order || 'asc']));
      }
    } catch (err) {
      console.error('[ERROR] Get List Game Available', err?.message);
    }
  };

  const handleCurrencyChange = async (currencyId) => {
    try {
      const { data } = await dataProvider.getOne('currency', {
        id: currencyId,
      });

      if (!isEmpty(data)) {
        setCurrency(data);
      }
    } catch (err) {
      console.error('[ERROR] Get One Currency', err?.message);
    }
  };

  const handleGameChange = async (gameId) => {
    form.change('game.id', gameId);

    // Get current game selected
    try {
      const { data } = await dataProvider.getOne('game', {
        id: gameId,
      });

      if (!isEmpty(data)) {
        setGame(data);
      }
    } catch (err) {
      console.error('[ERROR] Get One Game', err?.message);
    }
  };

  const onlyTwoDigits = (fieldName) =>
    testByRegex(translateSchemaRef, {
      regex: TWO_DECIMAL_REGEX,
      translationSchema: {
        name: 'ra.validation.onlyTwoDigitsAfter',
        params: {
          smart_name: fieldName,
        },
      },
    });

  const getBrandFilter = (groupId) => {
    if (!groupId) {
      return undefined;
    }

    return {
      'group.id': groupId,
    };
  };

  const getCurrencySupportByBrand = async (brandId) => {
    // Get currency support by brand selected
    try {
      const { data } = await dataProvider.getOne('brand', {
        id: brandId,
      });

      if (Array.isArray(data?.currencies)) {
        setListCurrencySupport(data?.currencies);
      }
    } catch (err) {
      console.error('[ERROR] Get One Brand', err?.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(game) && !isEmpty(currency)) {
      getBetSettingDefault(game?.id, currency?.id);
    }
  }, [game, currency]);

  useEffect(() => {
    if (isEmpty(defaultSettingValue)) {
      return;
    }

    const settingObj = {
      betSize: [],
      betLevel: [],
    };

    const settingDefaultObj = {
      betSize: null,
      betLevel: null,
    };

    // Update betSize list & betSize default value
    defaultSettingValue.betSizes.forEach((item) => {
      settingObj.betSize.push({
        value: item.value,
      });
      if (item.default) {
        settingDefaultObj.betSize = item.value;
      }
    });

    // Update betLevel list & betLevel default value
    defaultSettingValue.betLevels.forEach((item) => {
      settingObj.betLevel.push({
        value: item.value,
      });
      if (item.default) {
        settingDefaultObj.betLevel = item.value;
      }
    });

    setCurrentSettingDefaultValue(settingDefaultObj);
    setCurrentSettingValue(settingObj);

    const [totalBetMin, totalBetMax] = getLimitRanges(settingObj.betSize, settingObj.betLevel, baseBet);

    form.change('totalBetMin', totalBetMin);
    form.change('totalBetMax', totalBetMax);
  }, [defaultSettingValue, baseBet]);

  useEffect(() => {
    if (isEmpty(defaultSettingValue)) {
      setCurrentSettingValue({});
      setCurrentSettingDefaultValue({});

      form.change('totalBetMin', '');
      form.change('totalBetMax', '');
    }
  }, [defaultSettingValue]);

  useEffect(() => {
    setListCurrencySupport([]);
  }, [formValues?.brand?.id]);

  useEffect(() => {
    if (formValues?.group?.id && formValues?.brand?.id) {
      getListGame();
    } else {
      setListGameAvailable(null);
    }
  }, [formValues?.group?.id, formValues?.brand?.id]);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Box>
          {isSystemAdmin && (
            <BetSettingRefInput
              label="resources.group.name"
              source="group.id"
              reference="group"
              perPage={propertiesGroup.choiceLimit || 25}
              sort={{
                field: propertiesGroup.choiceSort?.field || 'name',
                order: propertiesGroup.choiceSort?.order || 'ASC',
              }}
              afterOnChange={() => {
                // Choose brand again when group is changed
                form.change('brand.id', '');
              }}
            />
          )}

          <BetSettingRefInput
            label="resources.brand.name"
            source="brand.id"
            reference="brand"
            disabled={isSystemAdmin && !formData.group?.id}
            filter={getBrandFilter(formData.group?.id)}
            perPage={propertiesBrand.choiceLimit || 100}
            sort={{
              field: propertiesBrand.choiceSort?.field || 'name',
              order: propertiesBrand.choiceSort?.order || 'ASC',
            }}
            onChange={(value) => {
              getCurrencySupportByBrand(value);
            }}
          />

          <AutocompleteInput
            label={translate('resources.currency.name')}
            source="currency.id"
            disabled={!formData.brand?.id}
            choices={listCurrencySupport}
            onChange={handleCurrencyChange}
            validate={required()}
          />

          <AutocompleteInput
            label={translate('resources.game.name')}
            source="game.id"
            disabled={isEmpty(listGameAvailable)}
            choices={listGameAvailable || []}
            onChange={handleGameChange}
            validate={required()}
          />

          {!isEmpty(game) && !isEmpty(currency) && betSettingDefaultLoaded && (
            <>
              <NumberInput
                fullWidth
                source="totalBetMin"
                label={translate('ra.field.totalBetMin')}
                validate={[
                  required(),
                  minValue(0, totalBetMinMustGreatThanZero),
                  onlyTwoDigits('ra.field.totalBetMin'),
                ]}
                min={0}
              />

              <NumberInput
                fullWidth
                source="totalBetMax"
                label={translate('ra.field.totalBetMax')}
                validate={[
                  required(),
                  minValue(0, totalBetMaxMustGreatThanZero),
                  onlyTwoDigits('ra.field.totalBetMax'),
                  validateTotalBetMax(translateSchemaRef),
                ]}
                min={0}
              />

              <BetSettingPickerProvider>
                <BetSettingPicker
                  source="picker"
                  baseBetInit={baseBet}
                  listItemInit={currentSettingValue}
                  defaultValueInit={currentSettingDefaultValue}
                  onResetSetting={isEmpty(defaultSettingValue) ? null : resetBetSettingDefault}
                  defaultSettingValue={defaultSettingValue}
                />
              </BetSettingPickerProvider>
            </>
          )}
        </Box>
      )}
    </FormDataConsumer>
  );
};

export default BetSettingCreateForm;
