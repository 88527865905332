import { useRef, useEffect } from 'react';
import { useTranslate } from 'ra-core';

const useTranslateRef = () => {
  const translate = useTranslate();

  const translateRef = useRef({
    translate: f => f,
  });

  useEffect(() => {
    if (typeof translate === 'function') {
      translateRef.current.translate = translate;
    }
  }, [translate]);

  return translateRef.current;
};

export default useTranslateRef;
