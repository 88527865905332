/* eslint-disable */
import React from 'react';
import { useTranslate, FormDataConsumer, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { Box } from '@material-ui/core';
import { BooleanInput } from '../../../../base/components/ra/inputs';
import { InputContainer } from '../../../../base/components/layout/input-wrapper';
import { PLAYER_OPERATOR } from '../../../../constant';
import UploadDialog from './UploadDialog';
import PlayerAutocomplete from './PlayerAutocomplete';

export const EXCLUDE_SELECTED_PLAYER_KEY = 'excludeSelectedPlayer';

const PlayerInput = ({ error }) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const initialPlayers = get(record, 'players');

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const isDisabled = !formData.groupId || !formData.brandId || !formData.currencyId;
        const isExcluded = formData[EXCLUDE_SELECTED_PLAYER_KEY];
        return (
          <>
            <InputContainer width="100%">
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
                sx={{
                  gap: 8,
                }}
              >
                <PlayerAutocomplete
                  groupId={formData.groupId}
                  brandId={formData.brandId}
                  currencyId={formData.currencyId}
                  disabled={isDisabled}
                  value={formData.players}
                  label={
                    isExcluded
                      ? translate('resources.prepaid.fields.excludedPlayers')
                      : translate('resources.prepaid.fields.players', {
                          _: 'Players',
                        })
                  }
                  error={error}
                />

                <UploadDialog disabled={isDisabled} />
              </Box>

              <InputContainer>
                <BooleanInput
                  source={EXCLUDE_SELECTED_PLAYER_KEY}
                  label={translate('resources.prepaid.fields.excludeSelectedPlayers')}
                  initialValue={initialPlayers?.[0]?.operator === PLAYER_OPERATOR.EXCLUDE}
                />
              </InputContainer>
            </InputContainer>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default PlayerInput;
