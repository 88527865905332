export const REVERSE_INTEGRATION_OPERATORS = {
  VERTBET: {
    id: 'vertbet',
    name: 'Vertbet',
  },
};

export const CUSTOM_ROUTES = {
  QUICK_SETUP_NEW_GROUP: '/quick-setup-new-group',
  QUICK_SETUP_NEW_BRAND: '/quick-setup-new-brand',
  PROFILE: '/profile',
  REVERSE_INTEGRATION_PARTNER: operatorId => `/${operatorId}/partner`,
  REVERSE_INTEGRATION_GROUP_CONFIG: operatorId => `/${operatorId}/config`,
  REVERSE_WALLET_INTEGRATION: operatorId => `/${operatorId}/${operatorId}-wallet-integration`,
};

export const hasAccessPermissionByRoleId = (userRoleId, accessibleRoleIds) => {
  if (!accessibleRoleIds) {
    return true;
  }
  if (!userRoleId) {
    return false;
  }
  if (Array.isArray(accessibleRoleIds)) {
    return accessibleRoleIds.includes(userRoleId);
  }
  return accessibleRoleIds === userRoleId;
};
