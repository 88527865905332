/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslate } from 'react-admin';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import { sanitizeObject } from '../../services/util';
import PlayUrlInput from './components/playUrl.input';
import PrepaidEnabled from './components/prepaidEnabled.input';
import { BooleanInput } from '../../base/components/ra/inputs';

function GameEdit(props) {
  const translate = useTranslate();
  return (
    <WealthEditGuesser
      {...props}
      excludeFields={['enabled', 'isAvailable', 'playUrl', 'prepaidEnabled', 'isHotGame', 'isNewGame']}
      transform={sanitizeObject}
      fieldOrder={['name', 'gameId', 'gameType', 'playUrl', 'gameLogicUrl', 'desc', 'preview', 'isNewGame', 'isHotGame', 'prepaidEnabled']}
    >
      <PlayUrlInput source="playUrl" />
      <BooleanInput
        label={translate('resources.game.fields.isNewGame')}
        source="isNewGame"
      />
      <BooleanInput
        label={translate('resources.game.fields.isHotGame')}
        source="isHotGame"
      />
      <PrepaidEnabled source="prepaidEnabled" />
    </WealthEditGuesser>
  );
}

export default GameEdit;
