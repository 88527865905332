import { announcementReducer } from './announcement/announcement.reducer';
import { appReducer } from './app/app.reducer';
import { authReducer } from './auth/auth.reducer';
import { filterReducer } from './filter/filter.reducer';
import { schemaReducer } from './schema/schema.reducer';
import { settingReducer } from './setting/setting.reducer';

const rootReducer = {
  setting: settingReducer,
  schema: schemaReducer,
  auth: authReducer,
  announcement: announcementReducer,
  filter: filterReducer,
  app: appReducer,
};

export default rootReducer;
