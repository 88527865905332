import { Box, IconButton, MenuItem, Select, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useListContext, useListPaginationContext, useTranslate } from 'react-admin';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    padding: 0,
    fontSize: 14,

  },

});

const InfinitePagination = ({ rowsPerPage = [25, 50, 100] }) => {
  const translate = useTranslate();
  const {
    perPage = rowsPerPage[0], setPerPage, setPage, page = 1,
  } = useListPaginationContext();
  const classes = useStyles();
  const { ids } = useListContext();

  const isEmpty = !ids?.length;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        gap: 18,
      }}
      minHeight={52}
    >
      <Box
        display="flex"
        sx={{
          gap: 10,
        }}
      >
        <Box>{translate('ra.navigation.page_rows_per_page')}</Box>
        <Select
          value={perPage}
          onChange={e => setPerPage(e.target.value)}
          disableUnderline
          classes={{
            select: classes.root,
          }}
        >
          {rowsPerPage.map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex">
        <IconButton disabled={page === 1}>
          <NavigateBefore onClick={() => setPage(page - 1)} />
        </IconButton>
        <IconButton disabled={isEmpty || ids?.length < perPage}>
          <NavigateNext onClick={() => setPage(page + 1)} />
        </IconButton>
      </Box>
    </Box>
  );
};

InfinitePagination.propTypes = {
  rowsPerPage: PropTypes.arrayOf(PropTypes.number),
};

InfinitePagination.defaultProps = {
  rowsPerPage: [25, 50, 100],
};

export default InfinitePagination;
