import React from 'react';
import PropTypes from 'prop-types';
import { Loading, required, useTranslate } from 'react-admin';
import WealthCreate from '../../../base/components/guesser/wealth-create';
import StringInput from '../../../base/components/customized/common-input/StringInput';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import useConfiguration from '../hooks/useConfiguration';
import BrandInput from './components/brand-input';

const requiredValidator = required();

const PartnerCreate = ({
  resource, ...otherProps
}) => {
  const {
    data: configInfo, isLoading,
  } = useConfiguration();
  const translate = useTranslate();

  if (isLoading) return <Loading />;

  const groupId = configInfo?.groupId;

  return (
    <WealthCreate
      resource={resource}
      writableFields={[
        'name',
        'partnerId',
        'brandId',
        'groupId',
        'placeBetUrl',
        'payOutUrl',
        'authorizeUrl',
        'getBalanceUrl',
        'cancelBetUrl',
      ]}
      confirmDialogInfo={{
        content: 'resources.reverse-integration.partner.confirmToCreateContent',
      }}
      translatedResourceName={translate('resources.reverse-integration.partner.name')}
      {...otherProps}
    >
      <StringInput
        source="name"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <StringInput
        source="partnerId"
        validate={requiredValidator}
        fullWidth
        helperText
        label="resources.reverse-integration.partner.fields.partnerId"
      />
      <GroupSelector
        disabled
        initialValue={groupId}
        validate={requiredValidator}
        fullWidth
        inputProps={{
          helperText: true,
        }}
      />
      <BrandInput
        groupId={groupId}
        validate={requiredValidator}
      />
      <UrlInput
        source="authorizeUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="placeBetUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="payOutUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="getBalanceUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="cancelBetUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
    </WealthCreate>
  );
};

export default PartnerCreate;

PartnerCreate.propTypes = {
  resource: PropTypes.string.isRequired,
  options: PropTypes.shape({
    operatorId: PropTypes.string.isRequired,
  }).isRequired,
};
