/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { required, minValue, useTranslate, useLocale, usePermissions } from 'react-admin';
import { Grid, Box, Fade, CircularProgress } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { NumberInput, TextInput, ReferenceInput, AutocompleteInput } from '../../../base/components/ra/inputs';
import { ONLY_INTEGER } from '../../../services/util/validate/regularExpression';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import { InputGridWrapper, InputContainer } from '../../../base/components/layout/input-wrapper';
import useGettingAvailableGame from '../hooks/useGettingAvailableGame';
import useGettingSpinTypes from '../hooks/useGettingSpinTypes';
import useBaseBetCalculating from '../hooks/useBaseBetCalculating';
import useBetSettingHandler from '../hooks/useBetSettingHandler';
import resourceSlug from '../../../constant/resource-slug';
import IssuerSelector from './IssuerSelector';
import SpinTypeSelector from './SpinTypeSelector';
import ExpiresInput from './ExpiresInput';
import PlayerInput from './PlayerInput';
import { useAuthUser } from '../../../base/hooks';

const PrepaidEditForm = ({ record, error }) => {
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasIssuerEditPermission =
    user?.superman ||
    (Array.isArray(permissions) &&
      !!permissions?.find((item) => item.resource.name === resourceSlug.PREPAID_ISSUER)?.update);

  const hasIssuerReadPermission =
    user?.superman ||
    (Array.isArray(permissions) &&
      !!permissions?.find((item) => item.resource.name === resourceSlug.PREPAID_ISSUER)?.read);

  const form = useForm();
  const locale = useLocale();
  const { values: formValues } = useFormState();
  const translate = useTranslate();
  const translateSchemaRef = useTranslateSchemaRef();

  const { availableGame } = useGettingAvailableGame({
    groupId: formValues.groupId,
    brandId: formValues.brandId,
  });

  const { listSpinTypes } = useGettingSpinTypes({
    gameId: formValues.gameId,
  });

  const { betAmountCalculating, betAmount } = useBaseBetCalculating({
    betSize: formValues.betSize,
    betLevel: formValues.betLevel,
    gameId: formValues.gameId,
  });

  const { betSizeChoices, betLevelChoices, getBetSettingDefault, setBetSettingDefault, getBetSetting, setBetSetting } =
    useBetSettingHandler({});

  const betSettingDefaultInitValue = {
    betSizes: [],
    betLevels: [],
  };
  const betSettingInitValue = {
    betSizes: [],
    betLevels: [],
  };

  const [currenciesSupport, setCurrenciesSupport] = useState([]);

  const paramsGetBetSetting = [formValues.groupId, formValues.brandId, formValues.gameId, formValues.currencyId];

  const paramsGetBetSettingDefault = [formValues.gameId, formValues.currencyId];

  const countValueMustGreatThanOneMessage = translate('ra.validation.mustGreatThan', {
    field_a: translate('resources.prepaid.fields.count'),
    field_b: '0',
  });

  const resources = useSelector(({ admin }) => admin.resources);
  const brandDataFetched = get(resources, 'brand.data');

  const handleGameSelect = () => {
    form.change('betSize', undefined);
    form.change('betLevel', undefined);
    form.change('spinTypeId', undefined);
    setBetSetting(betSettingInitValue);
    setBetSettingDefault(betSettingDefaultInitValue);
  };

  const handleCurrencySelect = () => {
    form.change('betSize', undefined);
    form.change('betLevel', undefined);
    form.change('players', []);
    setBetSetting(betSettingInitValue);
    setBetSettingDefault(betSettingDefaultInitValue);
  };

  const handleBetSizeSelect = () => {};

  const handleBetLevelSelect = () => {};

  const onlyInteger = (fieldName) =>
    testByRegex(translateSchemaRef, {
      regex: ONLY_INTEGER,
      translationSchema: {
        name: 'ra.validation.inputMustBeInteger',
        params: {
          smart_name: fieldName,
        },
      },
    });

  const renderGameInput = () => {
    const isTranslatable = availableGame.some((item) => typeof item.name === 'object');
    return (
      <AutocompleteInput
        label="resources.prepaid.fields.game"
        source="gameId"
        disabled={!formValues.groupId || !formValues.brandId}
        choices={availableGame.filter((game) => game.prepaidEnabled || game.id === get(record, 'game.id'))}
        optionText={isTranslatable ? `name.${locale}` : 'name'}
        optionValue="id"
        onChange={handleGameSelect}
        validate={required()}
      />
    );
  };

  useEffect(() => {
    const brandSelected = get(brandDataFetched, formValues.brandId);
    if (!formValues.brandId || !brandSelected) {
      return;
    }

    const currencies = get(brandSelected, 'currencies', []);
    if (currencies.length > 0) {
      setCurrenciesSupport(currencies);
    }
  }, [formValues.brandId, brandDataFetched]);

  useEffect(() => {
    if (paramsGetBetSetting.every((item) => item)) {
      getBetSetting(...paramsGetBetSetting);
    } else {
      setBetSetting(betSettingInitValue);
    }
  }, [...paramsGetBetSetting]);

  useEffect(() => {
    if (paramsGetBetSettingDefault.every((item) => item)) {
      getBetSettingDefault(...paramsGetBetSettingDefault);
    } else {
      setBetSettingDefault(betSettingDefaultInitValue);
    }
  }, [...paramsGetBetSettingDefault]);

  useEffect(() => {
    form.change('betAmount', betAmount);
  }, [betAmount]);

  useEffect(() => {
    if (!formValues.betAmount) {
      form.change('spinTypeId', undefined);
    }
  }, [formValues.betAmount]);

  useEffect(() => {
    if (isEmpty(record)) {
      return;
    }

    const betSize = get(record, 'reward.data.betSetting.betSize');
    const betLevel = get(record, 'reward.data.betSetting.betLevel');
    const prepaidDataInit = {
      groupId: get(record, 'group.id'),
      brandId: get(record, 'brand.id'),
      gameId: get(record, 'game.id'),
      currencyId: get(record, 'currency.id'),
      betSize: String(betSize),
      betLevel: String(betLevel),
      count: get(record, 'reward.count'),
      claimBefore: get(record, 'reward.claimBefore'),
      name: get(record, 'name'),
      desc: get(record, 'desc'),
      inboxRuleMgsTemplate: get(record, 'inboxRule.message.data'),
      players: get(record, 'players') || [],
      spinTypeId: get(record, 'reward.data.gameMode.id'),
      issuer: get(record, 'issuer'),
    };

    form.initialize({
      ...prepaidDataInit,
    });
  }, [record]);

  return (
    <>
      <Grid container>
        <InputGridWrapper>
          <ReferenceInput
            fullWidth
            label="resources.prepaid.fields.group"
            reference="group"
            source="groupId"
            validate={required()}
            afterOnChange={() => {
              form.change('brandId', undefined);
              form.change('gameId', undefined);
              form.change('currencyId', undefined);
              form.change('betSize', undefined);
              form.change('betLevel', undefined);
              form.change('players', []);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </InputGridWrapper>

        <InputGridWrapper>
          <ReferenceInput
            fullWidth
            label="resources.prepaid.fields.brand"
            reference="brand"
            source="brandId"
            filter={{
              'group.id': formValues.groupId,
            }}
            validate={required()}
            disabled={!formValues.groupId}
            afterOnChange={() => {
              form.change('gameId', undefined);
              form.change('currencyId', undefined);
              form.change('betSize', undefined);
              form.change('betLevel', undefined);
              form.change('players', []);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </InputGridWrapper>

        <InputGridWrapper>{renderGameInput()}</InputGridWrapper>

        <InputGridWrapper>
          <AutocompleteInput
            label="resources.prepaid.fields.currency"
            source="currencyId"
            disabled={!formValues.brandId}
            choices={currenciesSupport}
            optionText="name"
            optionValue="id"
            onChange={handleCurrencySelect}
            validate={required()}
            afterOnChange={() => {
              form.change('players', []);
            }}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <NumberInput
            fullWidth
            label="ra.field.count"
            source="count"
            min={1}
            step={1}
            validate={[
              required(),
              minValue(1, countValueMustGreatThanOneMessage),
              onlyInteger(
                translate('resources.prepaid.fields.count', {
                  _: 'Count',
                }),
              ),
            ]}
          />
        </InputGridWrapper>

        <ExpiresInput />

        <InputGridWrapper>
          <AutocompleteInput
            label="ra.field.betSize"
            source="betSize"
            disabled={!formValues.gameId || !formValues.currencyId}
            choices={betSizeChoices}
            optionText="value"
            optionValue="value"
            onChange={handleBetSizeSelect}
            validate={required()}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <AutocompleteInput
            label="ra.field.betLevel"
            source="betLevel"
            disabled={!formValues.gameId || !formValues.currencyId}
            choices={betLevelChoices}
            optionText="value"
            optionValue="value"
            onChange={handleBetLevelSelect}
            validate={required()}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <Box
            className="pointer-event-none"
            style={{
              position: 'relative',
            }}
          >
            <NumberInput
              disabled
              fullWidth
              label={translate('resources.prepaid.fields.betAmount', {
                _: 'Bet Amount',
              })}
              source="betAmount"
              min={1}
              step={1}
            />
            <Fade
              in={betAmountCalculating}
              style={{
                transitionDelay: betAmountCalculating ? '800ms' : '0ms',
                position: 'absolute',
                top: '5px',
                right: '10px',
                color: '#c8c8c8',
                margin: '8px 0px 4px',
              }}
              unmountOnExit
            >
              <CircularProgress
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </Fade>
          </Box>
        </InputGridWrapper>

        <InputContainer width="100%">
          <SpinTypeSelector data={listSpinTypes} />
        </InputContainer>

        {hasIssuerReadPermission && (
          <InputContainer width="100%">
            <IssuerSelector validate={required()} disabled={hasIssuerEditPermission} />
          </InputContainer>
        )}

        {!formValues.groupId || !formValues.brandId || !formValues.currencyId ? null : <PlayerInput error={error} />}

        <InputContainer width="100%">
          <TextInput fullWidth label="resources.prepaid.fields.name" source="name" validate={required()} />
        </InputContainer>

        <InputContainer width="100%">
          <TextInput fullWidth multiline label="resources.prepaid.fields.desc" source="desc" rows={5} />
        </InputContainer>

        <InputContainer width="100%">
          <TextInput
            fullWidth
            multiline
            label="resources.inbox-rule.fields.message"
            source="inboxRuleMgsTemplate"
            rows={5}
            validate={required()}
          />
        </InputContainer>
      </Grid>
    </>
  );
};

PrepaidEditForm.propTypes = {
  record: PropTypes.object,
};

PrepaidEditForm.defaultProps = {
  record: null,
};

export default PrepaidEditForm;
