import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Box, Collapse, IconButton } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useRedirect, useNotify } from "react-admin";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Field, Form } from "react-final-form";
import ReactHelmet from "react-helmet";
import ContentLoader from "../../ContentLoader";
import CmtImage from "../../../../@coremat/CmtImage";
import { sanitizeMultipleWhitespace } from "../../../../../services/util/stringUtil";
import { checkMboEnv, getBackofficeEnv } from "../../../../../services/util";
import { REGEX_PASSWORD } from "../../../../../services/util/validate/regularExpression";
import AuthWrapper from "./AuthWrapper";
import { APP_ROUTE } from "../../../../../constant";
import { PasswordInput } from "../../../../../base/components/ra/inputs";
import useSite from "../../../../../base/hooks/useSite";
import { getRawTheme } from '../../../../../custom-theme/themeConfig';
import { appBrandConfigMapping } from "../../../../../constant/appBrandMapping";

const REQUIRED_FIELD_MESSAGE = "This field is required";
const PASSWORD_MATCHED_MESSAGE = "Confirm password must match new password";
const INVALID_MESSAGE = (fieldName) =>
  sanitizeMultipleWhitespace(`
  ${fieldName} has minimum eight characters, 
  at least one uppercase letter, 
  one lowercase letter, 
  one number and one special character
`);

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    fontFamily: theme.typography.fontFamily,
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  titleRoot: {
    textAlign: 'center',
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

const ResetPassword = ({ variant = "default", wrapperVariant = "default" }) => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [counter, setCounter] = useState(10);
  const [tokenValid, setTokenValid] = useState(true);
  const classes = useStyles({
    variant,
  });
  const redirect = useRedirect();
  const notify = useNotify();
  const { token } = useParams();
  const { title, favicon } = useSite();

  const isMboEnv = checkMboEnv();
  const { APP_BRAND } = getBackofficeEnv();
  const { resetPasswordPageLogo } = appBrandConfigMapping[APP_BRAND];

  // Validate token
  const validateToken = async (token) => {
    try {
      await axios.post("api/auth/password/verify-token", {
        passwordToken: token,
      });
      setTokenValid(true);
    } catch (error) {
      if (!error?.response) {
        setTokenValid(false);
      }
      setTokenValid(false);
    }
  };
  useEffect(() => {
    if (!token) {
      notify("error");
    }
    validateToken(token);
  }, [token]);

  useEffect(() => {
    if (successOpen) {
      setCounter(counter - 1);
    }
  }, [successOpen]);

  useEffect(() => {
    if (counter === 0) {
      redirect(APP_ROUTE.LOGIN_ROUTE);
    }
    const timer =
      successOpen &&
      counter > 0 &&
      setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, successOpen]);

  const onSubmit = async ({ newPassword, confirmPassword }) => {
    try {
      await axios.post("api/auth/password/reset", {
        newPassword,
        confirmPassword,
        passwordToken: token,
      });
      setSuccessOpen(true);
    } catch (error) {
      setErrorResponse(error);
    }
  };

  const handleValidation = (values) => {
    const { newPassword, confirmPassword } = values;

    // Validate `newPassword`
    if (!newPassword) {
      return {
        newPassword: REQUIRED_FIELD_MESSAGE,
      };
    }

    const validPassword = REGEX_PASSWORD.test(newPassword);
    if (!validPassword) {
      return {
        newPassword: INVALID_MESSAGE("Password"),
      };
    }

    // Validate `confirmPassword`
    if (!confirmPassword) {
      return {
        confirmPassword: REQUIRED_FIELD_MESSAGE,
      };
    }

    if (confirmPassword !== newPassword) {
      return {
        confirmPassword: PASSWORD_MATCHED_MESSAGE,
      };
    }

    return {};
  };

  return (
    <>
      <ReactHelmet>
        <title>{title}</title>
        <link
          rel="icon"
          id="site-favicon"
          href={favicon.default}
          onError={`this.onerror=null;this.href='${favicon.default}'`}
        />
      </ReactHelmet>

      <AuthWrapper variant={wrapperVariant}>
        {variant === "default" ? (
          <Box className={classes.authThumb}>
            <CmtImage src="/images/auth/forgot-img.png" />
          </Box>
        ) : null}
        <Box className={classes.authContent}>
          <Box mb={2}>
            <CmtImage
              src={resetPasswordPageLogo}
              style={{ width: isMboEnv ? 60 : 100, display: 'block', margin: '0 auto' }}
            />
          </Box>
          <Typography component="div" variant="h4" className={classes.titleRoot}>
            Reset Password
          </Typography>
          <Collapse in={Boolean(!successOpen && tokenValid && !errorResponse)}>
            <Form
              onSubmit={onSubmit}
              validate={handleValidation}
              render={({ handleSubmit, saving, pristine, valid }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="newPassword" component={TextField}>
                    {({ input, meta }) => (
                      <PasswordInput
                        {...input}
                        label="New password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required={true}
                        className={classes.textFieldRoot}
                        helperText={meta.error && meta.touched && meta.error}
                        error={meta.error && meta.touched}
                      />
                    )}
                  </Field>
                  <Field name="confirmPassword" component={TextField}>
                    {({ input, meta }) => (
                      <PasswordInput
                        {...input}
                        label="Confirm password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required={true}
                        className={classes.textFieldRoot}
                        helperText={meta.error && meta.touched && meta.error}
                        error={meta.error && meta.touched}
                      />
                    )}
                  </Field>
                  <Box mt={2.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      className={classes.btnLogin}
                      style={{
                        background: pristine || saving || !valid ? undefined : getRawTheme().palette.primary.main,
                      }}
                      disabled={pristine || saving || !valid}
                    >
                      Save
                    </Button>
                  </Box>
                </form>
              )}
            />
          </Collapse>
          <Box mt={2}>
            {/* Error Response alert*/}
            <Collapse in={Boolean(errorResponse)}>
              <Alert
                variant="outlined"
                severity="error"
                className={classes.alertRoot}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorResponse("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography>{errorResponse.toString()}</Typography>
              </Alert>
            </Collapse>
            {/* Invalid token alert */}
            <Collapse in={!tokenValid}>
              <Alert
                variant="outlined"
                severity="error"
                className={classes.alertRoot}
              >
                <Typography>{`Sorry, this reset link is invalid or expired, please contact your admin for help.`}</Typography>
              </Alert>
            </Collapse>
            {/* Success alert */}
            <Collapse in={successOpen}>
              <Alert
                variant="outlined"
                severity="success"
                className={classes.alertRoot}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setCounter(0);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography>
                  {`Password has been reset, redirect to login page in ${counter}`}
                </Typography>
              </Alert>
            </Collapse>
          </Box>
          <ContentLoader />
        </Box>
      </AuthWrapper>
    </>
  );
};

export default ResetPassword;
