import React from 'react';
import { regex } from 'ra-core';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslate } from 'react-admin';
import WealthInputWrapper from '../../../base/components/guesser/wealth-input-wrapper';
import { REGEX_URL } from '../../../services/util/validate/regularExpression';
import { TextInput } from '../../../base/components/ra/inputs';
import { isPreStagingEnv } from '../../../services/util';

const useStyles = makeStyles(() => ({
  infoButton: {
    padding: '0 0 0 8px',
    marginTop: -4,
    '&:hover': {
      background: 'transparent',
    },
  },
  label: {
    pointerEvents: 'auto',
    cursor: 'text',
  },
}));

const validateWithoutRequired = [regex(REGEX_URL, 'wa.exception.invalidUrl')];

const GetPlayerTokenUrlInput = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const isInPreStaging = isPreStagingEnv();

  if (!isInPreStaging) return null;

  return (
    <WealthInputWrapper
      source="getPlayerTokenUrl"
      label={(
        <Box
          display="flex"
        >
          <Box component="span">{translate('resources.brand.fields.getPlayerTokenUrl')}</Box>
          <Tooltip
            placement="top-end"
            title={translate('resources.brand.fields.getPlayerTokenUrlTooltip')}
          >
            <IconButton
              className={classes.infoButton}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      InputLabelProps={{
        className: classes.label,
      }}
      validate={validateWithoutRequired}
    >
      <TextInput />
    </WealthInputWrapper>
  );
};

export default GetPlayerTokenUrlInput;
