/* eslint-disable */
import React from 'react';
import {  makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  outlinedDiv: {
    '&:hover [class*="notchedOutline"], & [class*="focused"] [class*="notchedOutline"]': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: 1,
    },
  },
  label: {
    pointerEvents: 'auto',
    cursor: 'text',
  },
}));


const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      className={classes.outlinedDiv}
      InputLabelProps={{
        shrink: true,
        className: classes.label,
      }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{
        children,
      }}
    />
  );
};

export default OutlinedDiv;
