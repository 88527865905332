/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import classnames from "classnames";
import { useListContext, useNotify, useTranslate } from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import { grey } from "@material-ui/core/colors";
import Axios from "axios";
import { Form } from "react-final-form";
import { sanitizeButtonRestProps } from "../../../../base/components/ra/buttons/Button";
import { guessProperty } from "../../../../base/components/guesser/wealth-guesser";
import { useError, useSchema } from "../../../../base/hooks";
import RecallListController from "./retrigger";

const useStyles = makeStyles(() => ({
  viewButton: {
    color: "#0080FF",
    "&:hover": {
      backgroundColor: fade("#0080FF", 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  btn: {
    marginLeft: 8,
    fontWeight: "medium",
  },
  contentText: {
    color: grey[600],
  },
}));

const defaultIcon = <EditIcon />;

function EditPlayerBalanceForm(props) {
  const pathRequest = "/player/{id}/edit-balance";
  const {
    record,
    propsController,
    icon = defaultIcon,
    className,
    ...rest
  } = props;

  const [showForm, setShowEditBalanceForm] = useState(false);

  const translate = useTranslate();

  const [openRecall, setOpenRecall] = useState(false);

  const classes = useStyles();

  const [guessedInfo, setGuessedInfo] = useState({});
  const { notifyError } = useError();
  const notify = useNotify();

  const { ids: listContextIds, data: listContextData } = useListContext();

  const { api, ref } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;

      let requestRef =
        paths?.[pathRequest]?.post?.requestBody?.content?.["application/json"]
          ?.schema;
      requestRef = requestRef?.$ref;

      const requestComponent = ref.get(requestRef);

      const { properties, required } = requestComponent;

      const writableFields = Object.keys(properties);

      const components = writableFields?.map((i) =>
        guessProperty({
          source: i,
          properties,
          apiRef: ref,
          view: "edit",
          resource: "user",
          resources: [],
          required,
        })
      );

      setGuessedInfo({
        components,
      });
    }
  }, [api]);

  const handleSubmitForm = async (values) => {
    let success;

    try {
      const dataRes = await Axios.post(
        `api/player/${record.id}/edit-balance`,
        values
      );

      const data = dataRes?.data;

      if (data) {
        notify(
          translate("ra.message.successfully", {
            smart_name: translate("ra.text.editBalance"),
          }),
          "success"
        );

        setOpenRecall(true);

        setTimeout(() => {
          setShowEditBalanceForm(false);
        }, 700);

        setTimeout(() => {
          setShowEditBalanceForm(false);
          setOpenRecall(false);
        }, 2500);
      }

      success = true;
    } catch (e) {
      notifyError(e);
    }

    return success;
  };

  return (
    <>
      <Tooltip title={translate("ra.text.editBalance")}>
        <IconButton
          onClick={(e) => {
            setShowEditBalanceForm(true);
            e.stopPropagation();
          }}
          className={classnames(className, classes.viewButton)}
          {...sanitizeButtonRestProps(rest)}
        >
          {icon}
        </IconButton>
      </Tooltip>

      <Dialog
        open={showForm}
        fullWidth
        onClose={() => setShowEditBalanceForm(false)}
        aria-labelledby="form-dialog-title"
      >
        <Form
          initialValues={{
            balance: record?.wallet?.balance || 0,
          }}
          onSubmit={async (values, form) => {
            const success = await handleSubmitForm(values);
            if (success) {
              setTimeout(() => {
                form.reset(values);
              }, 100);
            }
          }}
          render={({ handleSubmit, valid, saving, pristine }) => (
            <form
              className={classes.root}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <DialogTitle id="form-dialog-title">
                {translate("ra.text.editBalanceTitle", {
                  smart_text: `#${record?.nativeId}`,
                })}
              </DialogTitle>
              <DialogContent>{guessedInfo?.components}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowEditBalanceForm()}
                  color="primary"
                >
                  {translate("ra.action.cancel")}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  className={classes.btnSubmit}
                  margin="normal"
                  disabled={pristine || saving || !valid}
                >
                  {translate("ra.action.confirm")}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
      {openRecall && (
        <RecallListController
          propsController={propsController}
          setOpenRecall={setOpenRecall}
          listContextIds={listContextIds}
          listContextData={listContextData}
          open={openRecall}
        />
      )}
    </>
  );
}

export default EditPlayerBalanceForm;
