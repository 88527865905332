import { RoleBuiltIn } from '../services/util';
import { REVERSE_INTEGRATION_OPERATORS } from './customRoutes';

export const CUSTOM_RESOURCES = {
  VERTBET_PARTNER: {
    name: `${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/partner`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      operatorId: REVERSE_INTEGRATION_OPERATORS.VERTBET.id,
      isAcl: true,
    },
  },
  VERTBET_CONFIG: {
    name: `${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/config`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      operatorId: REVERSE_INTEGRATION_OPERATORS.VERTBET.id,
    },
  },
  VERTBET_WALLET_INTEGRATION: {
    name: `${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}-wallet-integration`,
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
    options: {
      isAcl: true,
    },
  },
  QUICK_SETUP: {
    name: 'quick-setup',
    accessibleRoleIds: [RoleBuiltIn.SYSTEM_ADMIN],
  },
};
