import React from 'react';
import { Loading, ReferenceField, TextField, UrlField, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import StringFilter from '../../../base/components/customized/common-input/StringInput';
import TimeRangeFilter from '../../../base/components/customized/common-input/TimeRangeInput';
import FilterButton from '../../../base/components/customized/common-list-action-buttons/FilterButton';
import CustomizedFilterWrapper from '../../../base/components/customized/CustomizedFilterWrapper';
import CustomizedList from '../../../base/components/customized/CustomizedList';
import CustomizedListActionWrapper from '../../../base/components/customized/CustomizedListActionWrapper';
import { DateField, IdField } from '../../../base/components/ra/fields';
import BrandFilter from '../components/brand-input';
import useConfiguration from '../hooks/useConfiguration';

const ReverseWalletIntegrationList = props => {
  const {
    data: configInfo, isLoading,
  } = useConfiguration();
  const translate = useTranslate();

  if (isLoading) return <Loading />;

  const groupId = configInfo?.groupId;

  return (
    <CustomizedList
      {...props}
      actions={<ReversedWalletListAction />}
      filters={<ReversedWalletFilterWrapper groupId={groupId} />}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      emptyProps={{
        emptyMessage: translate('resources.reverse-integration.reverseWalletIntegration.empty'),
        readOnly: true,
      }}
    >
      <IdField hasShow />
      <DateField
        source="createdAt"
        label="common.fields.created"
      />
      <DateField
        source="updatedAt"
        label="common.fields.updated"
      />
      <ReferenceField
        source="groupId"
        reference="group"
        link="show"
        label="resources.group.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        reference="brand"
        link="show"
        label="resources.brand.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <UrlField
        source="authorizeUrl"
        target="_blank"
        label="common.fields.authorizeUrl"
      />
      <UrlField
        source="placeBetUrl"
        target="_blank"
        label="common.fields.placeBetUrl"
      />
      <UrlField
        source="payOutUrl"
        target="_blank"
        label="common.fields.payOutUrl"
      />
      <UrlField
        source="cancelBetUrl"
        target="_blank"
        label="common.fields.cancelBetUrl"
      />
      <UrlField
        source="getBalanceUrl"
        target="_blank"
        label="common.fields.getBalanceUrl"
      />
    </CustomizedList>
  );
};

const ReversedWalletListAction = () => (
  <CustomizedListActionWrapper>
    <FilterButton />
  </CustomizedListActionWrapper>
);

const ReversedWalletFilterWrapper = ({ groupId }) => (
  <CustomizedFilterWrapper>
    <TimeRangeFilter
      resource="createdAt"
      source="createdAt"
      label="common.fields.created"
    />
    <TimeRangeFilter
      resource="updatedAt"
      source="updatedAt"
      label="common.fields.updated"
    />
    <BrandFilter
      filter={{
        'group.id': groupId,
      }}
    />
    <StringFilter
      source="name||$cont"
      label="common.fields.name"
    />
    <StringFilter
      source="authorizeUrl||$cont"
      label="common.fields.authorizeUrl"
    />
    <StringFilter
      source="getBalanceUrl||$cont"
      label="common.fields.getBalanceUrl"
    />
    <StringFilter
      source="payOutUrl||$cont"
      label="common.fields.payOutUrl"
    />
    <StringFilter
      source="placeBetUrl||$cont"
      label="common.fields.placeBetUrl"
    />
    <StringFilter
      source="cancelBetUrl||$cont"
      label="common.fields.cancelBetUrl"
    />
  </CustomizedFilterWrapper>
);

ReversedWalletFilterWrapper.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default ReverseWalletIntegrationList;
