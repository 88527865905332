/* eslint-disable react/prop-types */
import React, { cloneElement, useState, useRef, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { Box, useTheme, fade } from '@material-ui/core';
import inflection from 'inflection';

const FIELD_MAX_HEIGHT = 300;
const ELLIPSIS_STATE = {
  DETECTING: '#detecting',
  WILL_SHOW_MORE: '#will-show-more',
  WILL_SHOW_LESS: '#will-show-less',
};

const FieldWrapper = ({ children }) => {
  const [ellipsis, setEllipsis] = useState(ELLIPSIS_STATE.DETECTING);
  const fieldRef = useRef();
  const muiTheme = useTheme();
  const translate = useTranslate();

  const isWillShowMore = ellipsis === ELLIPSIS_STATE.WILL_SHOW_MORE;
  const ellipsisStyle = isWillShowMore
    ? {
      maxHeight: `${FIELD_MAX_HEIGHT}px`,
      overflow: 'hidden',
    }
    : {
      maxHeight: 'unset',
      height: 'auto',
    };

  const willShowMoreStyle = {
    position: 'absolute',
    marginTop: 'inherit',
    bottom: '6px',
    left: '6px',
    width: 'calc(100% - 12px)',
  };

  useEffect(() => {
    const fieldHeight = fieldRef.current?.offsetHeight || 0;

    if (
      ellipsis === ELLIPSIS_STATE.DETECTING
      && fieldHeight > FIELD_MAX_HEIGHT
    ) {
      setEllipsis(ELLIPSIS_STATE.WILL_SHOW_MORE);
    }
  });

  return (
    <Box
      style={{
        ...ellipsisStyle,
      }}
    >
      <Box ref={fieldRef}>{cloneElement(children)}</Box>
      {[ELLIPSIS_STATE.WILL_SHOW_MORE, ELLIPSIS_STATE.WILL_SHOW_LESS].includes(
        ellipsis,
      ) && (
        <Box
          style={{
            position: 'relative',
            marginTop: '10px',
            bottom: 0,
            left: 0,
            width: '100%',
            ...(isWillShowMore ? willShowMoreStyle : {}),
            // General style
            borderRadius: '5px',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (isWillShowMore) {
              setEllipsis(ELLIPSIS_STATE.WILL_SHOW_LESS);
            } else {
              setEllipsis(ELLIPSIS_STATE.WILL_SHOW_MORE);
            }
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              padding: '8px',
              fontWeight: '700',
              color: muiTheme.palette.primary.main,
              fontSize: '15px',
              background: `linear-gradient(to top, ${fade(
                muiTheme.palette.primary.light,
                0.37,
              )},  rgba(0,0,0,0))`,
              backdropFilter: 'blur(6px)',
            }}
          >
            {isWillShowMore
              ? inflection.titleize(translate('ra.action.showMore'))
              : inflection.titleize(translate('ra.action.showLess'))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FieldWrapper;
