/* eslint-disable */
import React, { useState } from "react";
import AccessTime from "@material-ui/icons/AccessTime";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import makeStyles from "@material-ui/styles/makeStyles";
import { fade } from "@material-ui/core";
import Axios from "axios";
import Type from "prop-types";
import { useNotify, useTranslate, useRefresh, useListContext } from "ra-core";
import { SmallButton } from "../../../../base/components/ra/buttons";
import ToolbarMenu from "../../../../base/components/action-menu/toolbar-menu";
import { useError } from "../../../../base/hooks";
import ForceChangePasswordForm from "../../../user/components/forceChangePassword.button";
import EditBalanceForm from "./edit-balance.form";
import { useSelector } from "react-redux";
import { getBackofficeEnv } from "../../../../services/util";

const useStyles = makeStyles(() => ({
  viewButton: {
    color: "#0080FF",
    "&:hover": {
      backgroundColor: fade("#0080FF", 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  sendMailButton: {
    color: "#EAAA00",
    "&:hover": {
      backgroundColor: fade("#EAAA00", 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const ActionMenu = (props) => {
  const { record: recordResourceLink, propsController } = props;
  const notify = useNotify();
  const { notifyError } = useError();
  const translate = useTranslate();
  const refresh = useRefresh();
  const [disabledSendMail, setDisabledSendMail] = useState(false);

  const record = recordResourceLink.user;
  const recordPlayer = recordResourceLink.players?.[0] || {};

  const { brandId } = recordResourceLink || {};

  const brand = useSelector(
    ({ admin }) => admin.resources.brand?.data[brandId]
  );

  const env = getBackofficeEnv();

  const classes = useStyles();

  const sendCredentialsEmail = async () => {
    try {
      setDisabledSendMail(true);
      const getPlayersUrl =
        brand?.implementation?.createPlayerUrl?.split("/create")?.[0];

      const { data } = await Axios.get(getPlayersUrl, {
        params: {
          playTokens: recordPlayer.playerToken,
        },
      });

      if (data.length) {
        const playerFromOperator = data[0];

        await Axios.post(`api/user/${record?.id}/send-credentials-email`, {
          password: playerFromOperator.password,
        });

        notify(
          translate("ra.message.successfully", {
            smart_name: translate("ra.text.sendCredentialsEmail"),
          }),
          "success"
        );
      }
    } catch (error) {
      notifyError(error);
    } finally {
      setDisabledSendMail(false);
    }
  };

  return (
    <ToolbarMenu
      {...props}
      styleWrapper={{ justifyContent: "center", width: "100%" }}
    >
      <Tooltip
        title={translate("ra.text.sendCredentialsEmail")}
        className={classes.sendMailButton}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();

            sendCredentialsEmail();
          }}
          disabled={disabledSendMail}
        >
          <MailIcon />
        </IconButton>
      </Tooltip>

      <EditBalanceForm
        {...props}
        record={recordPlayer}
        propsController={propsController}
      />

      <ForceChangePasswordForm {...props} record={record} />

      <Tooltip
        title={translate("ra.text.lobbyPage")}
        className={classes.viewButton}
      >
        <IconButton
          onClick={(e) => {
            const brandCode = brand?.code;
            const groupCode = brand?.group?.code;
            const playerToken = recordPlayer.playerToken;
            const href = `${
              env.LOBBY_PAGE_URL ||
              "https://lobby.dev.game.topasianplatform.com/"
            }?brandCode=${brandCode}&groupCode=${groupCode}&playerToken=${playerToken}`;

            window.open(href, "_blank");
          }}
        >
          <SportsEsportsIcon />
        </IconButton>
      </Tooltip>
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};

export default ActionMenu;
