/* eslint-disable */
import * as React from 'react';
import { cloneElement, useCallback, useMemo, useRef } from 'react';
import { FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { ValidationError } from 'ra-core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  SimpleFormIteratorItem,
  SimpleFormIteratorContext,
  useSimpleFormIteratorStyles,
  useArrayInput,
  AddItemButton as DefaultAddItemButton,
  RemoveItemButton as DefaultRemoveItemButton,
  ReOrderButtons as DefaultReOrderButtons,
} from 'react-admin';
import { useFormState } from 'react-final-form';

const DefaultLabelFn = (index) => index + 1;

const SimpleFormIterator = (props) => {
  const {
    addButton = <DefaultAddItemButton />,
    removeButton = <DefaultRemoveItemButton />,
    reOrderButtons = <DefaultReOrderButtons />,
    basePath,
    children,
    className,
    record,
    resource,
    source,
    disabled,
    disableAdd,
    deleteRemove,
    disableRemove,
    disableReordering,
    variant,
    margin,
    TransitionProps,
    defaultValue,
    getItemLabel = DefaultLabelFn,
  } = props;
  const classes = useSimpleFormIteratorStyles(props);
  const { fields, meta } = useArrayInput(props);
  const { error, submitFailed } = meta;
  const nodeRef = useRef(null);
  const { values } = useFormState();

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(fields && fields.length ? fields.length : defaultValue ? defaultValue.length : 0);

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []);

  const removeField = useCallback(
    (index) => {
      ids.current.splice(index, 1);
      fields.remove(index);
    },
    [fields],
  );

  const addField = useCallback(
    (item = undefined) => {
      ids.current.push(nextId.current++);
      fields.push(item);
    },
    [fields],
  );

  // add field and call the onClick event of the button passed as addButton prop
  const handleAddButtonClick = (originalOnClickHandler) => (event) => {
    addField();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  const handleReorder = useCallback(
    (origin, destination) => {
      const item = ids.current[origin];
      ids.current[origin] = ids.current[destination];
      ids.current[destination] = item;
      fields.move(origin, destination);
    },
    [fields],
  );

  const records = get(record, source);

  const context = useMemo(
    () => ({
      total: fields.length,
      add: addField,
      remove: removeField,
      reOrder: handleReorder,
    }),
    [fields.length, addField, removeField, handleReorder],
  );
  return fields ? (
    <SimpleFormIteratorContext.Provider value={context}>
      <ul className={classNames(classes.root, className)}>
        {submitFailed && typeof error !== 'object' && error && (
          <FormHelperText error>
            <ValidationError error={error} />
          </FormHelperText>
        )}
        <TransitionGroup component={null}>
          {fields.map((member, index) => {
            return (
              <CSSTransition nodeRef={nodeRef} key={index} timeout={500} classNames="fade" {...TransitionProps}>
                <SimpleFormIteratorItem
                  basePath={basePath}
                  classes={classes}
                  disabled={disabled}
                  disableRemove={deleteRemove}
                  disableReordering={disableReordering}
                  fields={fields}
                  getItemLabel={getItemLabel}
                  index={index}
                  margin={margin}
                  member={member}
                  meta={meta}
                  onRemoveField={removeField}
                  onReorder={handleReorder}
                  record={(records && records[index]) || {}}
                  removeButton={cloneElement(removeButton, {
                    disabled:
                      typeof disableRemove === 'function'
                        ? disableRemove((Array.isArray(values[source]) && values[source][index]) || {})
                        : disableRemove,
                  })}
                  reOrderButtons={reOrderButtons}
                  resource={resource}
                  source={source}
                  variant={variant}
                  ref={nodeRef}
                >
                  {children}
                </SimpleFormIteratorItem>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
        {!disabled && !disableAdd && (
          <li className={classes.line}>
            <span className={classes.action}>
              {cloneElement(addButton, {
                onClick: handleAddButtonClick(addButton.props.onClick),
                className: classNames('button-add', `button-add-${source}`),
              })}
            </span>
          </li>
        )}
      </ul>
    </SimpleFormIteratorContext.Provider>
  ) : null;
};

SimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

SimpleFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  addButton: PropTypes.element,
  removeButton: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  // @ts-ignore
  fields: PropTypes.object,
  meta: PropTypes.object,
  // @ts-ignore
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
};

export default SimpleFormIterator;
