import React, { useEffect, useState } from 'react';
import { useShowController, useTranslate } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { checkCheatModeEnv } from '../../services/util';
import { useAuthUser } from '../../base/hooks';
import { isUserSA } from '../../constant';
import { ActionMenu } from './components/action.menu';
import { UNLIMITED_PLAYER_VALUE } from './components/player-limit';

export default function BrandShow(props) {
  const user = useAuthUser();
  const isSystemAdmin = isUserSA(user);
  const { record } = useShowController(props);
  const translate = useTranslate();

  const [excludeFields, setExcludeFields] = useState([]);

  const isCheatMode = checkCheatModeEnv();

  useEffect(() => {
    if (!record?.walletIntegration?.implementation) {
      return;
    }

    const excludeKeys = [];

    if (!isCheatMode) {
      excludeKeys.push('playerLimit');
    } else if (record?.playerLimit === UNLIMITED_PLAYER_VALUE) {
      record.playerLimit = translate('resources.brand.text.unlimited');
    }

    switch (record?.walletIntegration?.implementation) {
      case 'SEAMLESS':
        excludeKeys.push('secretKey');
        break;
      case 'TRANSFER':
        excludeKeys.push('publicKey');
        break;
      default:
        return;
    }

    setExcludeFields(excludeKeys);
  }, [record?.walletIntegration?.implementation, record?.playerLimit]);

  return (
    <WealthShowGuesser
      {...props}
      excludeFields={excludeFields}
      actionMenu={isSystemAdmin ? (
        <ActionMenu
          type="toolbar"
          isSystemAdmin
        />
      ) : null}
    />
  );
}
