import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, required, useEditContext, useGetOne, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { Edit } from '../../../base/components/ra/views';
import StringInput from '../../../base/components/customized/common-input/StringInput';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import BrandInput from './components/brand-input';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import { EnumViewType, registerViewType } from '../../../base/hooks/useViewType';

const requiredValidator = required();

const PartnerEdit = ({
  resource, ...otherProps
}) => {
  const translate = useTranslate();
  const { pathname } = useLocation();

  useEffect(() => {
    // Hotfix: To self register view type because the current system doesn't cover this case
    registerViewType(pathname, EnumViewType.EDIT);
  }, []);

  return (
    <Edit
      resource={resource}
      confirmDialogInfo={{
        content: 'resources.reverse-integration.partner.confirmToEditContent',
      }}
      translatedResourceName={translate('resources.reverse-integration.partner.name')}
      {...otherProps}
    >
      <SimpleForm redirect="show">
        <StringInput
          source="name"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <StringInput
          source="partnerId"
          validate={requiredValidator}
          fullWidth
          helperText
          label="resources.reverse-integration.partner.fields.partnerId"
        />
        <GroupSelector
          disabled
          validate={requiredValidator}
          fullWidth
          inputProps={{
            helperText: true,
          }}
        />
        <BrandSelector />
        <UrlInput
          source="authorizeUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="placeBetUrl"
          label="common.fields.placeBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="payOutUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="getBalanceUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="cancelBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
      </SimpleForm>
    </Edit>
  );
};

const BrandSelector = () => {
  const { values: { groupId } } = useFormState();
  const { record } = useEditContext();
  const brandInfo = useGetOne('brand', record.brandId);

  const initialBrand = useMemo(() => {
    const data = brandInfo?.data;

    if (!data) return null;
    return {
      brandId: data.id,
      name: data.name,
    };
  }, [brandInfo]);

  return (
    <BrandInput
      source="brandId"
      groupId={groupId}
      validate={requiredValidator}
      initialValue={initialBrand}
      disabled
    />
  );
};

PartnerEdit.propTypes = {
  resource: PropTypes.string.isRequired,
  options: PropTypes.shape({
    operatorId: PropTypes.string.isRequired,
  }).isRequired,
};

export default PartnerEdit;
