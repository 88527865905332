import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Cookie from '../util/handleCookie';
import LocalStorage, { userStorageKey } from '../local-storage';
import { forceLogout } from '../util/handleByHttpCode';
import { goToLoginPage } from '../util';

let refreshTimeOutId;

const clearRefreshTimeout = () => {
  if (refreshTimeOutId) {
    clearTimeout(refreshTimeOutId);
    refreshTimeOutId = null;
  }
};

const getRefreshedToken = async () => {
  try {
    await axios.post('/api/auth/token/refreshToken');
  } catch (error) {
    goToLoginPage();
  }
};

export const fetchUser = async (forceUpdate = false) => {
  if (!forceUpdate) {
    const user = LocalStorage.getItem(userStorageKey);
    if (!isEmpty(user)) {
      const { cacheExpiredTime } = user;
      if (moment(cacheExpiredTime).isSameOrAfter(new Date())) {
        return user;
      }
    }
  }

  try {
    const accessToken = Cookie.getItem('accessToken');
    if (!accessToken) {
      goToLoginPage();
      return null;
    }

    const { data } = await axios.get('/api/auth/me');
    LocalStorage.setItem(userStorageKey, {
      ...data,
      cacheExpiredTime: moment().add(60, 'second').toDate(), // cache will live 60s
    });
    return data;
  } catch (error) {
    // Do nothing!
    return null;
  }
};

export default {
  login: async payload => {
    try {
      const newAuthenticateAPI = 'v1/auth/session'; // "token/password"
      const response = await axios.post(`/api/${newAuthenticateAPI}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          authorization: 'Basic YXBpLWRldjpJT0huMTUxMDJITkxLUCNPR0pT',
        },
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      // if (response.data?.accessToken && !refreshTimeOutId) {
      //   const { exp: tokenExpiry } = jwtDecode(response.data.accessToken);
      //   refreshTimeOutId = window.setTimeout(
      //     getRefreshedToken,
      //     tokenExpiry * 1000 - 5000,
      //   ); // Validity period of the token in seconds, minus 5 seconds
      // }

      if (response.data?.accessToken) {
        const { exp: tokenExpiry } = jwtDecode(response.data.accessToken);
        clearRefreshTimeout();
        refreshTimeOutId = window.setTimeout(getRefreshedToken, tokenExpiry * 1000 - 5000); // Validity period of the token in seconds, minus 5 seconds
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(new Error(error.response?.data?.message || error.message));
    }
  },
  // don't handle logout in this method
  logout: async () => {
    clearRefreshTimeout();
    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      forceLogout({}, false);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: false,
      });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const accessToken = Cookie.getItem('accessToken');
    const refreshToken = Cookie.getItem('refreshToken');

    if (!accessToken) {
      if (refreshToken) {
        getRefreshedToken();
      } else {
        // goToLoginPage();
        LocalStorage.removeItem(userStorageKey);
      }

      // @NOTE: quick fix to redirect to cheat-mode-apply page -> here
      window.location.assign('/#/cheat-mode-apply');
      return Promise.reject();
    }

    // @NOTE: quick fix to redirect to cheat-mode-apply page -> there
    window.location.assign('/#/cheat-mode-apply');
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const user = await fetchUser();
    return user?.superman
      ? {
        superman: user.superman,
      }
      : user?.perms?.filter(p => p.create || p.read || p.update || p.delete);
  },
};
