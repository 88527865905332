/* eslint-disable */
import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import ReferenceInput from '../../../base/components/ra/inputs/reference.input';
import resourceSlug from '../../../constant/resource-slug';
import { useAuthUser } from '../../../base/hooks';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';

const NATIVE_ID_SOURCE_KEY = `${resourceSlug.PLAYER}.id`;

const NativePlayerIdFilter = () => {
  const translate = useTranslate();
  const { values: filteredValues } = useFormState();
  const form = useForm();
  const user = useAuthUser();
  const userGroupId = user?.group?.id;
  const userBrandId = user?.brands?.[0]?.id;
  const groupId = userGroupId || filteredValues?.group?.id;
  const brandId = userBrandId || filteredValues?.brand?.id;

  return (
    <>
      <OnChange name="group">
        {() => {
          if (userGroupId) {
            // Do nothing
          } else {
            form.change(NATIVE_ID_SOURCE_KEY, null);
          }
        }}
      </OnChange>
      <OnChange name="brand">
        {() => {
          if (userBrandId) {
            // Do nothing
          } else {
            form.change(NATIVE_ID_SOURCE_KEY, null);
          }
        }}
      </OnChange>
      <ReferenceInput
        label={translate('resources.transaction-transfer.fields.nativePlayerId')}
        source={NATIVE_ID_SOURCE_KEY}
        reference={resourceSlug.PLAYER}
        filter={{
          groupId: groupId ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${groupId}` : undefined,
          brandId: brandId ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${brandId}` : undefined,
        }}
        filterToQuery={(value) => ({
          'nativeId||$cont': value,
        })}
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        perPage={100}
      >
        <AutocompleteInput optionText="nativeId" resettable />
      </ReferenceInput>
    </>
  );
};

export default NativePlayerIdFilter;
