/* eslint-disable no-unused-vars, no-underscore-dangle */
import _ from 'lodash';

import { AppThemeEnum } from '../constant';
import LoginPage from '../base/components/layout/loginPage';
import GreenPacificQueenLoginPage from '../base/components/layout/GreenPacificQueenLoginPage';

const _otherThemeConfigMapping = {
  [AppThemeEnum.DEFAULT]: {
    background: '#f4f7fe',
    loginPageComponent: LoginPage,
  },
  [AppThemeEnum.GREEN_PACIFIC_QUEEN]: {
    // background: '#48c289',
    background: '#ffffff',
    loginPageComponent: GreenPacificQueenLoginPage,
  },
};

export const otherThemeConfigMapping = new Proxy(_otherThemeConfigMapping, {
  get(target, prop, _receiver) {
    const targetKeys = _.keys(target);
    if (!targetKeys.includes(prop)) {
      return {};
    }
    return target[prop];
  },
});
