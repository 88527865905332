/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Dialog, Button, useTheme, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useLocale, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { Typography } from 'antd';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import BetSettingCreateForm from '../../../base/components/bet-setting/bet-setting-create-form';
import { useBetSettingFormContext } from '../../../base/components/bet-setting/utils/bet-setting-form.context';
import { BET_SETTING_CONFIG_STATUS } from './constants';
import { compareListItem, generateBetSettingKey, sortListItem } from './utils';

const checkBetSettingStructure = betSetting => !!(
  betSetting?.betLevel?.length
    && betSetting?.betSize?.length
    && betSetting?.betLevelDefault
    && betSetting?.betSizeDefault
    && betSetting?.currency?.id
    && betSetting?.game?.id
    && betSetting?.totalBetLimit?.length === 2
);

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: 'calc(100vw - 40px)',
    maxWidth: '1350px',
    padding: '0',
  },
  divideHorizontal: {
    padding: 0,
    position: 'relative',
    '&:before': {
      content: '""',
      width: '1px',
      height: '20px',
      background: '#787878',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  closeBtnWrapper: {
    flex: 1,
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      width: '80px',
    },
  },
}));

const BetSettingDialog = props => {
  const {
    open,
    onClose: onCloseParent,
    game,
    currency,
    sourceNamePrefix,
    totalBetMinSourceName,
    totalBetMaxSourceName,
  } = props;

  const pickerSourceName = `${sourceNamePrefix}picker`;

  const betStKey = generateBetSettingKey({
    gameCode: game.id,
    currencyCode: currency.code,
  });

  const {
    betSettingDefaultLoaded, defaultSettingValue, getBetSettingDefault,
  } = useBetSettingFormContext();

  const form = useForm();
  const { values: formValues } = useFormState();

  const classes = useStyles();
  const locale = useLocale();
  const muiTheme = useTheme();
  const translate = useTranslate();

  const [initByCustomData, setInitByCustomData] = useState(null);

  const configStatus = get(
    formValues,
    `${sourceNamePrefix}betSettingConfigStatus.${betStKey}`,
    [undefined, false].includes(betSettingDefaultLoaded) ? undefined : BET_SETTING_CONFIG_STATUS.defaultSetting,
  );

  const isTranslatable = typeof game.name === 'object';

  const Badge = ({ children }) => (
    <Box
      style={{
        color: muiTheme.palette.primary.main,
        fontWeight: 700,
        padding: '2px 8px',
        background: alpha(muiTheme.palette.primary.main, 0.1),
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );

  const handleClose = (e, reason) => {
    if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
      e.stopPropagation();
      return;
    }

    if (typeof onCloseParent === 'function') {
      onCloseParent(e, reason);
    }

    // NOTE: The key 'picker' related to the pickerSourceName key defined below
    const { picker } = get(formValues, sourceNamePrefix.slice(0, sourceNamePrefix.length - 1), {});

    // Before close this Dialog... Need to remove this picker input values from the Form
    form.change(pickerSourceName, undefined);
    form.change(totalBetMinSourceName, undefined);
    form.change(totalBetMaxSourceName, undefined);

    setInitByCustomData(null);

    if (!picker) {
      return;
    }

    const totalBetMin = get(formValues, totalBetMinSourceName);
    const totalBetMax = get(formValues, totalBetMaxSourceName);

    const newData = {
      game: {
        id: game.id,
      },
      currency: {
        id: currency.id,
      },
      betSize: picker.listItem.betSize.map(item => ({
        value: item.value,
      })),
      betLevel: picker.listItem.betLevel.map(item => ({
        value: item.value,
      })),
      betSizeDefault: picker.defaultValue.betSize,
      betLevelDefault: picker.defaultValue.betLevel,
      totalBetLimit: [totalBetMin, totalBetMax],
    };

    let newDataStatus = defaultSettingValue?.betSizes?.find(item => item.default)?.value === picker.defaultValue.betSize
      && defaultSettingValue?.betLevels?.find(item => item.default)?.value === picker.defaultValue.betLevel
      && compareListItem(sortListItem(defaultSettingValue?.betSizes), picker.listItem.betSize)
      && compareListItem(sortListItem(defaultSettingValue?.betLevels), picker.listItem.betLevel)
      && defaultSettingValue.totalBetLimit[0] === totalBetMin
      && defaultSettingValue.totalBetLimit[1] === totalBetMax
      ? BET_SETTING_CONFIG_STATUS.defaultSetting
      : BET_SETTING_CONFIG_STATUS.customSetting;

    if (!defaultSettingValue) {
      const betSetting = get(form.getState().values, `${sourceNamePrefix}betSettings.${betStKey}`, {});
      const hasBetSettingCustom = checkBetSettingStructure(betSetting);

      if (!hasBetSettingCustom) {
        newDataStatus = BET_SETTING_CONFIG_STATUS.noSetting;
      }
    }

    if (newDataStatus !== configStatus) {
      form.change(`${sourceNamePrefix}betSettingConfigStatus.${betStKey}`, newDataStatus);
    }

    form.change(`${sourceNamePrefix}betSettings.${betStKey}`, newData);
  };

  useEffect(() => {
    if (game?.id && currency?.id && betSettingDefaultLoaded === undefined) {
      getBetSettingDefault(game.id, currency.id);
    }
  }, [game, currency, betSettingDefaultLoaded]);

  useEffect(() => {
    const currentBetSetting = get(form.getState().values, `${sourceNamePrefix}betSettings.${betStKey}`);
    if (isObject(currentBetSetting) && open) {
      setInitByCustomData(currentBetSetting);
    }
  }, [betStKey, open]);

  return (
    <Box marginTop="24px">
      <Dialog
        open={open}
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={handleClose}
      >
        <Box
          style={{
            marginBottom: '24px',
            padding: '8px 20px',
            background: '#ebebeb',
            borderBottom: '1px solid #d8d8d8',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontWeight: 600,
            }}
          >
            <Box>{translate('ra.text.configBetSetting')}</Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px',
              }}
            >
              <Badge>{isTranslatable ? game.name?.[locale] : game.name}</Badge>
              <Box color="#787878">&</Box>
              <Badge>{currency.name}</Badge>
              {configStatus?.text && (
                <>
                  <Box
                    component="span"
                    className={classes.divideHorizontal}
                  />
                  <Typography>{translate(configStatus.text)}</Typography>
                </>
              )}
            </Box>
            <Box className={classes.closeBtnWrapper}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
              >
                {translate('ra.action.ok')}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box padding="8px 20px">
          {/* <CircularProgress /> */}

          <BetSettingCreateForm
            sourceNamePrefix={sourceNamePrefix}
            initByCustomData={initByCustomData}
            game={game}
            currency={currency}
            totalBetMinSourceName={totalBetMinSourceName}
            totalBetMaxSourceName={totalBetMaxSourceName}
            pickerSourceName={pickerSourceName}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

BetSettingDialog.defaultProps = {
  sourceNamePrefix: '',
};

BetSettingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  game: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  sourceNamePrefix: PropTypes.string,
  totalBetMinSourceName: PropTypes.string.isRequired,
  totalBetMaxSourceName: PropTypes.string.isRequired,
};

export default BetSettingDialog;
