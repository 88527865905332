/* eslint-disable */
import { Box, Button as MuiButton, fade, makeStyles } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Notification, useTranslate, useSetLocale } from "react-admin";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { getRawTheme } from "../../../../custom-theme/themeConfig";
import CmtImage from "../../../../theme/@coremat/CmtImage";
import AuthWrapper from "../../../../theme/@jumbo/components/Common/authComponents/AuthWrapper";
import ContentLoader from "../../../../theme/@jumbo/components/ContentLoader";
import { withStandardButtonProps } from "../../../hoc/with-standard-button-props";
import { useError, useAuthUser } from "../../../hooks";
import { REGEX_PASSWORD } from "../../../../services/util/validate/regularExpression";
import dataProvider from "../../../../services/provider/dataProvider";
import { forceLogout, cleanUserClue } from "../../../../services/util/handleByHttpCode";
import { goToLoginPage, getBackofficeEnv } from "../../../../services/util";

import { updateRequireChangePassword } from "../../../../services/redux/auth/auth.action";
import { UPDATE_PASSWORD_WITH_ID } from "../../../../constant";
import { PasswordInput } from "../../ra/inputs";
import Dialog from "./dialogGoToLogin";
import { appBrandConfigMapping } from "../../../../constant/appBrandMapping";

const DialogGoToLogin = (props) => {
  const { timeout, timeoutAction, open, ...rest } = props;
  const translate = useTranslate();
  const [timeoutState, setTimeoutState] = useState(timeout);

  const getSecond = (ms) => {
    return ("0" + parseInt(ms / 1000)).slice(-2);
  };

  const timeoutRef = useRef(0);
  timeoutRef.current = timeoutState;

  useEffect(() => {
    if (!open) {
      return;
    }

    // Timeout run when this dialog is opened!
    const interval = setInterval(() => {
      if (timeoutRef.current < 2000) {
        timeoutAction();
        clearInterval(interval);
      }

      setTimeoutState(timeoutRef.current - 1000);
    }, 1000);

    return function cleanUp() {
      clearInterval(interval);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      {...rest}
      confirmLabel={`${translate("ra.action.goLogin")} (${getSecond(
        timeoutState
      )})`}
    />
  );
};

DialogGoToLogin.propTypes = {
  timeoutAction: PropTypes.func,
  open: PropTypes.bool,
  timeout: PropTypes.number,
};

DialogGoToLogin.defaultProps = {
  timeoutAction: (f) => f,
  open: false,
  timeout: 0,
};

const Button = withStandardButtonProps(MuiButton);

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    display: "none",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  authContent: {
    padding: theme.spacing(3),

    "& img": {
      width: 100,
      margin: "0 auto",
      display: "block",
    },

    [theme.breakpoints.up("md")]: {
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
      order: 1,
      padding: 30,
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  titleRoot: {
    textAlign: "center",
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: fade(theme.palette.common.black, 0.12),
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  btnSubmit: {
    color: '#ffffff',
    backgroundColor: ({ customTheme }) => customTheme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ customTheme }) => customTheme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: ({ customTheme }) => customTheme.palette.primary.main,
    }
  },
  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(7),
    },
  },
}));

// Change password for the new user
const RequireChangePassword = ({
  variant = "default",
  wrapperVariant = "default",
}) => {
  const setLocale = useSetLocale();
  const translate = useTranslate();
  const { notifyError } = useError();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const { APP_THEME, APP_BRAND } = getBackofficeEnv();
  const { newUserChangePasswordPageLogo } = appBrandConfigMapping[APP_BRAND];

  const classes = useStyles({
    variant,
    customTheme: getRawTheme(APP_THEME),
  });

  const [displayConfirmGoLogin, setDisplayConfirmGoLogin] = useState(false);

  async function onSubmit(values) {    
    const { newPassword, confirmPassword } = values;

    try {
      await dataProvider.put(UPDATE_PASSWORD_WITH_ID(user.id), {
        data: {
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          requireChangePassword: true,
        },
      });

      cleanUserClue();

      dispatch(
        updateRequireChangePassword({
          needChange: false,
        })
      );

      setDisplayConfirmGoLogin(true);
    } catch (error) {
      notifyError(error);
    }
  }

  const handleValidation = (values) => {
    const { newPassword, confirmPassword } = values;

    // Validate `newPassword`
    if (!newPassword) {
      return {
        newPassword: translate("ra.validation.required"),
      };
    }

    const validPassword = REGEX_PASSWORD.test(newPassword);
    if (!validPassword) {
      return {
        newPassword: translate("wa.exception.InvalidPassword"),
      };
    }

    // Validate `confirmPassword`
    if (!confirmPassword) {
      return {
        confirmPassword: translate("ra.validation.required"),
      };
    }

    if (confirmPassword !== newPassword) {
      return {
        confirmPassword: translate(
          "wa.exception.newPasswordAndConfirmPasswordNotMatch"
        ),
      };
    }
  };

  /**
   * Need to set locale to get translations from server
   */
  useEffect(() => {
    setLocale("en");
  }, []);

  /**
   * Handle case the new user do not submit this form to change password,
   * and login with another account.
   * */
  useEffect(() => {
    return function cleanUp() {
      forceLogout();
    };
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb} flex="1">
          <CmtImage
            src={newUserChangePasswordPageLogo}
            style={{ width: 100, display: "block", margin: "0 auto" }}
          />
        </Box>
      ) : null}
      <Box className={classes.authContent} flex="1">
        <Box className={classes.logo}>
          <CmtImage
            height={40}
            style={{ width: 'auto' }}
            src={newUserChangePasswordPageLogo}
          />
        </Box>
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          {translate("ra.page.requireChangePassword")}
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={handleValidation}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="newPassword" component={PasswordInput}>
                {({ input, meta }) => (
                  <PasswordInput
                    {...input}
                    label={translate("ra.field.newPassword")}
                    type="password"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    helperText={meta.touched && meta.error ? meta.error : ""}
                    error={!!(meta.touched && meta.error)}
                  />
                )}
              </Field>
              <Field name="confirmPassword" component={PasswordInput}>
                {({ input, meta }) => (
                  <PasswordInput
                    {...input}
                    label={translate("ra.auth.password")}
                    type="password"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    helperText={meta.touched && meta.error ? meta.error : ""}
                    error={!!(meta.touched && meta.error)}
                  />
                )}
              </Field>
              <Box mt={2.5}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className={classes.btnSubmit}
                >
                  {translate("ra.action.save")}
                </Button>
              </Box>
            </form>
          )}
        />
        <DialogGoToLogin
          hideDeny
          open={displayConfirmGoLogin}
          timeout={10000}
          timeoutAction={() => goToLoginPage()}
          onClose={() => setDisplayConfirmGoLogin(false)}
          onConfirm={() => goToLoginPage()}
          dialogTitle={translate("ra.notification.goToLoginPage")}
        >
          {translate("ra.notification.changePasswordSuccessAndGoLogin")}
        </DialogGoToLogin>
        <ContentLoader />
      </Box>
      <Notification />
    </AuthWrapper>
  );
};

export default RequireChangePassword;
