import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDataProvider, useLogout, useTranslate } from 'ra-core';
import * as React from 'react';
import { CheckboxGroupInput, FormWithRedirect, useNotify } from 'react-admin';
import { SaveButton, Button } from '../ra/buttons';
import { PasswordInput } from '../ra/inputs';
import { useError } from '../../hooks';
import { fetchUser } from '../../../services/provider/authProvider';
import { UPDATE_PASSWORD_WITH_ID } from '../../../constant';
import { REGEX_PASSWORD } from '../../../services/util/validate/regularExpression';

function ChangePasswordForm(props) {
  const {
    open,
    close,
    record: user,
    hasLogoutButton,
    hasCloseButton,
    hasRequireChangePassword,
    userRequireChangePassword,
    title,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const logout = useLogout();
  const { notifyError } = useError();

  // @TODO move to separated validation module if needed
  function matchPassword(
    message = 'wa.exception.newPasswordAndConfirmPasswordNotMatch',
  ) {
    return function (value, allValues) {
      return value === allValues.newPassword ? undefined : translate(message);
    };
  }

  async function handleSubmit(formValues) {
    try {
      await dataProvider.put(
        UPDATE_PASSWORD_WITH_ID(user.id),
        {
          data: {
            newPassword: formValues.newPassword,
            confirmPassword: formValues.confirmPassword,
            requireChangePassword:
              formValues?.requireChangePassword?.[0] || false,
          },
        },
        {
          onSuccess: () => {
            if (userRequireChangePassword) {
              fetchUser(true);
            }
            close();
            notify('ra.notification.forceChangePasswordSuccess', 'success');
          },
        },
      );
    } catch (error) {
      notifyError(error);
    }
  }

  const handleValidation = values => {
    const {
      newPassword, confirmPassword,
    } = values;

    // Validate `newPassword`
    if (!newPassword) {
      return {
        newPassword: translate('ra.validation.required'),
      };
    }

    const validPassword = REGEX_PASSWORD.test(newPassword);
    if (!validPassword) {
      return {
        newPassword: translate('wa.exception.InvalidPassword'),
      };
    }

    // Validate `confirmPassword`
    if (!confirmPassword) {
      return {
        confirmPassword: translate('ra.validation.required'),
      };
    }

    if (confirmPassword !== newPassword) {
      return {
        confirmPassword: translate('wa.exception.newPasswordAndConfirmPasswordNotMatch'),
      };
    }

    return {};
  };

  const handleLogout = () => {
    logout();
    close();
  };

  return (
    <Dialog
      fullWidth
      open={!!open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <FormWithRedirect
        save={handleSubmit}
        validate={handleValidation}
        render={({
          handleSubmitWithRedirect, pristine, saving,
        }) => (
          <form>
            <DialogContent>
              <input
                type="text"
                name="email"
                value={`${user.username}`}
                autoComplete="username email"
                style={{
                  display: 'none',
                }}
              />
              <PasswordInput
                label={translate('ra.field.newPassword')}
                source="newPassword"
                autoComplete="new-password"
                fullWidth
              />
              <PasswordInput
                label={translate('ra.field.confirmPassword')}
                source="confirmPassword"
                autoComplete="new-password"
                fullWidth
                validate={matchPassword()}
              />
              {hasRequireChangePassword && (
                <CheckboxGroupInput
                  source="requireChangePassword"
                  label="" // get in choices > name bellow
                  choices={[
                    {
                      id: true,
                      name: translate('wa.notice.requireChangePassword'),
                    },
                  ]}
                />
              )}
            </DialogContent>
            <DialogActions>
              {hasCloseButton && (
                <Button
                  label={translate('ra.action.close')}
                  onClick={close}
                  variant="text"
                  color="primary"
                  size="small"
                />
              )}
              {hasLogoutButton && (
                <Button
                  label={translate('ra.text.logout')}
                  onClick={handleLogout}
                  variant="text"
                  size="small"
                  color="primary"
                />
              )}
              <SaveButton
                label={translate('ra.action.confirm')}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                size="small"
                color="primary"
                variant="text"
                disabled={pristine}
                icon={<></>}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

ChangePasswordForm.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  record: PropTypes.object,
  hasLogoutButton: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  hasRequireChangePassword: PropTypes.bool,
  userRequireChangePassword: PropTypes.bool,
  title: PropTypes.node,
};

ChangePasswordForm.defaultProps = {
  record: {},
  hasLogoutButton: false,
  hasCloseButton: true,
  hasRequireChangePassword: true,
  userRequireChangePassword: false, // user login first time
  title: '',
  close: f => f,
};

export default ChangePasswordForm;
