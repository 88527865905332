import { Card } from '@material-ui/core';
import React from 'react';
import { ReferenceField, TextField, UrlField, useTranslate } from 'react-admin';
import { ShowSection } from '../../../base/components/guesser/wealth-show.guesser';
import CommonShowWrapper from '../../../base/components/customized/common-wrapper/CommonShowWrapper';
import { DateField } from '../../../base/components/ra/fields';
import Labeled from '../../../base/components/ra/labeled';

const ReverseWalletIntegrationShow = props => {
  const translate = useTranslate();

  return (
    <CommonShowWrapper {...props}>
      <Card>
        <ShowSection title={translate('common.show.general')}>
          <TextField
            source="id"
            label="common.fields.id"
          />
          <Labeled label="common.fields.created">
            <DateField source="createdAt" />
          </Labeled>
          <Labeled label="common.fields.updated">
            <DateField source="updatedAt" />
          </Labeled>
        </ShowSection>
        <ShowSection
          title={translate(
            'resources.reverse-integration.reverseWalletIntegration.reverseWalletIntegrationInformation',
          )}
        >
          <ReferenceField
            reference="group"
            source="groupId"
            label="common.fields.group"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference="brand"
            source="brandId"
            label="common.fields.brand"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <UrlField
            source="authorizeUrl"
            label="common.fields.authorizeUrl"
            target="_blank"
          />
          <UrlField
            source="getBalanceUrl"
            label="common.fields.getBalanceUrl"
            target="_blank"
          />
          <UrlField
            source="payOutUrl"
            label="common.fields.payOutUrl"
            target="_blank"
          />
          <UrlField
            source="placeBetUrl"
            label="common.fields.placeBetUrl"
            target="_blank"
          />
          <UrlField
            source="cancelBetUrl"
            label="common.fields.cancelBetUrl"
            target="_blank"
          />
        </ShowSection>
      </Card>
    </CommonShowWrapper>
  );
};

export default ReverseWalletIntegrationShow;
