/* eslint-disable */
import React from 'react';
import ReportDateRangeInput from '../../../base/components/reports/ReportFilterWrapper/ReportDateRangeInput';
import CurrencyTypeSelector from '../../../base/components/reports/ReportFilterWrapper/CurrencyTypeSelector';
import CurrencyInput from '../../../base/components/reports/ReportFilterWrapper/CurrencyInput';
import GroupInput from '../../../base/components/reports/ReportFilterWrapper/GroupInput';
import BrandInput from '../../../base/components/reports/ReportFilterWrapper/BrandInput';
import { useAuthUser } from '../../../base/hooks';
import ConsolidatedInput from '../../../base/components/reports/ReportFilterWrapper/ConsolidatedInput';
import BetTypeSelect from '../../../base/components/reports/ReportFilterWrapper/BetTypeSelect';
import GameTypeInput from '../../../base/components/reports/ReportFilterWrapper/GameTypeInput';
import PlayerIdInput from '../../../base/components/reports/ReportFilterWrapper/PlayerId';
import NativeIdInput from '../../../base/components/reports/ReportFilterWrapper/NativeId';

const PlayersReportFilters = ({ reportServiceVersion }) => {
  const user = useAuthUser();

  const isUnderGroupManagement = Boolean(user?.group?.id);

  return (
    <>
      <ReportDateRangeInput reportServiceVersion={reportServiceVersion} />
      {!isUnderGroupManagement && <GroupInput />}
      <BrandInput />
      <ConsolidatedInput />
      <CurrencyTypeSelector />
      <CurrencyInput />
      <GameTypeInput />
      <BetTypeSelect />
      <PlayerIdInput />
      <NativeIdInput />
    </>
  );
};

export default PlayersReportFilters;
