/* eslint react/prop-types: 0 */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { isEqual } from 'lodash';
import ConfirmationDialog from '../../../base/components/ra/layout/Confirm';
import { HEADER_HEIGHT } from '../../../custom-theme/themeConfig';
import { API_URL } from '../../../constant';
import { useError } from '../../../base/hooks';
import {
  DEPEND_ON_PERM,
  PERM_CHECKBOX_WIDTH,
  LIST_PERM_KEY,
  CONTAINER_MIN_WIDTH,
} from '../constants';
import { checkDisableAction } from '../utils';
import TableCell from './table-cell';
import CheckBoxCell from './checkbox-cell';
import Row from './row';

const moduleColumnStyle = {
  '&:first-child': {
    textAlign: 'left',
    paddingLeft: 30,
  },
};

const useStyle = makeStyles(
  theme => ({
    '@global': {
      '.GroupingTable-tableHeaderFixed': {
        width: 'calc(100% - 64px)',
        position: 'fixed',
        top: HEADER_HEIGHT.md,
        zIndex: 100,
        background: 'red',

        opacity: 1,
        animationName: 'fadeInOpacity',
        animationIterationCount: 1,
        animationTimingFunction: 'ease-in',
        animationDuration: '0.2s',

        [theme.breakpoints.up('lg')]: {
          top: HEADER_HEIGHT.lg,
        },
      },
    },
    checkboxColumn: {
      width: PERM_CHECKBOX_WIDTH.default,
      [theme.breakpoints.down('lg')]: {
        width: PERM_CHECKBOX_WIDTH.lg,
      },
      [theme.breakpoints.down('md')]: {
        width: PERM_CHECKBOX_WIDTH.md,
      },
    },
    checkboxColumnContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    customTableCell: {
      border: 'none',
    },
    cellCollapse: {
      padding: 0,
    },
    cellOfGroup: {
      border: 'none',
      ...moduleColumnStyle,
    },
    titleOfGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    rowHead: {
      background: fade(theme.palette.primary.main, 0.6),
    },
    tableHead: {
      minWidth: CONTAINER_MIN_WIDTH,
      overflow: 'hidden',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    tableChildren: {
      minWidth: CONTAINER_MIN_WIDTH,
      overflow: 'hidden',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    rowParent: {
      background: fade(theme.palette.common.black, 0.18),
      cursor: 'pointer',
      '& > td': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.borderColor.main,

        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    rowChildrenWrapper: {
      background: fade(theme.palette.secondary.main, 1),
    },
    rowChildren: {
      '& > td': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.borderColor.dark,

        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    cellHead: {
      fontWeight: 700,
      ...moduleColumnStyle,
    },
    childTitleCol: {
      textAlign: 'left',
      paddingLeft: 80,
    },
    checkboxReadOnly: {
      pointerEvents: 'none',
      '& input': {
        cursor: 'default',
      },
    },
  }),
  {
    name: 'GroupingTable',
  },
);

const GroupingTable = props => {
  const {
    readOnly,
    permissions,
    dataPerms,
    id,
    style = {},
    role,
    fetchPermByRole,
  } = props;

  const translate = useTranslate();
  const notify = useNotify();
  const { notifyError } = useError();

  const classes = useStyle();
  const tableHeadContainerRef = useRef();
  const tableContentContainerRef = useRef();
  const isScreenLgRef = useRef();
  const [headerFixed, setHeaderFixed] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const theme = useTheme();
  isScreenLgRef.current = useMediaQuery(theme.breakpoints.up('lg'));

  const [dataUpdate, setDataUpdate] = useState({});
  const [preventingUpdate, setPreventingUpdate] = useState({});

  const preventSubmit = isEmpty(dataUpdate);

  const handleScroll = e => {
    e.preventDefault();
    const tableHeadContainer = tableHeadContainerRef.current;

    if (!isScreenLgRef.current) {
      tableHeadContainer?.classList?.remove('GroupingTable-tableHeaderFixed');
      setHeaderFixed(false);
      return;
    }

    const headerHeight = isScreenLgRef.current
      ? HEADER_HEIGHT.lg
      : HEADER_HEIGHT.md;
    const tableContentContainer = tableContentContainerRef.current;
    const scrollTopOfHeader = window.scrollY + headerHeight;

    if (scrollTopOfHeader > tableContentContainer?.offsetTop && !headerFixed) {
      tableHeadContainer?.classList?.add('GroupingTable-tableHeaderFixed');
      const parentWidth = tableHeadContainer?.parentElement?.offsetWidth;
      tableHeadContainer.style.width = `${parentWidth}px`;

      setHeaderFixed(true);
    } else {
      tableHeadContainer?.classList?.remove('GroupingTable-tableHeaderFixed');
      setHeaderFixed(false);
    }
  };

  const getValidParentPerms = parentPerm => {
    if (Array.isArray(parentPerm?.children) && parentPerm.children.length > 0) {
      return true;
    }
    return false;
  };

  const getDependPermsWhenChecked = (action, resource) => {
    const { perm } = resource;
    const stateUpdatedPerm = dataUpdate[perm.id] || perm;

    const newPerm = {
      [action]: true,
    };

    const actionDependIsDisabled = Object.keys(
      DEPEND_ON_PERM[action] || {},
    ).some(actionDepend => {
      const disabled = checkDisableAction({
        action: actionDepend,
        resource,
        permissions,
        role,
      });

      // Perm cannot change when depend perm is disable
      if (
        disabled
        && stateUpdatedPerm[actionDepend] !== DEPEND_ON_PERM[action][actionDepend]
      ) {
        setPreventingUpdate({
          action: actionDepend,
          resource,
          message: translate('wa.exception.notAllowToUpdate', {
            smart_name: 'list',
          }),
        });
        return true;
      }
      return false;
    });

    if (actionDependIsDisabled) {
      return {};
    }

    Object.keys(DEPEND_ON_PERM[action] || {}).forEach(actionDepend => {
      newPerm[actionDepend] = DEPEND_ON_PERM[action][actionDepend];
    });
    return newPerm;
  };

  const onChange = (resource, action, checked) => {
    const { perm } = resource;
    const stateUpdatedPerm = dataUpdate[perm.id] || perm;

    let permUpdate = {};

    if (checked) {
      if (Object.keys(DEPEND_ON_PERM).includes(action)) {
        permUpdate = getDependPermsWhenChecked(action, resource);
      } else {
        permUpdate = {
          [action]: true,
        };
      }
    }

    if (!checked) {
      if (Object.keys(DEPEND_ON_PERM).includes(action)) {
        permUpdate = {
          [action]: false,
        };
      } else {
        const dependOnInvert = {};
        Object.keys(DEPEND_ON_PERM).forEach(actionRoot => {
          Object.keys(DEPEND_ON_PERM[actionRoot]).forEach(actionDepend => {
            if (!Array.isArray(dependOnInvert[actionDepend])) {
              dependOnInvert[actionDepend] = [];
            }
            if (DEPEND_ON_PERM[actionRoot][actionDepend]) {
              dependOnInvert[actionDepend].push(actionRoot);
            }
          });
        });

        if (Object.keys(dependOnInvert).includes(action)) {
          const actionRootIsDisabled = dependOnInvert[action].some(
            actionRoot => {
              const disabled = checkDisableAction({
                action: actionRoot,
                resource,
                permissions,
                role,
              });

              if (disabled && stateUpdatedPerm[actionRoot]) {
                setPreventingUpdate({
                  action: actionRoot,
                  resource,
                  message: translate('wa.exception.notAllowToUpdate', {
                    smart_name: 'list',
                  }),
                });
                return true;
              }
              return false;
            },
          );

          if (!actionRootIsDisabled) {
            permUpdate = {
              [action]: false,
            };
            dependOnInvert[action].forEach(actionRoot => {
              permUpdate[actionRoot] = false;
            });
          }
        }
      }
    }

    const objUpdate = {
      ...stateUpdatedPerm,
      ...permUpdate,
    };

    if (isEqual(objUpdate, perm)) {
      const tmpDataUpdate = {
        ...dataUpdate,
      };
      delete tmpDataUpdate[perm.id];
      return setDataUpdate(tmpDataUpdate);
    }

    return setDataUpdate({
      ...dataUpdate,
      [perm.id]: objUpdate,
    });
  };

  const submitPermChanges = async permChanges => {
    try {
      const data = Object.values(permChanges);
      await axios.post(API_URL.GET_PERM_BY_ROLE(id), {
        data,
      });

      notify('ra.notification.updated', 'success', {
        smart_name: translate('resources.perm.name'),
      });

      setIsOpenDialog(false);
      fetchPermByRole(id);
    } catch (error) {
      notifyError(error);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return function cleanUp() {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <TableContainer
      component={Box}
      style={{
        width: '100%',
        ...style,
      }}
    >
      <Box
        ref={tableHeadContainerRef}
        style={{
          background: '#FFFFFF',
        }}
      >
        <Table className={classes.tableHead}>
          <TableHead>
            <TableRow className={classes.rowHead}>
              <TableCell
                classes={classes}
                className={classes.cellHead}
                style={{
                  paddingLeft: 38,
                }}
              >
                {translate('resources.resource.name')}
              </TableCell>

              {LIST_PERM_KEY.map((item, index) => (
                <CheckBoxCell
                  key={`perm-checkbox-${index}`}
                  classes={classes}
                  className={classes.cellHead}
                >
                  {translate(`resources.perm.fields.${item}`)}
                </CheckBoxCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </Box>

      <Box ref={tableContentContainerRef}>
        <Table className={classes.tableChildren}>
          <TableHead />
          <TableBody>
            {dataPerms
              .filter(getValidParentPerms)
              .map((parent, parentIndex) => (
                <Row
                  key={`table-row-${parentIndex}`}
                  data={parent}
                  readOnly={readOnly}
                  onChange={onChange}
                  dataUpdate={dataUpdate}
                  translate={translate}
                  permissions={permissions}
                  classes={classes}
                  checkboxNotify={preventingUpdate}
                  role={role}
                />
              ))}
          </TableBody>
        </Table>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: CONTAINER_MIN_WIDTH,
        }}
      >
        <Button
          color="primary"
          disabled={preventSubmit}
          onClick={() => {
            if (preventSubmit) {
              return;
            }

            setIsOpenDialog(true);

            // submitPermChanges(dataUpdate);
          }}
        >
          {translate('ra.action.submit')}
        </Button>

        <ConfirmationDialog
          isOpen={isOpenDialog}
          title={translate('ra.message.confirmSubmitEdit', {
            smart_name: translate('resources.perm.name'),
          })}
          content=""
          onConfirm={() => submitPermChanges(dataUpdate)}
          onClose={() => setIsOpenDialog(false)}
        />
      </Box>
    </TableContainer>
  );
};

export default GroupingTable;
