/* eslint-disable */
import React from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'ra-core';
import { SmallButton } from '../../../base/components/ra/buttons';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { useError } from '../../../base/hooks';
import useAuthUser from '../../../base/hooks/useAuthUser';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    background: theme.palette.primary.main,
    '&:hover, &:focus': {
      color: 'white',
      background: theme.palette.primary.main,
    },
  },
}));

export const ActionMenu = (props) => {
  const { record, list } = props;
  const notify = useNotify();
  const { notifyError } = useError();
  const refresh = useRefresh();
  const user = useAuthUser();
  const classes = useStyles();

  const activateEvent = () => {
    Axios.patch(`api/cheat-mode-apply/${record?.id}/activate`, {
      groupId: record?.group?.id,
    })
      .then((res) => {
        if (res.status === 200) {
          // console.log('status', res.status);
          notify('Successfully', 'success');
          refresh();
        }
      })
      .catch((error) => notifyError(error));
  };

  const deactivateEvent = () => {
    Axios.patch(`api/cheat-mode-apply/${record?.id}/deactivate`, {
      groupId: record?.group?.id,
    })
      .then((res) => {
        if (res.status === 200) {
          notify('Successfully', 'success');
          refresh();
        }
      })
      .catch((error) => notifyError(error));
  };

  const mainComponent = record?.status === 'INACTIVE' && (
    <SmallButton label="ra.action.activate" onClick={activateEvent} startIcon={<AccessTime />} />
  );

  // Commented this component because in the label we are getting the data from api but the key is not there
  // const disabledComponent = record?.status === 'ACTIVE' && (
  //   <SmallButton label="resources.cheat-mode-apply.text.inactive" onClick={inactiveEvent} startIcon={<CloseIcon />} />
  // );

  const disabledComponent = record?.status === 'ACTIVE' && (
    <>
      <SmallButton
        className={classes.button}
        label="ra.action.activated"
        onClick={deactivateEvent}
        startIcon={<CloseIcon />}
      />

      <SmallButton label="reactivate" onClick={activateEvent} startIcon={<RefreshIcon />} />
    </>
  );

  // need to check if there is any condition to show the component.
  if (list) {
    return mainComponent;
  }

  return (
    <ToolbarMenu {...props}>
      {mainComponent}
      {disabledComponent}
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
