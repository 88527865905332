/* eslint-disable */
import React from 'react';
import { SimpleForm, useEditController, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Edit } from '../../base/components/ra/views';
import { useUpdateCustom } from '../../base/hooks';
import { showConfirmDialog } from '../../services/redux/app/app.actions';
import rcSlug from '../../constant/resource-slug';
import PrepaidEditForm from './components/prepaidEditForm';
import { sanitizeObject } from '../../services/util';
import { dateTimeMustBeAfterCurrent } from '../../services/util/validate/validateMethods';
import { EXPIRES_INPUT_ID } from './components/ExpiresInput';
import { EXCLUDE_SELECTED_PLAYER_KEY } from './components/PlayerInput';
import { PLAYER_OPERATOR } from '../../constant';

const PrepaidEdit = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { record } = useEditController(props);
  const { update, error } = useUpdateCustom({
    resource: rcSlug.PREPAID,
    resourceTranslated: translate(`resources.${rcSlug.PREPAID}.name`),
  });

  const transform = (data) => {
    const isSelectAllPlayer = !!(!Array.isArray(data.players) || !data.players?.length);
    return sanitizeObject({
      name: data.name,
      desc: data.desc,
      currency: {
        id: data.currencyId,
      },
      brand: {
        id: data.brandId,
      },
      group: {
        id: data.groupId,
      },
      game: {
        id: data.gameId,
      },
      reward: {
        typeId: data.spinTypeId,
        module: 'prepaid',
        count: Number(data.count),
        claimBefore: data.claimBefore,
      },
      inboxRule: {
        message: {
          data: data.inboxRuleMgsTemplate,
        },
      },
      betSetting: {
        betSize: Number(data.betSize),
        betLevel: Number(data.betLevel),
      },
      players: isSelectAllPlayer
        ? null
        : data.players?.map((player) => ({
            nativeId: player.nativeId,
            operator: data[EXCLUDE_SELECTED_PLAYER_KEY] ? PLAYER_OPERATOR.EXCLUDE : PLAYER_OPERATOR.INCLUDE,
          })),
      issuer: data.issuer,
      [EXCLUDE_SELECTED_PLAYER_KEY]: null,
    });
  };

  const updatePrepaid = (values) => {
    if (!record?.id) {
      return;
    } else if (dateTimeMustBeAfterCurrent(translate)(values.claimBefore)) {
      const prepaid = document.getElementById(EXPIRES_INPUT_ID);
      // To trigger customized validation event
      prepaid.focus();
      prepaid.blur();
      prepaid.scrollIntoView({ behavior: 'smooth', block: 'end' });
      return;
    } else {
      const resourceTranslated = translate('resources.prepaid.name');
      const confirmEditContent = translate('ra.message.confirmSubmitEdit', {
        smart_name: resourceTranslated.toLowerCase(),
      });

      const confirmEdit = () => {
        update({
          resource: rcSlug.PREPAID,
          payload: {
            id: record.id,
            data: transform(values),
          },
        });
      };

      dispatch(
        showConfirmDialog({
          isOpen: true,
          title: 'ra.action.edit',
          content: confirmEditContent,
          onConfirm: confirmEdit,
        }),
      );
    }
  };

  return (
    <Edit {...props} forceCustomSave onCustomSave={updatePrepaid}>
      <SimpleForm redirect="show">
        <PrepaidEditForm record={record} error={error} />
      </SimpleForm>
    </Edit>
  );
};
export default PrepaidEdit;
