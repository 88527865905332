import React from 'react';
import { List, Box, fade, useMediaQuery, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import LanguageSwitcher from '../../../@jumbo/components/AppLayout/partials/LanguageSwitcher';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';

const useStyles = makeStyles((theme) => ({
  sideNavMenu: {
    position: 'relative',
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: fade(theme.palette.common.dark, 0.15),
    },
  },
}));

const CmtVertical = (props) => {
  const { menuItems } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const tmpMenuItems = menuItems.filter((item) => item.name !== 'Home' && item.children?.length > 0);

  return (
    <>
      <List component="nav" disablePadding className={classes.sideNavMenu}>
        {tmpMenuItems.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
            default:
              return null;
          }
        })}
      </List>
      {isXsDown && (
        <Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')} marginTop="16px" marginBottom="16px">
          <LanguageSwitcher mobileVersion={isXsDown} />
        </Box>
      )}
    </>
  );
};

export default CmtVertical;
