import React from 'react';
import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import NavMenuItem from './NavMenuItem';
import NavMega from './NavMega';
import RecursiveMenuItem from './RecursiveMenuItem';

const useStyles = makeStyles((theme) => ({
  horizontalNavMenu: {
    position: 'relative',
    display: 'flex',
    marginLeft: -14,
    marginRight: -14,
    flexWrap: 'wrap',
  },
}));

const CmtHorizontal = (props) => {
  const { menuItems } = props;
  const classes = useStyles();

  const tmpMenuItems = menuItems.filter((item) => item.name !== 'Home' && item.children?.length > 0);

  return (
    <List component="nav" disablePadding className={clsx(classes.horizontalNavMenu, 'Cmt-horizontalNavMenu')}>
      {tmpMenuItems.map((item, index) => {
        switch (item.type) {
          case 'collapse':
            return <RecursiveMenuItem isParent {...item} key={index} />;
          case 'mega':
            return <NavMega {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtHorizontal;
