/* eslint-disable no-param-reassign */
import React, { useMemo, useRef, isValidElement, cloneElement, Children } from 'react';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { useSchema, useViewType } from '../../hooks';

export default function WealthInputArrangementProvider({
  children, basePath, fieldOrder,
}) {
  const {
    api, ref,
  } = useSchema();
  const {
    isEditView, isCreateView,
  } = useViewType();

  // Other element which don't have sourceName
  const otherElements = useRef([]);

  const properties = useMemo(() => {
    if (api) {
      const { paths } = api;

      let requestSchema = null;
      if (isEditView) {
        const target = `${basePath}/{id}`;
        requestSchema = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;
      } else if (isCreateView) {
        requestSchema = paths?.[basePath]?.post?.requestBody?.content?.['application/json']?.schema;
      }

      if (!requestSchema) {
        return {};
      }

      const requestRef = requestSchema.$ref || requestSchema.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      return ref.get(requestRef)?.properties || {};
    }

    return {};
  }, [api, ref, basePath]);

  const getSourceIndex = source => get(properties, `${source}.properties.index.default`);

  const needToReOrderField = useMemo(
    () => keys(properties).some(source => isNumber(getSourceIndex(source))),
    [properties],
  );

  if (!needToReOrderField && !fieldOrder?.length) {
    return children;
  }

  const getSourceListFromChildren = childrenData => {
    const sourceListInitial = {
      dictionary: {},
      names: [],
    };

    // Clear other element
    otherElements.current = [];

    return Children.toArray(childrenData).reduce((sourceList, curChild) => {
      const sourceName = curChild?.props?.source;

      if (isValidElement(curChild) && !sourceName) {
        otherElements.current.push(curChild);
      }

      // Validate child and sourceName
      if (!isValidElement(curChild) || !sourceName) {
        return sourceList;
      }

      // Update names & dictionary
      sourceList.names.push(sourceName);
      sourceList.dictionary[sourceName] = curChild;

      return sourceList;
    }, sourceListInitial);
  };

  const sourceDataListFromChildren = useMemo(() => getSourceListFromChildren(children), [children]);

  // Start to reach sourceName list which is sorted with index
  const sourceNamesOrdered = useMemo(() => {
    const allSourceNameToDisplay = keys(properties).filter(source => sourceDataListFromChildren.names.includes(source));

    const sourceListWithIndex = allSourceNameToDisplay.reduce((result, currSource) => {
      const sourceIndex = getSourceIndex(currSource);

      if (!isNumber(sourceIndex)) {
        return result;
      }

      result.push({
        source: currSource,
        index: sourceIndex,
      });

      return result;
    }, []);

    const sourceListWithIndexSorted = sortBy(sourceListWithIndex, ['index']);
    const keysOfSourceList = sourceListWithIndexSorted.map(item => item?.source);

    const keysOfSourceListWithoutIndex = allSourceNameToDisplay.filter(item => !keysOfSourceList.includes(item));

    const sourceListArrangedResult = sourceListWithIndexSorted.reduce(
      (result, currSource) => {
        if (currSource?.index < allSourceNameToDisplay.length) {
          // Add current source to source list with a new index
          result.splice(currSource?.index, 0, currSource?.source);
        } else {
          // Handle case that index of source is greater than number of field is displayed
          result.push(currSource?.source);
        }

        return result;
      },
      [...keysOfSourceListWithoutIndex],
    );

    if (Array.isArray(fieldOrder)) {
      sourceListArrangedResult.sort((a, b) => fieldOrder.indexOf(a) - fieldOrder.indexOf(b));
    }

    return sourceListArrangedResult;
  }, [properties, sourceDataListFromChildren, fieldOrder]);

  return (
    <>
      {sourceNamesOrdered.map(key => {
        const inputField = sourceDataListFromChildren?.dictionary?.[key];
        if (!isValidElement(inputField)) {
          return null;
        }
        return cloneElement(inputField, {
          key,
        });
      })}

      {otherElements.current}
    </>
  );
}

WealthInputArrangementProvider.propTypes = {
  children: PropTypes.node.isRequired,
  basePath: PropTypes.string.isRequired,
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
};

WealthInputArrangementProvider.defaultProps = {
  fieldOrder: null,
};
