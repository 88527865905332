import React from 'react';
import { useTranslate } from 'react-admin';
import { TextInput } from '../../ra/inputs';
import FormField from './FormField';

const NativeIdInput = props => {
  const translate = useTranslate();
  return (
    <>
      <FormField name="nativeId||$cont">
        {() => (
          <TextInput
            label={translate('resources.report.fields.nativeId')}
            source="nativeId||$eq"
            resettable
            {...props}
          />
        )}
      </FormField>
    </>
  );
};

NativeIdInput.propTypes = {};

NativeIdInput.defaultProps = {};

export default NativeIdInput;
