/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import BetDateRangeInput from './components/date-range.input';
import { FILTER_PREFIX_ON_VALUE } from '../../constant';
import InfinitePagination from '../../base/components/ra/list/InfinitePagination';
import { useTranslate } from 'react-admin';
import { DateField } from '../../base/components/ra/fields';

const useStyles = makeStyles({
  filterContainerWrapper: {
    "& *[data-field='created']": {
      order: 1,
    },
    "& *[data-field='updated']": {
      order: 2,
    },
    "& *[data-field='groupId']": {
      order: 3,
    },
    "& *[data-field='brandId']": {
      order: 4,
    },
    "& *[data-field='player']": {
      order: 5,
    },
    "& *[data-field='game']": {
      order: 6,
    },
    "& *[data-field='amount']": {
      order: 7,
    },
    "& *[data-field='currency']": {
      order: 8,
    },
    "& *[data-field='earn']": {
      order: 9,
    },
    "& *[data-field='betType']": {
      order: 10,
    },
    "& *[data-field='betSize']": {
      order: 11,
    },
    "& *[data-field='betLevel']": {
      order: 12,
    },
    "& *[data-field='playerBalance']": {
      order: 13,
    },
    "& *[data-field='endingBalance']": {
      order: 14,
    },
    "& *[data-field='betStatus']": {
      order: 15,
    },
    "& *[data-field='channel']": {
      order: 16,
    },
    "& *[data-field='id']": {
      order: 17,
    },
    "& *[data-field='endTime']": {
      order: 18,
    },
  },
});

const BetList = (props) => {
  const styles = useStyles();
  const today = moment().endOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const translate = useTranslate();

  return (
    <WealthListGuesser
      {...props}
      excludeFilterInputs={['created', 'updated', 'endTime', 'amount', 'wonAmount', 'channel', 'endingBalance', 'playerBalance']}
      extraFilterInputs={[
        <BetDateRangeInput source="created" label={translate('resources.bet.fields.created')} {...props} />,
        <BetDateRangeInput
          source="updated"
          label={translate('resources.bet.fields.updated')}
          dateRange={[null, null]}
          allowClear
          {...props}
        />,
        // <BetDateRangeInput
        //   source="endTime"
        //   label={translate('resources.bet.fields.endTime')}
        //   dateRange={[null, null]}
        //   allowClear
        //   {...props}
        // />,
      ]}
      filterProps={{
        containerClassName: styles.filterContainerWrapper,
      }}
      filterDefaultValues={{
        created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday.toISOString()},${today.toISOString()}`,
      }}
      pagination={<InfinitePagination />}
      disabledAutoRefetch
      excludeFields={['created', 'updated', 'channel']}
      fieldOrder={[
        'id',
        'created',
        'updated',
        'player',
        'game',
        'amount',
        'currency',
        'earn',
        'betType',
        'betSize',
        'betLevel',
        'playerBalance',
        'endingBalance',
        'betStatus',
        'endTime',
        'groupId',
        'brandId',
      ]}
    >
      <DateField source="created" />
      <DateField source="updated" />
      <DateField source="endTime" />
    </WealthListGuesser>
  );
};

export default BetList;
