/* eslint-disable  */
import { Box, makeStyles } from '@material-ui/core';
import { FormDataConsumer, useTranslate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useDataProvider,
  useEditController,
  useLocale,
  useNotify,
  useUpdate,
} from 'react-admin';
import { useField, useForm, useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useApiProperties } from '../../base/hooks';
import useError from '../../base/hooks/useError';

import ReferenceInputCustom from '../../base/components/ra/inputs/reference.input';
import { Edit } from '../../base/components/ra/views';
import TransferList from './components/transfer-list';
import resourceSlug from '../../constant/resource-slug';

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
  leftContent: {
    flex: 1,
  },
  rightContent: {},
  raUpdateMain: {
    margin: '0 auto',
  },
  leftHeadClassName: {
    backgroundColor: theme.palette.secondary.main,
  },
  leftContentClassName: {
    backgroundColor: 'transparent',
  },
  rightHeadClassName: {
    backgroundColor: theme.palette.secondary.main,
  },
  rightContentClassName: {
    backgroundColor: 'transparent',
  },
}));

const WrapperTransferList = ({ children }) => {
  useField('games');
  return children;
};

const AvailableGameEdit = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();
  const { record } = useEditController(props);
  const locale = useLocale();
  const dataProvider = useDataProvider();

  const [allGamesData, setAllGamesData] = useState([]);
  const [allGameOptions, setAllGameOptions] = useState([]);
  const [isTransferListInit, setIsTransferInit] = useState(false);
  const [leftLoading, setLeftLoading] = useState(true);
  const [rightLoading, setRightLoading] = useState(true);

  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGame = getPropertiesByFieldName('game');

  const onUpdateSuccess = (response) => {
    const { data } = response;
    const availableGameId = data?.id;

    const availableGameNameTranslated = translate('resources.available-game.name');
    notify(translate('ra.notification.updated'), 'success', {
      smart_name: availableGameNameTranslated,
    });

    if (availableGameId) {
      history.push(`/available-game/${availableGameId}/show`);
    }
  };

  const [update, { loading: updateLoading, error }] = useUpdate(undefined, undefined, undefined, undefined, {
    onSuccess: onUpdateSuccess,
  });

  const saveAvailableGame = (values = {}) => {
    if (!values.group?.id) {
      // TODO: add this string-template to BE-message '%{fieldName} should be not empty',
      notify(
        translate('ra.message.shouldNotEmpty').replace(
          '%{fieldName}',
          translate('resources.available-game.fields.group'),
        ),
        'error',
      );
      return;
    }

    const { games } = values;

    update({
      resource: resourceSlug.AVAILABLE_GAME,
      payload: {
        id: record?.id,
        data: { games },
      },
    });
  };

  const getListGame = async ({ group }) => {
    try {
      const { data } = await dataProvider.getList(resourceSlug.GAME, {
        pagination: {
          page: 1,
          perPage: propertiesGame?.choiceLimit || 500,
        },
        sort: {
          field: propertiesGame.choiceSort?.field || 'name',
          order: propertiesGame.choiceSort?.order || 'ASC',
        },
        filter: {
          enabled: true,
        },
      });

      if (Array.isArray(data)) {
        setAllGamesData(data);
      }

      setIsTransferInit(true);
      setLeftLoading(false);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const getLeftInitValues = (availableGamesInitial) => {
    if (Array.isArray(availableGamesInitial)) {
      const gameIdsExisted = availableGamesInitial.map((item) => item.id);
      return allGameOptions.filter((gameId) => !gameIdsExisted.includes(gameId.value));
    }

    return allGameOptions;
  };

  const getRightInitValues = (availableGamesInitial) => {
    if (Array.isArray(availableGamesInitial)) {
      return availableGamesInitial.map((game) => ({
        value: game.id,
        label: game.name[locale] || game.name['en'] || game.name,
        enabled: game.enabled,
      }));
    }

    return [];
  };

  const leftOptions = useMemo(
    () => ({
      initValues: getLeftInitValues(record?.games),
      title: <strong>{translate('ra.text.allGames')}</strong>,
    }),
    [isTransferListInit, locale],
  );

  const rightOptions = useMemo(
    () => ({
      initValues: getRightInitValues(record?.games),
      title: <strong>{translate('ra.text.availableGames')}</strong>,
    }),
    [isTransferListInit, locale],
  );

  useEffect(() => {
    if (!updateLoading) {
      if (error) {
        notifyError(error);
      }
    }
  }, [updateLoading, error]);

  useEffect(() => {
    if (allGamesData.length > 0) {
      const gameIdsFromAllGamesData = allGamesData.map((game) => ({
        value: game.id,
        label: game.name[locale] || game.name['en'] || game.name,
        enabled: game.enabled,
      }));
      setAllGameOptions(gameIdsFromAllGamesData);
    }
  }, [allGamesData]);

  return (
    <Edit
      {...props}
      classes={{ main: classes.raUpdateMain }}
      alwaysEnableSaveButton={true}
      onCustomSave={saveAvailableGame}
    >
      <SimpleForm redirect="show">
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const form = useForm();

            const { values: formValues } = useFormState();

            useEffect(() => {
              getListGame({ ...formValues });
              setRightLoading(false);
            }, []);
            return (
              <>
                <Box className={classes.contentWrap}>
                  <Box className={classes.leftContent}>
                    <ReferenceInputCustom
                      label="resources.group.name"
                      source="group.id"
                      reference="group"
                      fullWidth
                      perPage={100}
                      afterOnChange={(_, form) => {
                        // NOTE: brand.id is source name of brand ReferenceInput
                        form.change('brand.id', '');
                      }}
                      variant="outlined"
                      disabled
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInputCustom>
                    <ReferenceInput
                      label="resources.brand.name"
                      source="brand.id"
                      reference="brand"
                      fullWidth
                      perPage={100}
                      filter={{
                        'group.id': formData.group?.id,
                      }}
                      disabled
                      variant="outlined"
                      allowEmpty
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  </Box>
                  <Box className={classes.rightContent}>
                    <WrapperTransferList>
                      <TransferList
                        leftOptions={leftOptions}
                        rightOptions={rightOptions}
                        leftLoading={leftLoading}
                        rightLoading={rightLoading}
                        classes={{
                          leftHead: classes.leftHeadClassName,
                          leftContent: classes.leftContentClassName,
                          rightHead: classes.rightHeadClassName,
                          rightContent: classes.rightContentClassName,
                        }}
                        onChange={(_, rightValues) => {
                          formData.games = rightValues.map((item) => ({
                            id: item.value,
                          }));
                          form.change('games', formData.games);
                        }}
                      />
                    </WrapperTransferList>
                  </Box>
                </Box>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default AvailableGameEdit;
