/* eslint-disable  */
import React from 'react';
import { SingleFieldList, useTranslate, ArrayField, useLocale, FunctionField } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ChipField } from '../../base/components/ra/fields';
import isEmpty from 'lodash/isEmpty';

const WrapperArrayField = (props) => {
  const locale = useLocale();
  const translate = useTranslate();

  if (isEmpty(props?.record)) {
    return null;
  }

  const isTranslatable = props?.record?.games?.some((item) => typeof item.name === 'object');

  return (
    <ArrayField label={translate('resources.available-game.fields.games')} source="games" fieldKey="id">
      <SingleFieldList linkType={false}>
        <ChipField clickable={false} source={isTranslatable ? `name.${locale}` : 'name'} />
      </SingleFieldList>
    </ArrayField>
  );
};

const AvailableGameShow = (props) => {
  return (
    <WealthShowGuesser {...props}>
      <WrapperArrayField {...props} />
    </WealthShowGuesser>
  );
};

export default AvailableGameShow;
